import React from 'react'
import { Text } from '../../../Text'
import { Colors } from '../../../../constants/Colors'
import { t } from '../../../../modules/i18n'

export default function OutboundIndexTitle() {
  return (
    <Text
      style={{
        fontSize: 22,
        fontWeight: '600',
        lineHeight: 33,
        color: Colors.black,
        marginBottom: 16,
      }}
    >
      {t('連携設定')}
    </Text>
  )
}
