import useSWR, {
  fetcher,
  onError,
  swrKey,
  mutate,
  SWRConfiguration,
  fetcherWithPaginationHeader,
  WithHeaderData,
} from '../modules/swr'
import axios, { setHeader, wrapResponse } from '../modules/axios'

import {
  RestaurantTableProducts,
  TableProduct,
} from '@hello-ai/ar_shared/src/types/ForR/TableProduct'
import { omit } from 'lodash'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
export * from '@hello-ai/ar_shared/src/types/ForR/TableProduct'

export function useArchivedRestaurantTableProducts(
  restaurantId: number,
  params: {
    page: number
    per: number
  }
) {
  const _params = {
    status: 'archived',
    page: params.page,
    per: params.per,
  }
  const token = useToken()
  const { data } = useSWR<
    WithHeaderData<TableProduct[]> | null,
    any,
    ReturnType<typeof swrKey>
  >(
    swrKey(token, `/restaurants/${restaurantId}/products`, _params),
    ([token, url]) => fetcherWithPaginationHeader([token, url, _params])
  )
  const mutateAllPages = async () => {
    const baseKey = swrKey(token, `/restaurants/${restaurantId}/products`)
    await mutate(
      (key: any) => {
        if (key == null || !Array.isArray(key)) return
        return key.slice(0, 2).every((v, i) => v === baseKey[i])
      },
      undefined,
      { revalidate: true }
    )
  }

  return {
    tableProducts: data ? data.data : null,
    headerData: data ? data.headerData : null,
    mutateAllPages,
  }
}

export function useRestaurantTableProducts({
  restaurantId,
  params,
  config,
}: {
  restaurantId: number
  params?:
    | {
        type: 'paging'
        page: number
        keyword?: string
        status?: string
        in_stock?: boolean
        parent_menu_page_id?: string | null
        sort_type?: string
      }
    | {
        type: 'all'
        paging: false
        table_product_ids?: string
      }
  config?: SWRConfiguration
}) {
  const token = useToken()
  const parameters = omit(params, 'type')
  const keys = swrKey(
    token,
    `/restaurants/${restaurantId}/products`,
    parameters
  )
  const { data, mutate, error } = useSWR<
    WithHeaderData<TableProduct[]> | null,
    any,
    ReturnType<typeof swrKey> | null
  >(
    // productIds が渡されていて空の場合はリクエストしない
    params?.type === 'all' &&
      typeof params.table_product_ids === 'string' &&
      params.table_product_ids.length === 0
      ? null
      : keys,
    ([token, url, parameters]: [
      token: string | null,
      url: string,
      params: string,
    ]) => fetcherWithPaginationHeader([token, url, JSON.parse(parameters)]),
    config
  )

  return {
    data: data ? data.data : null,
    tableProducts: data ? data.data : null,
    headerData: data ? data.headerData : null,
    mutate,
    error,
  }
}

export function useTableProduct({
  tableProductId,
}: {
  tableProductId?: string
}) {
  const token = useToken()
  const {
    data: tableProduct,
    error,
    mutate,
  } = useSWR<TableProduct | null, any, ReturnType<typeof swrKey> | null>(
    tableProductId != null
      ? swrKey(token, `/products/${tableProductId}`)
      : null,
    fetcher
  )

  if (tableProductId != null) {
    return { tableProduct, isLoading: false, mutate }
  }

  return {
    tableProduct,
    isLoading: tableProduct == null && error == null,
    mutate,
  }
}

export type UpdateTableProductFuncType = (
  tableProductId: string,
  params?: any
) => void

interface UpdateTableProductStockParams {
  restaurant_id?: number
  out_of_stock_at?: string | null
}

export type UpdateTableProductStockFuncType = (
  tableProductId: string,
  params: UpdateTableProductStockParams
) => void

export async function updateTableProduct({
  token,
  tableProductId,
  params,
}: {
  token: string
  tableProductId: string
  params: any
}) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.patch(`/products/${tableProductId}`, params)
  )

  if (error != null) {
    onError(error)
  }

  if (response?.data != null) {
    mutate(swrKey(token, `/products/${tableProductId}`), response.data, false)
  }

  return {
    tableProduct: response?.data,
  }
}

export async function updateTableProductStock({
  token,
  tableProductId,
  params,
}: {
  token: string
  tableProductId: string
  params: UpdateTableProductStockParams
}) {
  setHeader({ token })
  const { error } = await wrapResponse(
    axios.post(`/products/${tableProductId}/product_stock`, params)
  )

  if (error != null) {
    onError(error)
  }
  return {
    error,
  }
}

export async function createTableProduct({
  token,
  restaurantId,
  params,
}: {
  token: string
  restaurantId: number
  params: any
}) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.post(`/restaurants/${restaurantId}/products`, params)
  )

  if (error != null) {
    onError(error)
  }

  if (response?.data != null) {
    mutate(swrKey(token, `/products/${response.data.id}`), response.data, false)
  }

  return {
    tableProduct: response?.data,
  }
}

export async function bulkUpdateProductStock(
  token: string,
  restaurantId: number,
  product_stock_changes: {
    product_id: string
    after_quantity: number
  }[]
) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.post(`/products/product_stock_changes`, {
      product_stock_changes,
    })
  )

  return {
    error,
    response,
  }
}

export async function bulkDeleteTableProduct(
  token: string,
  restaurantId: number,
  params: {
    ids: string[]
  }
) {
  setHeader({ token })
  const { error } = await wrapResponse(
    axios.post(`/restaurants/${restaurantId}/products/bulk_delete`, params)
  )

  return {
    error,
  }
}

export async function bulkUpdateProductStatus(
  token: string,
  restaurantId: number,
  params: {
    ids: string[]
    status: 'published' | 'archived'
  }
) {
  setHeader({ token })
  const { error } = await wrapResponse(
    axios.patch(
      `/restaurants/${restaurantId}/products/statuses_bulk_update`,
      params
    )
  )

  return {
    error,
  }
}
