import React, { useEffect } from 'react'
import { View } from 'react-native'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Trans } from 'react-i18next'
import { AspectImage } from '@hello-ai/ar_shared/src/components/AspectImage'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { Divider } from '@hello-ai/ar_shared/src/components/Divider'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { displayToastInfo } from '@hello-ai/for_r_app/src/components/Shared/Toast'
import logo from 'assets/images/logo.png'
import { onError } from 'modules/swr'
import { useAuth, useAuthMe } from 'models/Auth'
import {
  useEntryPlanInvitation,
  useRegisterEntryPlan,
} from 'models/EntryPlanRegistration'

function CurrentSignedInAccount() {
  const { actor } = useAuthMe()

  const email = actor?.owner?.email ?? actor?.businessUser?.email

  if (email == null) {
    return null
  }

  return (
    <Text style={{ fontSize: 14 }}>
      {t('ログイン中のアカウント：{{email}}', { email })}
    </Text>
  )
}

function Registration({ invitationToken }: { invitationToken: string }) {
  const navigate = useNavigate()
  const { width, sm, md } = useResponsive()

  const { auth } = useAuth()
  const isLoggedIn = auth != null

  const { data: invitation } = useEntryPlanInvitation(invitationToken)
  const { trigger: register, isMutating } =
    useRegisterEntryPlan(invitationToken)

  if (invitation == null) {
    return <Loading />
  }

  const onSubmit = async () => {
    try {
      await register()
    } catch (error) {
      onError(error)
      return
    }

    displayToastInfo(t('登録しました'))

    if (isLoggedIn) {
      navigate('/', { replace: true })
    } else {
      navigate('/sign_in', { replace: true })
    }
  }

  return (
    <View
      style={{
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
        padding: 16,
      }}
    >
      <View
        style={{
          alignItems: 'center',
          width: '100%',
          maxWidth: width < sm ? '100%' : 500,
          flexShrink: 1,
        }}
      >
        <AspectImage
          style={{
            width: 160,
            aspectRatio: 2167 / 768,
          }}
          source={{ uri: logo }}
          resizeMode="contain"
        />
        {isLoggedIn && (
          <View style={{ marginTop: 20 }}>
            <CurrentSignedInAccount />
          </View>
        )}
        <Text
          style={{
            marginTop: 64,
            alignSelf: 'flex-start',
            fontSize: 22,
            fontWeight: '600',
          }}
        >
          {t('リクエスト機能の利用を開始しますか？')}
        </Text>
        <Text
          style={{
            marginTop: 16,
            fontSize: 16,
          }}
        >
          {t(
            'リクエスト予約機能では、メールやアプリで予約受付を柔軟に行えます。お客様がリクエストした予約を店舗が承認することで、予約が正式に成立します。'
          )}
        </Text>
        <Divider
          style={{
            width: '100%',
            marginTop: 32,
            marginBottom: 32,
          }}
        />
        {/* eslint-disable ar-i18n/require-translation-ja */}
        <Text
          style={{
            fontSize: 14,
          }}
        >
          <Trans
            i18nKey={
              '<0>リクエスト予約の場合は、一部の機能のご利用を制限させていただいております。その他の条件については</0><1>利用規約</1><2>に準ずる形といたします。「利用開始する」ボタンを押すことで</2><3>利用規約</3><4>に同意するものとします。</4>'
            }
          >
            <Text style={{ fontSize: 14 }}>
              {
                'リクエスト予約の場合は、一部の機能のご利用を制限させていただいております。その他の条件については'
              }
            </Text>
            <a
              href="https://helloincai.notion.site/request-reservation-terms"
              target="_blank"
              rel="noreferrer"
              style={{
                fontSize: 14,
                color: Colors.primary,
              }}
            >
              {'利用規約'}
            </a>
            <Text style={{ fontSize: 14 }}>
              に準ずる形といたします。「利用開始する」ボタンを押すことで
            </Text>
            <a
              href="https://helloincai.notion.site/request-reservation-terms"
              target="_blank"
              rel="noreferrer"
              style={{
                fontSize: 14,
                color: Colors.primary,
              }}
            >
              {'利用規約'}
            </a>
            <Text style={{ fontSize: 14 }}>に同意するものとします。</Text>
          </Trans>
        </Text>
        {/* eslint-enable ar-i18n/require-translation-ja */}
        <Button
          loading={isMutating}
          onPress={onSubmit}
          style={{
            marginTop: 24,
            width: width < md ? '100%' : 278,
            height: 48,
          }}
        >
          {t('利用開始する')}
        </Button>
      </View>
    </View>
  )
}

export default function EntryPlanRegistration() {
  const [searchParams] = useSearchParams()

  const navigate = useNavigate()
  const location = useLocation()
  const from = (location.state as { from: string } | undefined)?.from

  const invitationToken = searchParams.get('invitation_token')

  useEffect(() => {
    if (invitationToken == null || invitationToken === '') {
      navigate(from ?? '/')
    }
  }, [navigate, invitationToken, from])

  if (invitationToken == null || invitationToken === '') return null

  return <Registration invitationToken={invitationToken} />
}
