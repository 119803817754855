import useSWR, { fetcher, swrKey } from '../modules/swr'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { ReservationTag } from '@hello-ai/ar_shared/src/types/ForR/ReservationTag'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faCakeCandles } from '@fortawesome/pro-solid-svg-icons/faCakeCandles'
import { faBeerMugEmpty } from '@fortawesome/pro-solid-svg-icons/faBeerMugEmpty'
import { faFrown } from '@fortawesome/pro-solid-svg-icons/faFrown'
import { faChair } from '@fortawesome/pro-solid-svg-icons/faChair'
import { faPhoneAlt } from '@fortawesome/pro-solid-svg-icons/faPhoneAlt'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/affiliate'

export function useReservationTags() {
  const token = useToken()
  const { data, error, mutate, isLoading } = useSWR<
    ReservationTag[] | null,
    any,
    ReturnType<typeof swrKey>
  >(swrKey(token, `/reservation_book/reservation_tags`), fetcher)

  return {
    reservationTags: data ?? [],
    error,
    mutate,
    isLoading,
  }
}

/**
 * reservation_tagsテーブルの内容に合わせてアイコンを設定してください
 *
 * @param tagName - タグの名前
 * @returns タグに対応するアイコンの定義、または未定義の場合はundefined
 */
export function getTagIcon(tagName: string): IconDefinition | undefined {
  switch (tagName) {
    case t('誕生日・記念日'):
      return faCakeCandles
    case t('飲み放題'):
      return faBeerMugEmpty
    case allergyTagName:
      return faFrown
    case t('席指定'):
      return faChair
    case t('確認事項あり'):
      return faPhoneAlt
    default:
      return undefined
  }
}

// アレルギータグについては名前一致で別ファイルの処理に用いるため変数に格納しexport
export const allergyTagName = t('アレルギー')
