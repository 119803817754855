import React, { useMemo } from 'react'

import {
  Table,
  Empty,
} from '@hello-ai/for_r_app/src/components/Restaurant/Reservation/ListView/Content'
import { useRestaurantReservations } from '../../../models/RestaurantReservation'
import { useRestaurantId } from '../../../modules/useRestaurantId'
import { useLocation, useParams } from 'react-router'
import dayjs from '../../../modules/dayjs'
import { View } from 'react-native'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import {
  ContentKey,
  ReservedContentKeys,
} from 'components/Restaurant/Reservation/ListView/ReservedContent'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { useRestaurant } from 'models/Restaurant'

export default function RestaurantReservationsPrint() {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const date = dayjs(queryParams.get('date'))
  const restaurantId = useRestaurantId()
  const { restaurant } = useRestaurant(restaurantId)

  const params = useParams<{
    type?: string
  }>()

  const type: ContentKey = useMemo(() => {
    if (
      params.type != null &&
      (ReservedContentKeys as readonly string[]).includes(params.type)
    ) {
      return params.type as ContentKey
    }
    return 'all'
  }, [params.type])

  const { restaurantReservations, mutate } = useRestaurantReservations(
    restaurantId,
    {
      date: date.format('YYYY-MM-DD'),
      future_only: type === 'future_only',
    }
  )

  if (restaurant == null) return null

  return (
    <View
      style={{
        padding: 40,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Text
          style={{
            fontSize: 22,
            fontWeight: '600',
          }}
        >
          {/* eslint-disable-next-line ar-i18n/require-translation-ja */}
          {date.format('YYYY年M月D日(ddd)')}
        </Text>
        <Text
          style={{
            marginLeft: 16,
            fontSize: 22,
            fontWeight: '600',
          }}
        >
          {t('成立した予約')}
        </Text>
      </View>
      <View
        style={{
          marginTop: 24,
          borderTopWidth: 0.5,
          borderColor: Colors.border,
          borderBottomWidth: 0.5,
        }}
      >
        <Table
          restaurant={restaurant}
          mode="print"
          restaurantReservations={restaurantReservations}
          onIssueSmartPaymentReceipt={() => mutate()}
        />
        {restaurantReservations !== undefined &&
          restaurantReservations.length === 0 && (
            <Empty title={t('予約がありません')} />
          )}
        {restaurantReservations === undefined && (
          <View
            style={{
              paddingVertical: 24,
            }}
          >
            <Loading />
          </View>
        )}
      </View>
    </View>
  )
}
