import React, { useEffect, useMemo } from 'react'

import {
  TableSeat as TableSeatModel,
  useTableSeats,
} from '../../../../models/TableSeat'

import { RESERVATION_STEPPERS } from '../../FormCommon/Steppers'
import { ReservationFormStepProps } from '../types'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import {
  ReservationStepper,
  ReservationStepperNextButton,
  ReservationStepperBackButton,
} from '@hello-ai/ar_shared/src/components/ReservationStepper'
import { useRestaurantBusinessTimesByDate } from '../../../../models/RestaurantBusinessTime'
import { displayToastError } from '../../../Shared/Toast'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { SelectSeatForm, getDefaultDuration, validate } from './Form'

export { getDefaultDuration } from './Form'

export function SelectSeats({
  restaurantId,
  currentStep,
  skipSteps,
  restaurantReservation,
  state,
  onPressGoBack,
  onPressNext,
}: ReservationFormStepProps & { restaurantId: number }) {
  const { tableSeats } = useTableSeats(restaurantId, {})
  const { dateString, startTime, adultPartySize, childPartySize } = state

  const { restaurantBusinessTimes } = useRestaurantBusinessTimesByDate(
    restaurantId,
    { date: dateString }
  )

  const partySize = adultPartySize + childPartySize

  const [selectedSeatIds, setSelectedSeatIds] = useFormState<
    Array<TableSeatModel['id']>
  >(state.selectedSeatIds)

  const [endTime, setEndTime] = useFormState(
    startTime + getDefaultDuration({ startTime, restaurantBusinessTimes })
  )

  const { disabled, errors } = useMemo(
    () =>
      validate({
        partySize,
        selectedSeatIds,
        startTime,
        endTime,
        tableSeats,
        restaurantBusinessTimes,
      }),
    [
      startTime,
      endTime,
      partySize,
      restaurantBusinessTimes,
      selectedSeatIds,
      tableSeats,
    ]
  )

  useEffect(() => {
    errors.length > 0 &&
      errors.forEach((error) => {
        displayToastError(error)
      })
  }, [errors])

  const onPressNextWithParams = () =>
    onPressNext({
      endTime,
      selectedSeatIds,
    })

  const onPressNextButton = (
    <ReservationStepperNextButton
      onPressNext={onPressNextWithParams}
      text={t('詳細を入力する')}
    />
  )

  const onPressGoBackButton = (
    <ReservationStepperBackButton
      onPressGoBack={onPressGoBack}
      text={t('日時・人数の入力に戻る')}
    />
  )

  return (
    <ReservationStepper
      steps={RESERVATION_STEPPERS}
      currentStepNumber={currentStep == null ? undefined : currentStep}
      skipStepNumbers={skipSteps}
      errors={[]}
      onPressGoBack={onPressGoBack}
      onPressNext={onPressNextWithParams}
      onPressNextButton={onPressNextButton}
      onPressGoBackButton={onPressGoBackButton}
      isDisabledNextPress={disabled}
    >
      <SelectSeatForm
        restaurantId={restaurantId}
        restaurantReservation={restaurantReservation}
        dateString={dateString}
        startTime={startTime}
        endTime={endTime}
        setEndTime={setEndTime}
        adultPartySize={adultPartySize}
        childPartySize={childPartySize}
        selectedSeatIds={selectedSeatIds}
        setSelectedSeatIds={setSelectedSeatIds}
      />
    </ReservationStepper>
  )
}
