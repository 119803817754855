import React from 'react'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { Gesture, GestureDetector } from 'react-native-gesture-handler'
import { View } from 'react-native'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { getCellHeight, getCellStyle, getCellWidth } from './utils'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { faRepeat } from '@fortawesome/pro-solid-svg-icons/faRepeat'
import { ListPeriodResponse_RestaurantReservationBlockPeriod } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/restaurant_reservation_block/restaurant_reservation_block_service'
import { Timestamp } from '@hello-ai/proto/src/gen/google/protobuf/timestamp'

export const RestaurantReservationBlockPeriod = React.memo(
  ({
    onPress,
    type = 'default',
    index,
    restaurantReservationBlockPeriod,
    isSp = false,
  }: {
    onPress: ({
      absoluteX,
      absoluteY,
    }: {
      absoluteX: number
      absoluteY: number
    }) => void
    type: 'default' | 'unselectable'
    index: number
    restaurantReservationBlockPeriod: ListPeriodResponse_RestaurantReservationBlockPeriod
    isSp?: boolean
  }) => {
    const startAt = dayjs(
      Timestamp.toDate(restaurantReservationBlockPeriod.startAt!)
    )
    const endAt = dayjs(
      Timestamp.toDate(restaurantReservationBlockPeriod.endAt!)
    )

    const hours = endAt.diff(startAt, 'minute') / 60

    const pressGesture = Gesture.Tap()
      .runOnJS(true)
      .onEnd((e, success) => {
        if (success) {
          onPress(e)
        }
      })
    pressGesture.enabled(type !== 'unselectable')
    const gestures = Gesture.Race(pressGesture)

    return (
      <GestureDetector gesture={gestures}>
        <View
          style={[
            {
              backgroundColor: Colors.black50,
              position: 'absolute',
              borderRadius: 4,
              paddingVertical: 4,
              paddingHorizontal: 8,
              flexDirection: 'column',
              justifyContent: isSp ? 'flex-start' : 'center',
              width: getCellWidth(hours, isSp),
              height: getCellHeight(isSp),
            },
            getCellStyle({
              x: startAt.hour() + startAt.minute() / 60,
              y: index,
              isSp,
            }),
          ]}
        >
          {!isSp && (
            <View>
              <Text style={{ color: 'white', fontSize: 12 }} numberOfLines={1}>
                {startAt.format('H:mm')}-
              </Text>
            </View>
          )}
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Text
              style={{
                fontWeight: '600',
                color: 'white',
                marginTop: 2,
                fontSize: 14,
              }}
              numberOfLines={1}
            >
              {t('ブロック')}
            </Text>
            {restaurantReservationBlockPeriod.isRepeat && (
              <FontAwesomeIcon
                icon={faRepeat}
                size={14}
                color={'white'}
                style={{ marginLeft: 2 }}
              />
            )}
          </View>
        </View>
      </GestureDetector>
    )
  }
)
