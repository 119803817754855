import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { inRange } from 'lodash'
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Alert, Platform, View } from 'react-native'

import { useBusinessTimesFromGetCalendar } from '../../../models/RestaurantBusinessTime'
import {
  RestaurantReservation as RestaurantReservationModel,
  exchangeRestaurantReservationTableSeats,
  updateRestaurantReservation,
  useRestaurantReservations,
} from '../../../models/RestaurantReservation'
import {
  TableSeat as TableSeatModel,
  useTableSeats,
} from '../../../models/TableSeat'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { usePrevious } from '@hello-ai/ar_shared/src/modules/usePrevious'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import Loading from '../../Shared/Loading'

import { ListPeriodResponse_RestaurantReservationBlockPeriod } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/restaurant_reservation_block/restaurant_reservation_block_service'
import { Timestamp } from '@hello-ai/proto/src/gen/google/protobuf/timestamp'
import {
  Gesture,
  GestureDetector,
  ScrollView,
} from 'react-native-gesture-handler'
import Animated, {
  runOnJS,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
} from 'react-native-reanimated'
import { restaurantReservationBlockService } from '../../../models/RestaurantReservationBlock'
import { toHoursAndMinutes, toSeconds } from '../../../modules/time'

import {
  AlertMethods,
  AlertProvider,
} from '@hello-ai/ar_shared/src/components/Alert'
import { onError, useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useEffectEvent } from '@hello-ai/ar_shared/src/modules/useEffectEvent'
import { Trans } from 'react-i18next'
import { Menu } from 'react-native-paper'
import { useRestaurantRequestReservations } from '../../../models/RestaurantRequestReservation'
import { useNavigate } from '../../../modules/navigation/useNavigate'
import { useNavigation } from '../../../modules/navigation/useNavigation'
import { displayToastSuccess } from '../../Shared/Toast'
import { ReanimatedScrollEvent } from 'react-native-reanimated/lib/typescript/hook/commonTypes'
import { Reservation } from './Reservation'
import {
  getReservationText,
  getOtherReservations,
  findOverlappingItemsByTableSeatId,
  getStartTimeFromPosition,
  getSeatIdFromPosition,
  getPartySize,
} from './utils'
import { HeaderCell } from './HeaderCell'
import { HourCellList } from './HourCellList'
import { SelectSeatList } from './SelectSeatList'
import { RestaurantReservationBlockPeriod } from './RestaurantReservationBlockPeriod'
import type { Items, ChartProps, ChartMethods } from './types'
import {
  HOURS,
  SCROLL_VIEW_PADDING_Y,
  PADDING_HORIZONTAL,
  HOUR_PC_WIDTH,
  BORDER_WIDTH,
  SEAT_PC_WIDTH,
  CELL_HEIGHT,
  PARTY_SIZE_WIDTH,
  HOUR_SP_WIDTH,
  SEAT_PC_HEIGHT,
  SEAT_SP_HEIGHT,
  SEAT_SP_WIDTH,
  SCROLL_VIEW_PC_PADDING_X,
  REFRESH_INTERVAL,
  DEFAULT_SHOW_REQUEST_RESERVATION_SIZE,
} from './const'

export const Chart = React.forwardRef<ChartMethods, ChartProps>(
  (
    {
      restaurantId,
      date,
      mode = 'default',
      selectParams,
      scrollViewProps,
      onLoadStart,
      onLoadEnd,
      onReservationAdded,
      onStartSelectBlock,
      onEndSelectBlock,
    },
    ref
  ) => {
    const { tableSeats, isLoading: tableSeatsLoading } = useTableSeats(
      restaurantId,
      {}
    )
    const token = useToken()
    const { width, sm } = useResponsive()
    const navigation = useNavigation()
    const navigate = useNavigate()
    const alertRef = useRef<AlertMethods>(null)

    const {
      restaurantReservations: allRestaurantReservations,
      mutate,
      isLoading: restaurantReservationsLoading,
    } = useRestaurantReservations(
      restaurantId,
      {
        date: date.format('YYYY-MM-DD'),
      },
      {
        refreshInterval: REFRESH_INTERVAL,
      }
    )

    const [refreshCount, setRefreshCount] = useState(0)

    const refreshReservationData = async () => {
      await mutate()
      await mutateBlocks()
      setRefreshCount((pre) => pre + 1)
    }

    const restaurantReservations = useMemo(() => {
      return allRestaurantReservations.filter((item) => {
        // 予約の編集の場合は、自身の予約を除いて表示する
        if (mode === 'selectSeats') {
          if (selectParams?.restaurantReservation != null) {
            return item.id !== selectParams.restaurantReservation.id
          }
        }
        return true
      })
    }, [allRestaurantReservations, mode, selectParams])

    const otherReservations = useMemo(() => {
      return getOtherReservations({
        restaurantReservations,
      })
    }, [restaurantReservations])

    const { businessTimes } = useBusinessTimesFromGetCalendar(
      restaurantId,
      date
    )

    const isRestaurantOpenAt = useCallback(
      (hour: number, min: number) =>
        businessTimes?.some(({ open, startAt, endAt }) => {
          if (!open || !startAt || !endAt) return false
          // 営業時間が日を跨ぐ場合は1日分の秒数を加算し、適切に計算できるようにする
          const getAdjustedSeconds = (day: dayjs.Dayjs) =>
            day.isSame(date, 'day')
              ? toSeconds(day.hour(), day.minute())
              : 86400 + toSeconds(day.hour(), day.minute())
          const startSeconds = getAdjustedSeconds(dayjs.unix(startAt.seconds))
          const endSeconds = getAdjustedSeconds(dayjs.unix(endAt.seconds))
          return inRange(toSeconds(hour, min), startSeconds, endSeconds)
        }),
      [businessTimes, date]
    )

    const { data: restaurantReservationBlocks, mutate: mutateBlocks } =
      restaurantReservationBlockService.useListPeriod({
        restaurantId,
        startDate: date.format('YYYY-MM-DD'),
        endDate: date.format('YYYY-MM-DD'),
      })

    const [isShowMoreRequestReservations, setIsShowMoreRequestReservation] =
      useState(false)
    const { requestReservations: requestReservationsData = [] } =
      useRestaurantRequestReservations(restaurantId, { date })

    const filteredRequestReservations = useMemo(() => {
      return requestReservationsData.filter((requestReservation) => {
        if (
          requestReservation.reservation?.reservation_change_request?.status ===
          'requesting'
        ) {
          const fromReservationDate = dayjs(
            requestReservation.reservation.reservation_change_request
              .from_reserved_at
          ).format('YYYY-MM-DD')
          const toReservationDate = dayjs(
            requestReservation.reservation.reservation_change_request
              .to_reserved_at
          ).format('YYYY-MM-DD')
          const condition =
            // 同日の変更の場合は表示する
            fromReservationDate === toReservationDate ??
            // 変更前のChart日付では予約行のreservationで処理表示するため、リクエスト予約行では表示しない
            date.format('YYYY-MM-DD') !== fromReservationDate
          return condition
        }
        return true
      })
    }, [requestReservationsData, date])

    const reservationChangeRequests = useMemo(() => {
      return requestReservationsData.filter((requestReservation) => {
        if (
          requestReservation?.reservation?.reservation_change_request != null
        ) {
          // 変更リクエストが変更後日時にも含まれるがChart画面では表示しない
          const condition =
            date.format('YYYY-MM-DD') ===
            dayjs(
              requestReservation.reservation.reservation_change_request
                .to_reserved_at
            ).format('YYYY-MM-DD')
          return !condition
        }
        return false
      })
    }, [requestReservationsData, date])

    const requestReservations = useMemo(
      () =>
        mode === 'default'
          ? filteredRequestReservations.slice(
              0,
              isShowMoreRequestReservations
                ? undefined
                : DEFAULT_SHOW_REQUEST_RESERVATION_SIZE
            )
          : [],
      [isShowMoreRequestReservations, mode, filteredRequestReservations]
    )

    const scrollViewRef = useRef<ScrollView>(null)
    const scrollViewContentOffsetRef = useRef(0)

    const scrollRootRef = useRef<Animated.ScrollView>(null)

    useImperativeHandle(ref, () => ({
      scrollToTime: ({ time, animated = false }) => {
        setTimeout(() => {
          const { hours: hour, minutes: minute } = toHoursAndMinutes(time)
          scrollViewRef.current?.scrollTo({
            x:
              scrollViewContentOffsetRef.current > 0
                ? scrollViewContentOffsetRef.current
                : (hour + minute / 60) *
                  (width < sm ? HOUR_SP_WIDTH : HOUR_PC_WIDTH),
            y: 0,
            animated,
          })
        }, 0)
      },
      refreshData: refreshReservationData,
    }))

    const latestRootScrollViewEvent =
      useSharedValue<ReanimatedScrollEvent | null>(null)
    const headerTranslateY = useSharedValue(0)

    const onScroll = useAnimatedScrollHandler((event) => {
      const scrollY = event.contentOffset.y
      // TODO: react-compiler for react-native-reanimated
      // eslint-disable-next-line react-compiler/react-compiler
      headerTranslateY.value =
        scrollY < SCROLL_VIEW_PADDING_Y ? 0 : scrollY - SCROLL_VIEW_PADDING_Y
      latestRootScrollViewEvent.value = event
    })

    const isLoading = tableSeatsLoading || restaurantReservationsLoading

    const prevIsLoading = usePrevious(isLoading)
    const prevDate = usePrevious(date)
    const prevAllRestaurantReservations = usePrevious(allRestaurantReservations)

    useEffect(() => {
      if (prevDate !== date) {
        onLoadStart?.()
      }
    }, [date, onLoadStart, prevDate])

    useEffect(() => {
      if (
        (prevDate !== date && !isLoading) ||
        (prevIsLoading !== isLoading && !isLoading)
      ) {
        onLoadEnd?.()
      }
    }, [date, isLoading, onLoadEnd, prevDate, prevIsLoading])

    const handleReservationAdded = useEffectEvent(() => {
      onReservationAdded?.()
    })

    useEffect(() => {
      // NOTE: 新規予約追加後データに反映されるまでを記録する
      if (
        (allRestaurantReservations?.length ?? 0) >
        (prevAllRestaurantReservations?.length ?? 0)
      ) {
        handleReservationAdded()
      }
    }, [
      prevAllRestaurantReservations,
      allRestaurantReservations,
      handleReservationAdded,
    ])

    const [menuPopOver, setMenuPopOver] = useState<
      | {
          visible: false
          absoluteX?: number
          absoluteY?: number
          items?: Items
        }
      | {
          visible: true
          absoluteX: number
          absoluteY: number
          items: Items
        }
    >({
      visible: false,
    })

    const headerCellStyle = useAnimatedStyle(() => {
      return { transform: [{ translateY: headerTranslateY.value }] }
    })

    const onPressBackgroundView = Gesture.Tap().onEnd((event, success) => {
      if (!success) return

      if (mode === 'default') {
        const startTime = getStartTimeFromPosition(event.x, width < sm)
        const seatId = getSeatIdFromPosition(
          event.y,
          width < sm,
          tableSeats,
          requestReservations.length + otherReservations.length
        )
        if (onStartSelectBlock) {
          runOnJS(onStartSelectBlock)({ startTime, seatId })
        }
      } else if (mode === 'selectBlock') {
        const { startTime, endTime } = selectParams!
        const tappedTime = getStartTimeFromPosition(event.x, width < sm)
        // 選択ブロックの外側をタップした場合は終了する
        if (
          onEndSelectBlock &&
          (tappedTime < startTime || tappedTime > endTime)
        ) {
          runOnJS(onEndSelectBlock)()
        }
      }
    })
    onPressBackgroundView.enabled(mode === 'default' || mode === 'selectBlock')

    const onPressReservation = ({
      restaurantReservation,
      tableSeatId,
      absoluteX,
      absoluteY,
    }: {
      restaurantReservation: RestaurantReservationModel
      tableSeatId: TableSeatModel['id']
      absoluteX: number
      absoluteY: number
    }) => {
      const items = findOverlappingItemsByTableSeatId({
        tableSeatId,
        timeRange: {
          startAt: dayjs(restaurantReservation.start_at),
          endAt: dayjs(restaurantReservation.end_at),
        },
        reservations: restaurantReservations,
        reservationBlockPeriods:
          restaurantReservationBlocks?.restaurantReservationBlockPeriods ?? [],
      })

      if (items.reservationBlockPeriods.length > 0) {
        setMenuPopOver({
          visible: true,
          absoluteX,
          absoluteY,
          items,
        })
      }

      if (restaurantReservation.reservation_change_request != null) {
        if (Platform.OS === 'web') {
          navigate(
            `/restaurants/${restaurantId}/reservations/change_requests/${restaurantReservation.id}`
          )
        } else {
          navigation.navigate('ChangeRequestReservation', {
            data: {
              ...restaurantReservation,
              restaurant_course:
                restaurantReservation.reservation_courses.length > 0
                  ? restaurantReservation.reservation_courses[0]
                      .restaurant_course
                  : undefined,
            },
            customer: restaurantReservation.customers[0],
          })
        }
        return
      }

      if (Platform.OS === 'web') {
        navigate(
          `/restaurants/${restaurantId}/reservations/${restaurantReservation.id}`
        )
      } else {
        navigation.navigate('ReservationsShow', {
          restaurantReservationId: restaurantReservation.id,
        })
      }
    }

    const onPressReservationBlockPeriod = ({
      restaurantReservationBlockPeriod,
      tableSeatId,
      absoluteX,
      absoluteY,
    }: {
      restaurantReservationBlockPeriod: ListPeriodResponse_RestaurantReservationBlockPeriod
      tableSeatId: TableSeatModel['id']
      absoluteX: number
      absoluteY: number
    }) => {
      const items = findOverlappingItemsByTableSeatId({
        tableSeatId,
        timeRange: {
          startAt: dayjs(
            Timestamp.toDate(restaurantReservationBlockPeriod.startAt!)
          ),
          endAt: dayjs(
            Timestamp.toDate(restaurantReservationBlockPeriod.endAt!)
          ),
        },
        reservations: restaurantReservations,
        reservationBlockPeriods:
          restaurantReservationBlocks?.restaurantReservationBlockPeriods ?? [],
      })

      if (items.reservations.length > 0) {
        setMenuPopOver({
          visible: true,
          absoluteX,
          absoluteY,
          items,
        })
        return
      }

      if (Platform.OS === 'web') {
        navigate(
          `/restaurants/${restaurantId}/reservations/blocks/${restaurantReservationBlockPeriod.restaurantReservationBlock?.id}?startAt=${restaurantReservationBlockPeriod.startAt?.seconds}&endAt=${restaurantReservationBlockPeriod.endAt?.seconds}`
        )
      } else {
        navigation.navigate('ReservationBlocksShow', {
          restaurantReservationBlockId:
            restaurantReservationBlockPeriod.restaurantReservationBlock?.id,
          startAt: restaurantReservationBlockPeriod.startAt,
          endAt: restaurantReservationBlockPeriod.endAt,
        })
      }
    }

    const onMoveTableSeat = async (
      restaurantReservation: RestaurantReservationModel,
      selectTableSeatId: string,
      moveToTableSeatId: string
    ) => {
      const alert = Platform.select({
        web: alertRef.current?.alert,
        default: Alert.alert,
      })
      // 移動先シートの人数上限が超えていないか
      const tableSeat = tableSeats.find((ts) => ts.id === moveToTableSeatId)
      if (tableSeat == null) {
        onError(new Error(t('席情報が取得できませんでした。')))
        refreshReservationData()
        return
      }

      if (tableSeat != null) {
        // 移動前に保持している席で許容出来る席数
        const allMaxPartySize = restaurantReservation.table_seats.reduce(
          (acc, { id }) => {
            if (id !== moveToTableSeatId) return acc
            const ts = tableSeats.find((i) => i.id === id)
            if (ts != null) acc += ts?.max_party_size ?? 0
            return acc
          },
          0
        )
        const requireSeatSize =
          restaurantReservation.party_size - allMaxPartySize
        if (requireSeatSize > (tableSeat?.max_party_size ?? 0)) {
          // 席変更後の席数が足りないためエラーを出す
          alert(t('選択できないです'), t('席を変更しますか？'), [
            {
              text: t('いいえ'),
              onPress: () => {
                refreshReservationData()
              },
              style: 'cancel',
            },
            {
              text: t('はい'),
              onPress: () => {
                if (Platform.OS === 'web') {
                  navigate(
                    `/restaurants/${restaurantId}/reservations/${restaurantReservation.id}/change_seat`
                  )
                } else {
                  navigation.navigate('ChangeSeatForm', {
                    restaurantReservationId: restaurantReservation.id,
                  })
                  refreshReservationData()
                }
              },
            },
          ])
          return
        }
      }

      // 移動先シートが他で予約されているか
      const bookingReservations = allRestaurantReservations
        .filter((i) => i.id !== restaurantReservation.id)
        .filter((i) =>
          i.table_seats
            .flatMap((t) => t.id)
            .some((tid) => moveToTableSeatId === tid)
        )
      //  席の移動
      // 新しいシートId列 例）１、２、A
      const newTableSeatId = restaurantReservation.table_seats
        .map((i) => i.id)
        .filter((i) => i !== selectTableSeatId)
      newTableSeatId.push(moveToTableSeatId)

      const runSimpleMoveTableSeat = () => {
        alert(t('席の移動'), t('席を移動しますか？'), [
          {
            text: t('いいえ'),
            onPress: refreshReservationData,
            style: 'cancel',
          },
          {
            text: t('はい'),
            onPress: async () => {
              if (token == null) return
              const { error } = await updateRestaurantReservation(
                token,
                restaurantId,
                restaurantReservation.id,
                {
                  table_seat_ids: newTableSeatId,
                }
              )
              if (error != null) return
              displayToastSuccess(
                t('予約を更新しました'),
                undefined,
                width < sm ? { marginBottom: 48 } : undefined
              )
              refreshReservationData()
            },
          },
        ])
      }
      if (bookingReservations.length === 0) {
        runSimpleMoveTableSeat()
        return
      }

      // 時刻のかぶっているものがあるか
      const duplicateTimeReservations = bookingReservations.filter(
        (br) =>
          dayjs(br.end_at).isAfter(restaurantReservation.start_at) &&
          dayjs(restaurantReservation.end_at).isAfter(br.start_at)
      )

      if (
        duplicateTimeReservations.some(
          (dtr) => !dayjs(dtr.start_at).isSame(restaurantReservation.start_at)
        )
      ) {
        // 開始時刻が同じでない予約が入っているか
        alert(t('他の予約と被っています'), t('席を変更しますか？'), [
          {
            text: t('いいえ'),
            onPress: refreshReservationData,
            style: 'cancel',
          },
          {
            text: t('はい'),
            onPress: () => {
              if (Platform.OS === 'web') {
                navigate(
                  `/restaurants/${restaurantId}/reservations/${restaurantReservation.id}/change_seat`
                )
              } else {
                navigation.navigate('ChangeSeatForm', {
                  restaurantReservationId: restaurantReservation.id,
                })
                refreshReservationData()
              }
            },
          },
        ])
        return
      }
      const exchangeReservation = bookingReservations.find((br) =>
        dayjs(br.start_at).isSame(restaurantReservation.start_at)
      )

      if (exchangeReservation == null) {
        runSimpleMoveTableSeat()
        return
      }

      alert(t('席の入れ替え'), t('席を入れ替えますか？'), [
        {
          text: t('いいえ'),
          onPress: () => {
            refreshReservationData()
          },
          style: 'cancel',
        },
        {
          text: t('はい'),
          onPress: async () => {
            if (token == null) return
            /**
             * NOTE
             * 予約１：テーブル１、２、３
             * 予約２：テーブルA、B、C
             * のとき、予約１をテーブル１、２，Aとしたい場合は
             * table_seat_ids = １、２、A
             * other_table_seats = ３、B、C
             * とする。
             * このとき、３を交換元、Aを交換先と呼ぶ
             */

            // 交換先の新しいシートId列 例）B、C、３
            const bookingReservationNewTableSeatId =
              exchangeReservation.table_seats
                .map((i) => i.id)
                .filter((i) => i !== moveToTableSeatId)
            bookingReservationNewTableSeatId.push(selectTableSeatId)

            const params = {
              end_at: dayjs(restaurantReservation.end_at).format(
                'YYYY-MM-DD HH:mm:ss'
              ),
              table_seat_ids: newTableSeatId,
              other_table_seats: [
                {
                  reservation_id: exchangeReservation.id,
                  table_seat_ids: bookingReservationNewTableSeatId,
                },
              ],
            }
            const { error } = await exchangeRestaurantReservationTableSeats(
              token,
              restaurantId,
              restaurantReservation.id,
              params
            )
            if (error != null) return
            displayToastSuccess(
              t('席を交換しました'),
              undefined,
              width < sm ? { marginBottom: 48 } : undefined
            )
            refreshReservationData()
          },
        },
      ])
    }

    return (
      <>
        {isLoading && (
          <View
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 2,
            }}
          >
            <Loading />
          </View>
        )}
        <Animated.ScrollView
          {...scrollViewProps}
          contentContainerStyle={[
            {
              position: 'relative',
              paddingVertical: width < sm ? 8 : SCROLL_VIEW_PADDING_Y,
              paddingHorizontal: width < sm ? 0 : SCROLL_VIEW_PC_PADDING_X,
            },
            Platform.OS === 'web' ? { height: '100%' } : {},
            scrollViewProps?.contentContainerStyle,
          ]}
          onScroll={onScroll}
          scrollEventThrottle={16}
          ref={scrollRootRef}
        >
          <View
            style={[
              {
                flexDirection: 'row',
                height: Platform.OS === 'web' ? '100%' : undefined,
              },
            ]}
          >
            <View
              style={{
                marginRight: width < sm ? 4 : 20,
                marginLeft: width < sm ? 4 : 0,
              }}
            >
              {width >= sm && (
                <Animated.View
                  style={[{ flexDirection: 'row', zIndex: 1 }, headerCellStyle]}
                >
                  <HeaderCell width={SEAT_PC_WIDTH} text={t('席')} isFirst />
                  <HeaderCell
                    width={PARTY_SIZE_WIDTH}
                    text={t('定員')}
                    isFirst={false}
                    justifyContent="center"
                  />
                </Animated.View>
              )}
              <View style={width < sm && { marginTop: CELL_HEIGHT }}>
                {otherReservations.length > 0 && (
                  <View key={`other`} style={{ flexDirection: 'row' }}>
                    <View
                      style={{
                        width:
                          width < sm
                            ? SEAT_SP_WIDTH
                            : SEAT_PC_WIDTH + PARTY_SIZE_WIDTH,
                        height:
                          (width < sm ? SEAT_SP_HEIGHT : SEAT_PC_HEIGHT) *
                          otherReservations.length,
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        borderLeftWidth: width < sm ? 0 : BORDER_WIDTH,
                        borderColor: Colors.border,
                        borderRightWidth: width < sm ? 0 : BORDER_WIDTH,
                        borderBottomWidth: BORDER_WIDTH * 2,
                        paddingHorizontal: width < sm ? 8 : PADDING_HORIZONTAL,
                        backgroundColor: Colors.white,
                      }}
                    >
                      {width < sm ? (
                        <View style={{ marginTop: 8 }}>
                          <Text
                            style={{
                              fontSize: 14,
                              fontWeight: '600',
                            }}
                          >
                            {t('座席指定なし')}
                          </Text>
                          <Text
                            style={{
                              fontSize: 12,
                              marginTop: -18,
                              alignSelf: 'flex-end',
                            }}
                          >
                            {t('{{count}}件', {
                              count: otherReservations.length,
                            })}
                          </Text>
                        </View>
                      ) : (
                        <Text
                          style={{
                            fontSize: 14,
                            marginTop: 14,
                          }}
                        >
                          {t('席指定なしの予約\n{{count}}件', {
                            count: otherReservations.length,
                          })}
                        </Text>
                      )}
                    </View>
                  </View>
                )}
                {requestReservations.length > 0 && (
                  <View
                    key={'request_reservation'}
                    style={{ flexDirection: 'row' }}
                  >
                    <View
                      style={{
                        width:
                          width < sm
                            ? SEAT_SP_WIDTH
                            : SEAT_PC_WIDTH + PARTY_SIZE_WIDTH,
                        height:
                          (width < sm ? SEAT_SP_HEIGHT : SEAT_PC_HEIGHT) *
                          requestReservations.length,
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        borderLeftWidth: width < sm ? 0 : BORDER_WIDTH,
                        borderColor: Colors.border,
                        borderRightWidth: width < sm ? 0 : BORDER_WIDTH,
                        borderBottomWidth: BORDER_WIDTH * 2,
                        paddingHorizontal: width < sm ? 8 : PADDING_HORIZONTAL,
                        backgroundColor: Colors.white,
                      }}
                    >
                      {width < sm ? (
                        <View style={{ marginTop: 8 }}>
                          {/* eslint-disable ar-i18n/require-translation-ja */}
                          <Trans
                            i18nKey={
                              '<0>リクエスト</0><1><0>予約</0><1>{{count}}件</1></1>'
                            }
                            values={{
                              count: filteredRequestReservations.length,
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 14,
                                fontWeight: '600',
                              }}
                            >
                              {'リクエスト'}
                            </Text>
                            <View
                              style={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'baseline',
                                width: 66,
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 14,
                                  fontWeight: '600',
                                }}
                              >
                                {'予約'}
                              </Text>
                              <Text
                                style={{
                                  fontSize: 12,
                                }}
                              >
                                {`${filteredRequestReservations.length}`}
                                {'件'}
                              </Text>
                            </View>
                          </Trans>
                          {/* eslint-enable ar-i18n/require-translation-ja */}
                        </View>
                      ) : (
                        <Text
                          style={{
                            fontSize: 14,
                            marginTop: 14,
                          }}
                        >
                          {t('リクエスト予約{{count}}件', {
                            count: filteredRequestReservations.length,
                          })}
                        </Text>
                      )}
                      {filteredRequestReservations.length >
                        DEFAULT_SHOW_REQUEST_RESERVATION_SIZE && (
                        <Button
                          mode="text"
                          onPress={() => {
                            setIsShowMoreRequestReservation(
                              !isShowMoreRequestReservations
                            )
                          }}
                          variant="primary"
                          style={width < sm && { paddingHorizontal: 0 }}
                        >
                          <Text
                            style={[
                              width < sm && {
                                color: Colors.primary,
                                height: 40,
                              },
                              {
                                marginTop: 14,
                                fontSize: 14,
                              },
                            ]}
                          >
                            {width < sm
                              ? isShowMoreRequestReservations
                                ? t('とじる')
                                : t('もっとみる')
                              : isShowMoreRequestReservations
                                ? t('閉じる')
                                : t('もっと見る')}
                          </Text>
                        </Button>
                      )}
                    </View>
                  </View>
                )}
                {tableSeats?.map((tableSeat, index) => {
                  return (
                    <View key={tableSeat.id} style={{ flexDirection: 'row' }}>
                      <View
                        style={[
                          {
                            borderColor: Colors.border,
                            backgroundColor: Colors.white,
                          },
                          width < sm
                            ? {
                                width: SEAT_SP_WIDTH,
                                height: SEAT_SP_HEIGHT,
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                borderRightWidth: 0,
                                borderBottomWidth:
                                  tableSeats.length - 1 === index
                                    ? 0
                                    : BORDER_WIDTH,
                                paddingHorizontal: 8,
                              }
                            : {
                                width: SEAT_PC_WIDTH,
                                height: SEAT_PC_HEIGHT,
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                borderLeftWidth: BORDER_WIDTH,
                                borderRightWidth: BORDER_WIDTH,
                                borderBottomWidth: BORDER_WIDTH,
                                paddingHorizontal: PADDING_HORIZONTAL,
                              },
                        ]}
                      >
                        <Text
                          style={{
                            fontSize: 14,
                            lineHeight: 21,
                            fontWeight: width < sm ? '600' : '300',
                          }}
                          numberOfLines={width < sm ? 1 : undefined}
                          ellipsizeMode={width < sm ? 'tail' : undefined}
                        >
                          {tableSeat.name}
                        </Text>
                        {width < sm && (
                          <Text style={{ fontSize: 12, lineHeight: 18 }}>
                            {t('{{party_size}}名', {
                              party_size: getPartySize(tableSeat),
                            })}
                          </Text>
                        )}
                      </View>
                      {width >= sm && (
                        <View
                          style={{
                            width: PARTY_SIZE_WIDTH,
                            height: SEAT_PC_HEIGHT,
                            borderColor: Colors.border,
                            borderRightWidth: BORDER_WIDTH,
                            borderBottomWidth: BORDER_WIDTH,
                            paddingHorizontal: PADDING_HORIZONTAL,
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: Colors.white,
                          }}
                        >
                          <Text style={{ fontSize: 14, lineHeight: 21 }}>
                            {getPartySize(tableSeat)}
                          </Text>
                        </View>
                      )}
                    </View>
                  )
                })}
              </View>
            </View>
            <ScrollView
              horizontal
              showsHorizontalScrollIndicator={false}
              style={{
                flex: 1,
                position: 'relative',
                height:
                  (width >= sm ? SEAT_PC_HEIGHT : SEAT_SP_HEIGHT) *
                    (tableSeats?.length ?? 0) + // 席数分
                  (width < sm ? SEAT_SP_HEIGHT : SEAT_PC_HEIGHT) *
                    otherReservations.length + // 未指定席分
                  (width < sm ? SEAT_SP_HEIGHT : SEAT_PC_HEIGHT) *
                    requestReservations.length + // リクエスト予約分
                  24, // ヘッダー分
              }}
              ref={scrollViewRef}
              onScroll={(event) => {
                scrollViewContentOffsetRef.current =
                  event.nativeEvent.contentOffset.x
              }}
              scrollEventThrottle={16}
            >
              <GestureDetector gesture={onPressBackgroundView}>
                <View
                  style={{
                    flex: 1,
                    position: 'relative',
                  }}
                >
                  {mode !== 'default' && (
                    <SelectSeatList
                      mode={mode}
                      selectParams={selectParams!}
                      tableSeats={tableSeats}
                      restaurantReservations={restaurantReservations}
                      date={date}
                      otherReservations={otherReservations}
                    />
                  )}
                  <View
                    style={{
                      flexDirection: 'column',
                      position: 'relative',
                      zIndex: 1,
                    }}
                  >
                    <View style={{ zIndex: 1 }}>
                      {otherReservations.map((restaurantReservation, index) => {
                        return (
                          <Reservation
                            key={`${restaurantReservation.id}_other`}
                            type={
                              mode === 'default' ? 'default' : 'unselectable'
                            }
                            index={index}
                            restaurantReservation={restaurantReservation}
                            restaurantId={restaurantId}
                            date={date}
                            onPress={() => {
                              if (Platform.OS === 'web') {
                                navigate(
                                  `/restaurants/${restaurantId}/reservations/${restaurantReservation.id}`
                                )
                              } else {
                                navigation.navigate('ReservationsShow', {
                                  restaurantReservationId:
                                    restaurantReservation.id,
                                })
                              }
                            }}
                          />
                        )
                      })}
                      {requestReservations.map((requestReservation, index) => {
                        return (
                          <Reservation
                            key={`${requestReservation.id}_request_reservation`}
                            index={index + otherReservations.length}
                            type="default"
                            restaurantReservation={{
                              ...requestReservation,
                              memo: requestReservation.memo ?? '',
                              adult_party_size:
                                requestReservation.adult_party_size,
                              customers:
                                requestReservation.customer == null
                                  ? []
                                  : [
                                      {
                                        ...requestReservation.customer,
                                        reservation_first_name:
                                          requestReservation.name ??
                                          requestReservation.customer
                                            .reservation_first_name,
                                        reservation_last_name:
                                          requestReservation.name
                                            ? ''
                                            : requestReservation.customer
                                                .reservation_last_name,
                                      },
                                    ],
                              kind: 'normal',
                              smart_payment: null,
                              reservation_courses: [],
                            }}
                            restaurantId={restaurantId}
                            date={date}
                            onPress={() => {
                              if (requestReservation.reservation != null) {
                                if (Platform.OS === 'web') {
                                  navigate(
                                    `/restaurants/${restaurantId}/reservations/change_requests/${requestReservation.reservation.uuid}`
                                  )
                                } else {
                                  navigation.navigate(
                                    'ChangeRequestReservation',
                                    {
                                      data: requestReservation.reservation,
                                      customer: requestReservation.customer,
                                    }
                                  )
                                }
                                return
                              }

                              if (Platform.OS === 'web') {
                                navigate(
                                  `/restaurants/${restaurantId}/reservations/requests/${requestReservation.id}`
                                )
                              } else {
                                navigation.navigate('RequestReservation', {
                                  id: requestReservation.id,
                                })
                              }
                            }}
                          />
                        )
                      })}
                      {reservationChangeRequests.map(
                        (requestReservation, index) => {
                          index +=
                            requestReservations.length +
                            otherReservations.length
                          return (
                            <Reservation
                              key={`${requestReservation.id}_change_request_reservation`}
                              index={index}
                              type="default"
                              restaurantReservation={{
                                ...requestReservation,
                                memo: requestReservation.memo ?? '',
                                adult_party_size:
                                  requestReservation.adult_party_size,
                                customers:
                                  requestReservation.customer == null
                                    ? []
                                    : [requestReservation.customer],
                                kind: 'normal',
                                smart_payment: null,
                                reservation_courses: [],
                              }}
                              restaurantId={restaurantId}
                              date={date}
                              onPress={() => {
                                if (Platform.OS === 'web') {
                                  navigate(
                                    `/restaurants/${restaurantId}/reservations/requests/${requestReservation.id}`
                                  )
                                } else {
                                  navigation.navigate('RequestReservation', {
                                    id: requestReservation.id,
                                  })
                                }
                              }}
                              isWarning
                            />
                          )
                        }
                      )}
                      {restaurantReservations?.map((restaurantReservation) => {
                        return restaurantReservation.table_seats.map(
                          (tableSeat) => {
                            let index = tableSeats.findIndex(
                              ({ id }) => tableSeat.id === id
                            )

                            if (index < 0) {
                              // アーカイブされた席に紐づいている場合は表示しない
                              return null
                            }

                            index +=
                              otherReservations.length +
                              requestReservations.length +
                              reservationChangeRequests.length

                            return (
                              <Reservation
                                key={`${restaurantReservation.id}_${tableSeat.id}_${refreshCount}`}
                                type={
                                  mode === 'default'
                                    ? 'default'
                                    : 'unselectable'
                                }
                                index={index}
                                restaurantReservation={restaurantReservation}
                                restaurantId={restaurantId}
                                onPress={({ absoluteX, absoluteY }) =>
                                  onPressReservation({
                                    restaurantReservation,
                                    tableSeatId: tableSeat.id,
                                    absoluteX,
                                    absoluteY,
                                  })
                                }
                                date={date}
                                latestRootScrollViewEvent={
                                  latestRootScrollViewEvent
                                }
                                scrollRootRef={scrollRootRef}
                                onDragEnd={(nextIndex) => {
                                  const tableSeatIndex =
                                    nextIndex -
                                    requestReservations.length -
                                    otherReservations.length
                                  const nextTable = tableSeats[tableSeatIndex]
                                  if (nextTable != null) {
                                    onMoveTableSeat(
                                      restaurantReservation,
                                      tableSeat.id,
                                      nextTable.id
                                    )
                                  } else {
                                    onError(
                                      new Error(
                                        t('移動先の席を特定できませんでした。')
                                      )
                                    )
                                    refreshReservationData()
                                  }
                                }}
                                isWarning={
                                  restaurantReservation.reservation_change_request !=
                                  null
                                }
                              />
                            )
                          }
                        )
                      })}
                      {restaurantReservationBlocks?.restaurantReservationBlockPeriods.map(
                        (restaurantReservationBlockPeriod) => {
                          return restaurantReservationBlockPeriod.tableSeats.map(
                            (seat) => {
                              const index =
                                otherReservations.length +
                                requestReservations.length +
                                reservationChangeRequests.length +
                                tableSeats.findIndex(({ id }) => seat.id === id)
                              return (
                                <RestaurantReservationBlockPeriod
                                  key={`${restaurantReservationBlockPeriod.id}_${seat.id}`}
                                  type={
                                    mode === 'default'
                                      ? 'default'
                                      : 'unselectable'
                                  }
                                  index={index}
                                  restaurantReservationBlockPeriod={
                                    restaurantReservationBlockPeriod
                                  }
                                  onPress={({ absoluteX, absoluteY }) => {
                                    onPressReservationBlockPeriod({
                                      restaurantReservationBlockPeriod,
                                      tableSeatId: seat.id,
                                      absoluteX,
                                      absoluteY,
                                    })
                                  }}
                                  isSp={width < sm}
                                />
                              )
                            }
                          )
                        }
                      )}
                    </View>
                    <Animated.View
                      style={[
                        {
                          flexDirection: 'row',
                          zIndex: 2,
                        },
                        headerCellStyle,
                      ]}
                    >
                      {HOURS.map((hour, index) => (
                        <HeaderCell
                          key={`${hour}`}
                          width={width < sm ? HOUR_SP_WIDTH : HOUR_PC_WIDTH}
                          text={`${hour}:00`}
                          isFirst={index === 0}
                          isSp={width < sm}
                        />
                      ))}
                    </Animated.View>
                    <HourCellList
                      otherReservations={otherReservations}
                      requestReservations={requestReservations}
                      tableSeats={tableSeats}
                      isRestaurantOpenAt={isRestaurantOpenAt}
                      isSp={width < sm}
                    />
                  </View>
                </View>
              </GestureDetector>
            </ScrollView>
          </View>
          <Menu
            visible={menuPopOver.visible}
            anchor={{
              x: menuPopOver?.absoluteX ?? 0,
              y: menuPopOver?.absoluteY ?? 0,
            }}
            onDismiss={() => {
              setMenuPopOver((menuPopOver) => ({
                ...menuPopOver,
                visible: false,
              }))
            }}
            contentStyle={{
              paddingVertical: 0,
            }}
          >
            {menuPopOver.items?.reservations.map((restaurantReservation) => {
              return (
                <Menu.Item
                  key={`${restaurantReservation.id}`}
                  onPress={() => {
                    setMenuPopOver((menuPopOver) => ({
                      ...menuPopOver,
                      visible: false,
                    }))
                    if (Platform.OS === 'web') {
                      navigate(
                        `/restaurants/${restaurantId}/reservations/${restaurantReservation.id}`
                      )
                    } else {
                      navigation.navigate('ReservationsShow', {
                        restaurantReservationId: restaurantReservation.id,
                      })
                    }
                  }}
                  title={t('{{name}} の予約', {
                    name: getReservationText(restaurantReservation) || '-',
                  })}
                  contentStyle={{
                    minWidth: 240,
                  }}
                />
              )
            })}
            {menuPopOver.items?.reservationBlockPeriods.map(
              (reservationBlockPeriod) => {
                return (
                  <Menu.Item
                    key={`${reservationBlockPeriod.id}`}
                    onPress={() => {
                      setMenuPopOver((menuPopOver) => ({
                        ...menuPopOver,
                        visible: false,
                      }))
                      if (Platform.OS === 'web') {
                        navigate(
                          `/restaurants/${restaurantId}/reservations/blocks/${reservationBlockPeriod.restaurantReservationBlock?.id}?startAt=${reservationBlockPeriod.startAt?.seconds}&endAt=${reservationBlockPeriod.endAt?.seconds}`
                        )
                      } else {
                        navigation.navigate('ReservationBlocksShow', {
                          restaurantReservationBlockId:
                            reservationBlockPeriod.restaurantReservationBlock
                              ?.id,
                          startAt: reservationBlockPeriod.startAt,
                          endAt: reservationBlockPeriod.endAt,
                        })
                      }
                    }}
                    title={t('ブロック')}
                    contentStyle={{
                      minWidth: 240,
                    }}
                  />
                )
              }
            )}
          </Menu>
        </Animated.ScrollView>
        <View
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
          pointerEvents="box-none"
        >
          <AlertProvider ref={alertRef} />
        </View>
      </>
    )
  }
)
