import React, { useMemo } from 'react'
import { View } from 'react-native'
import dayjs from '../../../../modules/dayjs'
import { t } from '../../../../modules/i18n'
import {
  GOURMET_SITE_PROVIDER_SOURCE,
  GourmetSiteProvider,
} from '../../../../types/ForR/GourmetSiteSetting'
import { Colors } from '../../../../constants/Colors'
import { faGear } from '@fortawesome/pro-solid-svg-icons/faGear'
import { OutboundSettingData } from '../../../../types/ForR/OutboundSetting'
import { Text } from '../../../Text'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { TouchableOpacity } from '../../../Touchables'

const NO_DATA_DATE_TEXT = '----/--/-- --:--'
const DATE_FORMAT = 'YYYY/MM/DD HH:mm'

export type OutboundSettingListItemType = Partial<OutboundSettingData> & {
  site: GourmetSiteProvider
}

interface OutboundSettingListItemProps {
  outboundSetting: OutboundSettingListItemType
  onPressSetting: () => void
}

export function OutboundSettingListItem({
  outboundSetting,
  onPressSetting,
}: OutboundSettingListItemProps) {
  const statusInfo = useMemo(() => {
    if (outboundSetting?.id == null)
      return {
        isSet: false,
        label: t('未連携'),
        setAtText: NO_DATA_DATE_TEXT,
        lastReceivedAtText: NO_DATA_DATE_TEXT,
        labelStyle: {
          color: Colors.black,
          backgroundColor: Colors.black6,
        },
      }
    if (outboundSetting?.enabled) {
      return {
        isSet: true,
        label: t('連携中'),
        setAtText: dayjs(outboundSetting.set_at).tz().format(DATE_FORMAT),
        lastReceivedAtText:
          outboundSetting.mail_last_received_at == null
            ? NO_DATA_DATE_TEXT
            : dayjs(outboundSetting.mail_last_received_at)
                .tz()
                .format(DATE_FORMAT),
        labelStyle: {
          color: Colors.success,
          backgroundColor: Colors.accentBg,
        },
      }
    } else {
      return {
        isSet: false,
        label: t('連携停止中'),
        setAtText: NO_DATA_DATE_TEXT,
        lastReceivedAtText: NO_DATA_DATE_TEXT,
        labelStyle: {
          color: Colors.caution,
          backgroundColor: Colors.cautionBg,
        },
      }
    }
  }, [outboundSetting])

  const { setAtText, lastReceivedAtText } = statusInfo

  return (
    <View
      style={{
        flexDirection: 'row',
        paddingVertical: 8,
        alignItems: 'center',
      }}
    >
      <Text style={{ flex: 1, fontSize: 16 }}>
        {GOURMET_SITE_PROVIDER_SOURCE[outboundSetting.site].authenticateLabel}
      </Text>
      <View
        style={{
          flex: 1,
        }}
      >
        <Text
          style={[
            {
              alignSelf: 'flex-start',
              fontSize: 16,
              padding: 8,
              borderRadius: 2,
            },
            statusInfo.labelStyle,
          ]}
        >
          {statusInfo.label}
        </Text>
      </View>
      <View style={{ flex: 2 }}>
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ width: 140, fontSize: 14, color: Colors.black60 }}>
            {t('最新メール取り込み日')}
          </Text>
          <Text style={{ fontSize: 14, color: Colors.black60 }}>
            {lastReceivedAtText}
          </Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ width: 140, fontSize: 14, color: Colors.black60 }}>
            {t('連携開始日時')}
          </Text>
          <Text style={{ fontSize: 14, color: Colors.black60 }}>
            {setAtText}
          </Text>
        </View>
      </View>

      <TouchableOpacity
        style={{
          width: 96,
          flexDirection: 'row',
          columnGap: 8,
          alignItems: 'center',
        }}
        onPress={onPressSetting}
      >
        <FontAwesomeIcon icon={faGear} size={20} color={Colors.primary} />
        <Text
          style={{
            fontSize: 16,
            fontWeight: '300',
            lineHeight: 24,
            color: Colors.primary,
          }}
        >
          {t('連携設定')}
        </Text>
      </TouchableOpacity>
    </View>
  )
}
