import React, { useEffect } from 'react'
import { View } from 'react-native'
import { Checkbox } from '@hello-ai/ar_shared/src/components/Checkbox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import {
  allergyTagName,
  getTagIcon,
  useReservationTags,
} from '@hello-ai/for_r_app/src/models/ReservationTag'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { ReservationTag } from '@hello-ai/ar_shared/src/types/ForR/ReservationTag'
import { usePrevious } from '@hello-ai/ar_shared/src/modules/usePrevious'
import { Customer } from '../../../models/Customer'

export function RestaurantReservationTags({
  value,
  onChange,
  allergy,
}: {
  value: ReservationTag['id'][]
  onChange: (value: string) => void
  // アレルギーの存在チェックはあえて boolean にせず引数そのままにしている
  // boolean 変換前後で2度レンダリングされてしまい、
  // ページロード時に必ず prevAllergy !== allergy となって自動チェックが走ってしまうため
  allergy: string | Customer | undefined
}) {
  const { reservationTags } = useReservationTags()
  const prevAllergy = usePrevious(allergy)

  // アレルギーが入力されている場合はアレルギータグに自動でチェックを入れる
  // ただし、手動でチェックを外すことも可能
  useEffect(() => {
    if (prevAllergy === allergy) return
    const allergyTag = reservationTags.find(
      (tag) => tag.name === allergyTagName
    )
    if (!allergyTag) return

    const hasAllergyTag = value.includes(allergyTag.id)
    if (
      (allergy !== undefined && !hasAllergyTag) ||
      (allergy === undefined && hasAllergyTag)
    ) {
      onChange(allergyTag.id)
    }
  }, [prevAllergy, allergy, reservationTags, value, onChange])

  return (
    <View
      style={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        columnGap: 16,
        rowGap: 4,
      }}
    >
      {reservationTags.map((tag) => {
        const icon = getTagIcon(tag.name)
        return (
          <Checkbox
            key={tag.id}
            onChange={() => onChange(tag.id)}
            checkboxLabel={
              <View
                style={{ flexDirection: 'row', alignItems: 'center', gap: 4 }}
              >
                <Text style={{ color: Colors.black, fontSize: 14 }}>
                  {tag.name}
                </Text>
                {icon && (
                  <FontAwesomeIcon
                    icon={icon}
                    size={16}
                    color={Colors.black40}
                  />
                )}
              </View>
            }
            checked={value.includes(tag.id)}
            checkboxLabelContainerStyle={[]}
          />
        )
      })}
    </View>
  )
}
