import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { onError, useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { SmartPayment } from '@hello-ai/ar_shared/src/types/ForR/RestaurantReservation'
import ModalCenter from '../../Shared/ModalCenter'
import { receiptSmartPayment } from '../../../models/TableOrder'
import numberWithDelimiter from '@hello-ai/for_r_app/src/modules/numberWithDelimiter'
import React, { useState } from 'react'
import {
  Keyboard,
  Pressable,
  View,
  useWindowDimensions,
  Platform,
} from 'react-native'
import { displayToastSuccess } from '@hello-ai/for_r_app/src/components/Shared/Toast'

interface PaymentResultModalProps {
  data: SmartPayment
  restaurantId: number
  reservationId: string
  onClose: () => void
  isModalVisible: boolean
  onIssueReceipt: (smartPaymentData?: SmartPayment) => void
}

export default function PaymentSuccessedModal({
  data,
  restaurantId,
  reservationId,
  isModalVisible,
  onClose,
  onIssueReceipt,
}: PaymentResultModalProps) {
  const { height: windowHeight } = useWindowDimensions()
  const { width, sm } = useResponsive()
  const token = useToken()
  const amount = data.amount
  const [name, setName] = useState<string>('')
  return (
    <>
      <View
        style={{ flex: 1, width: '100%', backgroundColor: Colors.bgLightBlack }}
      />
      <ModalCenter
        isModalVisible={isModalVisible}
        width={'98%'}
        height={windowHeight * 0.98}
        title={t('スマート決済で会計する')}
        onClose={onClose}
      >
        <Pressable
          onPress={Platform.OS === 'web' ? undefined : Keyboard.dismiss}
          style={{
            marginTop: 102,
            alignItems: 'center',
            rowGap: width >= sm ? 48 : 24,
          }}
        >
          <Text
            style={{
              width: '100%',
              fontSize: width >= sm ? 22 : 18,
              fontWeight: '600',
              lineHeight: width >= sm ? 33 : 27,
              textAlign: 'center',
            }}
          >
            {t('スマート決済で会計が完了しました')}
          </Text>
          <Text
            style={{
              width: '100%',
              fontSize: width >= sm ? 40 : 30,
              fontWeight: '600',
              lineHeight: 40,
              textAlign: 'center',
            }}
          >
            {t('合計金額 ¥{{amount}}', {
              amount: numberWithDelimiter(amount),
            })}
          </Text>
          <Button
            width={width >= sm ? 486 : 200}
            height={width >= sm ? 72 : 48}
            onPress={onClose}
            textStyle={{ fontSize: 22 }}
          >
            {t('会計を終了する')}
          </Button>
          <View
            style={{
              rowGap: width >= sm ? 24 : 12,
              padding: width >= sm ? 0 : 12,
            }}
          >
            {data.issue_payment_receipt_enabled ? (
              <>
                <Text
                  style={{
                    width: width >= sm ? '100%' : undefined,
                    fontSize: width >= sm ? 16 : 12,
                    fontWeight: '300',
                    lineHeight: width >= sm ? 24 : 18,
                    textAlign: 'left',
                    color: Colors.black,
                  }}
                >
                  {t(
                    'AutoReserveで登録されているお客様のメールアドレスに領収書を送信します'
                  )}
                </Text>
                <View
                  style={{
                    flexDirection: 'row',
                    columnGap: 12,
                    alignItems: 'center',
                  }}
                >
                  <TextInput
                    style={{ flex: 1, height: 48 }}
                    placeholder={t('領収書の宛名を入力')}
                    value={name}
                    onChangeText={setName}
                  />
                  <Button
                    mode="outline"
                    style={{
                      height: 48,
                    }}
                    onPress={async () => {
                      if (token == null) return
                      const { data, error } = await receiptSmartPayment(
                        token,
                        restaurantId,
                        reservationId,
                        {
                          name,
                        }
                      )
                      if (error != null) {
                        onError(error)
                        return
                      }
                      onIssueReceipt(data)
                      displayToastSuccess(t('領収書を送信しました'))
                    }}
                  >
                    {t('メール送信')}
                  </Button>
                </View>
              </>
            ) : (
              <Text
                style={{
                  width: width >= sm ? '100%' : undefined,
                  fontSize: width >= sm ? 16 : 12,
                  fontWeight: '300',
                  lineHeight: width >= sm ? 24 : 18,
                  textAlign: 'left',
                  color: Colors.black,
                }}
              >
                {t('領収書は送信済です')}
              </Text>
            )}
          </View>
        </Pressable>
      </ModalCenter>
    </>
  )
}
