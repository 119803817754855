import React from 'react'
import { View } from 'react-native'

import {
  getMichelinAwardLabel,
  getMichelinAwardStatusColor,
  getMichelinAwardStatusLabel,
} from '../../Restaurant/Information/common'
import { RestaurantMichelinAwardStatus } from '@hello-ai/ar_shared/src/types/ForR/Restaurant'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'

export function MichelinList({
  michelinAwards,
}: {
  michelinAwards: RestaurantMichelinAwardStatus[]
}) {
  return (
    <>
      {michelinAwards
        ?.filter((award) => award.status !== 'rejected')
        .map((award, index) => (
          <View key={index} style={[{ flexDirection: 'row', marginTop: 24 }]}>
            <Text style={[{ flex: 1 }]}>
              {t('{{year}}年 {{label}}', {
                year: award.year,
                label: getMichelinAwardLabel(award.category),
              })}
            </Text>
            <Text
              style={{
                flex: 1,
                color: getMichelinAwardStatusColor(award.status),
                flexGrow: 2,
              }}
            >
              {getMichelinAwardStatusLabel(award.status)}
            </Text>
          </View>
        ))}
    </>
  )
}
