import React from 'react'
import { View } from 'react-native'
import { Colors } from '../../../../constants/Colors'
import { Text } from '../../../Text'
import { Button } from '../../../Button'
import { t } from '../../../../modules/i18n'

interface PromptOutboundViewProps {
  onPressOutboundSetting: () => void
}

export default function PromptOutboundView({
  onPressOutboundSetting,
}: PromptOutboundViewProps) {
  return (
    <View
      style={{
        backgroundColor: Colors.white,
        paddingHorizontal: 32,
        paddingVertical: 48,
        rowGap: 24,
        alignItems: 'center',
      }}
    >
      <Text style={{ fontSize: 18, fontWeight: '600' }}>
        {t('他社グルメサイトのアカウント連携をおこなってください')}
      </Text>
      <Text style={{ fontSize: 18, fontWeight: '300', textAlign: 'center' }}>
        {t('利用するには、はじめに専用メールアドレス登録による')}
        {'\n'}
        {t('他社グルメサイトのアカウント連携が必要です。')}
      </Text>
      <Button
        style={{ width: 280, height: 48 }}
        onPress={onPressOutboundSetting}
      >
        {t('アカウント連携へ')}
      </Button>
    </View>
  )
}
