import {
  createAspidaSWRMutationParameters,
  createAspidaSWRParameters,
} from '@hello-ai/ar_shared/src/modules/aspida-swr'
import { client } from '@hello-ai/for_r_app/src/modules/api'
import useSWR, { useSWRMutation } from 'modules/swr'

export function useEntryPlanInvitation(invitationToken: string) {
  return useSWR(
    ...createAspidaSWRParameters(
      client.reservation_book.entry_plan_invitations._token(invitationToken)
    )
  )
}

export function useRegisterEntryPlan(invitationToken: string) {
  return useSWRMutation(
    ...createAspidaSWRMutationParameters(
      client.reservation_book.entry_plan_invitations._token(invitationToken)
        .accept,
      '$post'
    )
  )
}
