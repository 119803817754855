import React, { useCallback, useMemo, useState } from 'react'
import { View, Platform } from 'react-native'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import PaymentCalculateModal from './PaymentCalculateModal'
import PaymentSuccessedModal from './PaymentSuccessedModal'
import PaymentFailedModal from './PaymentFailedModal'
import { onError, useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { adjustmentsSmartPayment } from '../../../models/TableOrder'
import { SmartPayment as ISmartPayment } from '@hello-ai/ar_shared/src/types/ForR/RestaurantReservation'
import SelectRevisionCasherModal from './SelectRevisionCasherModal'
import { displayToastError } from '@hello-ai/for_r_app/src/components/Shared/Toast'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useRestaurantReservation } from '../../../models/RestaurantReservation'

import { useNavigate } from '../../../modules/navigation/useNavigate'
import { useNavigation } from '../../../modules/navigation/useNavigation'

interface RevisionSmartPaymentViewProps {
  restaurantId: number
  restaurantReservationId: string
}

export default function RevisionSmartPaymentView({
  restaurantId,
  restaurantReservationId,
}: RevisionSmartPaymentViewProps) {
  const [step, setStep] = useState(0)
  const token = useToken()
  const [params, setParams] = useState<{
    restaurantCrewMemberId: string
    reason: string
    amount: number
  } | null>(null)
  const [successedSmartPayment, setSuccessedSmartPayment] =
    useState<ISmartPayment | null>(null)
  const [failedAmount, setFailedAmount] = useState(0)

  const { restaurantReservation } = useRestaurantReservation(
    restaurantId,
    restaurantReservationId
  )

  const smartPayment = useMemo(
    () => restaurantReservation?.smart_payment,
    [restaurantReservation]
  )

  const navigation = useNavigation()
  const navigate = useNavigate()

  const onPressGoBack = useCallback(() => {
    if (Platform.OS === 'web') {
      navigate(-1)
    } else {
      navigation.goBack()
    }
  }, [navigate, navigation])

  const component = useMemo(() => {
    switch (step) {
      case 0:
        return (
          <SelectRevisionCasherModal
            restaurantId={restaurantId}
            defaultRestaurantCrewMemberId={
              params?.restaurantCrewMemberId ?? null
            }
            onPressSubmit={(param) => {
              setParams({
                amount: 0,
                ...param,
              })
              setStep(1)
            }}
            onPressCancel={onPressGoBack}
          />
        )
      case 1:
        return (
          <PaymentCalculateModal
            title={t('金額を修正する')}
            amountLabel={t('修正金額を入力')}
            description={t(
              '既存の決済をキャンセルし、決済をやり直しします。\n正しい金額を入力し、確定を押してください'
            )}
            submitLabel={t('修正金額を確定する')}
            defaultAmount={smartPayment?.amount}
            showCancelButton={false}
            onClose={onPressGoBack}
            onCancel={onPressGoBack}
            onSubmit={async (amount) => {
              if (
                token == null ||
                params == null ||
                restaurantId == null ||
                smartPayment == null
              )
                return
              const diffAmount = amount - smartPayment.amount
              if (diffAmount >= 0 && diffAmount < 50) {
                displayToastError(
                  t('50円未満の変更はできません。現金などでご対応ください')
                )
                return
              }
              const { error } = await adjustmentsSmartPayment(
                token,
                restaurantId,
                restaurantReservationId,
                {
                  amount: diffAmount,
                  restaurant_crew_member_id: params.restaurantCrewMemberId,
                  reason: params.reason,
                }
              )

              if (error != null) {
                onError(error)
                setFailedAmount(amount)
                setStep(-1)
                return
              }
              setSuccessedSmartPayment({
                ...smartPayment,
                amount,
                issue_payment_receipt_enabled: true,
              })
              setStep(2)
            }}
          />
        )
      case 2:
        return (
          successedSmartPayment != null &&
          restaurantId !== null && (
            <PaymentSuccessedModal
              restaurantId={restaurantId}
              reservationId={restaurantReservationId}
              data={successedSmartPayment}
              isModalVisible
              onClose={onPressGoBack}
              onIssueReceipt={(data) => {
                if (!data) return
                setSuccessedSmartPayment(data)
              }}
            />
          )
        )
      default:
        return (
          <PaymentFailedModal
            failedAmount={failedAmount}
            onClose={onPressGoBack}
            isModalVisible
          />
        )
    }
  }, [
    step,
    params,
    smartPayment,
    onPressGoBack,
    restaurantId,
    restaurantReservationId,
    token,
    successedSmartPayment,
    failedAmount,
  ])

  return (
    <View style={{ flex: 1, backgroundColor: Colors.white }}>{component}</View>
  )
}
