import React, { useState } from 'react'
import { ScrollView } from 'react-native-gesture-handler'
import { GOURMET_SITE_PROVIDER_SOURCE } from '@hello-ai/ar_shared/src/types/ForR/GourmetSiteSetting'
import { View } from 'react-native'
import {
  signInCheck,
  updateOutboundSettings,
} from '@hello-ai/for_r_app/src/models/SiteControllerOutboundSettings'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { useRestaurantId } from 'modules/useRestaurantId'
import { useGourmetSource } from 'modules/useGourmetSiteSource'
import { useNavigate, useParams } from 'react-router'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import {
  FormGroup,
  FormLabel,
  FormLabelWithHelper,
} from '@hello-ai/ar_shared/src/components/Form'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import {
  displayToastError,
  displayToastSuccess,
} from '@hello-ai/for_r_app/src/components/Shared/Toast'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'

export default function GourmetSiteAccountSettingEditLoginInfo() {
  const restaurantId = useRestaurantId()
  const token = useToken()
  const [loginId, setLoginId] = useState('')
  const [password, setPassword] = useState('')
  /**
   * 一休向けの店舗ID
   */
  const [siteRestaurantId, setSiteRestaurantId] = useState('')
  const isDisabledNextPress = loginId === '' || password === ''
  const source = useGourmetSource()
  const { outboundSettingId } = useParams<{ outboundSettingId: string }>()
  const provider = GOURMET_SITE_PROVIDER_SOURCE[source]
  const navigate = useNavigate()

  const onPressSubmit = async () => {
    if (isDisabledNextPress) return
    if (token == null) return
    if (outboundSettingId == null) return
    let authenticateGourmetSiteParams
    switch (source) {
      case 'tabelog':
      case 'tabelog_note': {
        authenticateGourmetSiteParams = {
          signin_id: loginId,
          password,
          site: source,
        }
        break
      }
      case 'ikkyu': {
        authenticateGourmetSiteParams = {
          signin_id: loginId,
          password,
          site: source,
          site_restaurant_id: siteRestaurantId,
        }
        break
      }
      case 'hotpepper': {
        authenticateGourmetSiteParams = {
          signin_id: loginId,
          password,
          site: source,
        }
        break
      }
    }
    if (authenticateGourmetSiteParams == null) return

    const { response, error: signInCheckError } = await signInCheck(
      token,
      restaurantId,
      authenticateGourmetSiteParams
    )
    if (signInCheckError != null) return

    const checkedSite = response?.data.result
    if (checkedSite == null) {
      displayToastError(t('ログインに失敗しました'))
      return
    }
    const { error } = await updateOutboundSettings(
      token,
      restaurantId,
      outboundSettingId,
      {
        ...authenticateGourmetSiteParams,
        enabled: true,
      }
    )
    if (error != null) return
    displayToastSuccess(t('ログイン情報を更新しました'))
    navigate(
      `/restaurants/${restaurantId}/gourmet_site/account_settings/${checkedSite}`,
      {
        replace: true,
      }
    )
  }

  const onPressCancel = () => navigate(-1)

  return (
    <ScrollView
      style={{ backgroundColor: Colors.white }}
      contentContainerStyle={{ paddingTop: 40, marginHorizontal: 112 }}
    >
      <Text style={{ fontSize: 22, fontWeight: '600', marginBottom: 40 }}>
        {t('設定中のログイン情報を編集します')}
      </Text>
      <Text style={{ fontSize: 18, fontWeight: '300' }}>
        {source === 'ikkyu'
          ? t(
              '{{provider_name}}で登録されているアカウント情報と同じ店舗ID・オペレーターID・パスワードを入力してください。\n違うアカウント情報を入力した場合は連携エラーとなります。',
              {
                provider_name: GOURMET_SITE_PROVIDER_SOURCE[source].label,
              }
            )
          : t(
              '{{provider_name}}の店舗管理画面で登録されているアカウント情報と同じログインID・パスワードを入力してください。\n違うアカウント情報を入力した場合は連携エラーとなります。',
              {
                provider_name: GOURMET_SITE_PROVIDER_SOURCE[source].label,
              }
            )}
      </Text>

      <View style={{ marginVertical: 88, rowGap: 24, marginHorizontal: 80 }}>
        {source === 'ikkyu' && (
          <FormGroup
            formLabel={
              <FormLabelWithHelper
                FormLabelProps={{
                  value: t('店舗ID'),
                }}
                helperText={t(
                  '{{provider}}の管理画面にログインする際の設定中の店舗IDです',
                  { provider: provider.label }
                )}
              />
            }
          >
            <TextInput
              value={siteRestaurantId}
              onChangeText={setSiteRestaurantId}
              placeholder="1234567890"
            />
          </FormGroup>
        )}
        <FormGroup
          formLabel={
            <FormLabelWithHelper
              FormLabelProps={{
                value:
                  source === 'ikkyu' ? t('オペレーターID') : t('ログインID'),
              }}
              helperText={t(
                '{{provider}}の管理画面にログインする際の設定中のIDです',
                { provider: provider.label }
              )}
            />
          }
        >
          <TextInput
            value={loginId}
            onChangeText={setLoginId}
            placeholder="1234567890"
          />
        </FormGroup>
        <FormGroup formLabel={<FormLabel value={t('パスワード')} />}>
          <TextInput
            value={password}
            onChangeText={setPassword}
            placeholder="※※※※※※※"
            secureTextEntry
          />
        </FormGroup>
      </View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-around',
          columnGap: 16,
          marginHorizontal: 80,
        }}
      >
        <Button
          style={{ flex: 1, height: 48 }}
          mode="outline"
          onPress={onPressCancel}
        >
          {t('キャンセル')}
        </Button>
        <Button style={{ flex: 1, height: 48 }} onPress={onPressSubmit}>
          {t('情報を保存する')}
        </Button>
      </View>
    </ScrollView>
  )
}
