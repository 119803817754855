import React, { useMemo, useState } from 'react'
import { ContentScrollView } from '@hello-ai/for_r_app/src/components/Restaurant/Reservation/ListView/Content'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { useRestaurantReservations } from 'models/RestaurantReservation'
import { useRestaurantId } from 'modules/useRestaurantId'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Pagination } from '@hello-ai/ar_shared/src/components/Pagination'
import {
  SelectInput,
  SelectItem,
} from '@hello-ai/ar_shared/src/components/SelectInput'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { generatePath, useParams } from 'react-router'
import { View } from 'react-native'
import { faPrint } from '@fortawesome/pro-solid-svg-icons/faPrint'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { useRestaurant } from 'models/Restaurant'

export const ReservedContentKeys = [
  'all',
  'reservation',
  'future_only',
  'walkin',
] as const
export type ContentKey = (typeof ReservedContentKeys)[number]

export const RESERVED_TYPES: SelectItem<ContentKey>[] = [
  {
    label: t('全て'),
    value: 'all',
  },
  {
    label: t('予約'),
    value: 'reservation',
  },
  {
    label: t('来店前の予約'),
    value: 'future_only',
  },
  {
    label: t('ウォークイン'),
    value: 'walkin',
  },
]

export default function ReservedContent({ date }: { date: dayjs.Dayjs }) {
  const params = useParams()
  const restaurantId = useRestaurantId()
  const [currentPage, setCurrentPage] = useState(1)
  const [reservedType, setReservedType] = useState<ContentKey>('all')
  const { restaurant } = useRestaurant(restaurantId)

  const { restaurantReservations, headerData, mutate } =
    useRestaurantReservations(restaurantId, {
      date: date.format('YYYY-MM-DD'),
      future_only: reservedType === 'future_only',
      page: currentPage,
      per_page: 20,
    })

  const restaurantReservationItems = useMemo(
    () =>
      reservedType === 'walkin'
        ? restaurantReservations?.filter((i) => i.kind === 'walkin')
        : restaurantReservations,
    [restaurantReservations, reservedType]
  )

  const onPress = () => {
    const url = `${generatePath(
      `/restaurants/:id/reservations/${reservedType}/print`,
      {
        id: params.id!,
      }
    )}?date=${encodeURIComponent(date.format('YYYY-MM-DD'))}`

    window.open(url, '_blank', 'noopener')
  }

  if (restaurant == null) return null

  return (
    <ContentScrollView
      restaurant={restaurant}
      headerContent={
        <>
          <SelectInput
            selectedValue={reservedType}
            setValue={setReservedType}
            items={RESERVED_TYPES}
            labelStyle={{
              fontSize: 16,
              fontWeight: '300',
            }}
            style={{ backgroundColor: 'white' }}
            indicatorIcon={
              <FontAwesomeIcon
                icon={faChevronDown}
                size={10}
                color={Colors.black}
              />
            }
          />
          <Button
            mode="contained"
            variant="secondary"
            style={{
              marginLeft: 24,
              borderRadius: 24,
              height: 44,
            }}
            onPress={onPress}
          >
            <View
              style={{
                flexDirection: 'row',
              }}
            >
              <FontAwesomeIcon
                icon={faPrint}
                size={20}
                color={Colors.primary}
              />
              <Text
                style={{
                  marginLeft: 8,
                  fontWeight: '600',
                  color: Colors.primary,
                }}
              >
                {t('印刷する')}
              </Text>
            </View>
          </Button>
        </>
      }
      restaurantReservations={restaurantReservationItems}
      onIssueSmartPaymentReceipt={() => mutate()}
      pagination={
        <Pagination
          currentPage={currentPage}
          totalPage={headerData?.totalPages ?? 0}
          setPage={(page) => setCurrentPage(page)}
        />
      }
    />
  )
}
