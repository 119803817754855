import React, { useMemo, useState } from 'react'
import { View, Text, Linking, Platform } from 'react-native'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FormGroup, FormLabel } from '@hello-ai/ar_shared/src/components/Form'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'

import {
  updateCustomerRestaurantCustomer,
  updateCustomerRestaurantCustomerNote,
  updateCustomerRestaurantCustomerProfile,
  useCustomerProfile,
} from '../../models/Customer'
import { UpdateRestaurantCustomerParams } from '@hello-ai/ar_shared/src/types/ForR/RestaurantCustomer'
import {
  getParsedPhoneNumber,
  formatPhoneNumberByCountryCode,
  getDefaultDialCode,
} from '../../modules/phoneNumber'
import { PhoneNumberInput } from '../Shared/PhoneNumberInput'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { selectImageFromMediaLibrary } from '@hello-ai/ar_shared/src/components/SelectImage'
import {
  createRestaurantCustomerImage,
  destroyRestaurantCustomerImage,
  useRestaurantCustomerImages,
} from '../../models/RestaurantCustomerImage'
import { displayToastSuccess } from '../Shared/Toast'
import { ScrollView, TouchableOpacity } from 'react-native-gesture-handler'
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus'
import { SelectInput } from '@hello-ai/ar_shared/src/components/SelectInput'
import { Spinner } from '@hello-ai/ar_shared/src/components/Spinner'
import { Image } from '@hello-ai/ar_shared/src/components/Image'
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons/faCircleXmark'
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons/faTrashAlt'
import DatePicker from 'react-native-date-picker'
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown'
import { faCaretUp } from '@fortawesome/pro-solid-svg-icons/faCaretUp'
import { getLocale } from '@hello-ai/ar_shared/src/modules/locale'
import { useRestaurantAttributes } from '../../models/CustomerAttributes'
import {
  Checkbox,
  CheckboxGroup,
  CheckboxLabel,
} from '@hello-ai/ar_shared/src/components/Checkbox'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight'
import { faExternalLinkAlt } from '@fortawesome/pro-solid-svg-icons/faExternalLinkAlt'
import {
  useRestaurant,
  useRestaurantCustomerNoteTemplate,
} from '@hello-ai/for_r_app/src/models/Restaurant'
import { Customer } from '@hello-ai/ar_shared/src/types/ForR/Customer'
import { useNavigation } from '../../modules/navigation/useNavigation'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import SelectDateTimeInput from '../Shared/SelectDateTimeInput'
import { useNavigate } from '../../modules/navigation/useNavigate'

type DateStateType = number | string

function AnniversaryForm({
  item,
  handleAnniversaryChange,
  onRemoveAnniversary,
  onPressDatePicker,
  /** forWeb only */
  onChangeDate,
  /** forWeb only */
  date,
}: {
  item: {
    name: string
    date: {
      year: DateStateType
      month: DateStateType
      day: DateStateType
    }
  }
  handleAnniversaryChange: (
    name: string,
    date: { year: DateStateType; month: DateStateType; day: DateStateType }
  ) => void
  onRemoveAnniversary: () => void
  onPressDatePicker: () => void
  /** forWeb only */
  onChangeDate: (date: dayjs.Dayjs) => void
  /** forWeb only */
  date: dayjs.Dayjs | null
}) {
  const { width, sm } = useResponsive()
  return (
    <View
      style={{
        marginBottom: 16,
      }}
    >
      <TextInput
        style={[width < sm && { height: 48 }, { marginBottom: 16 }]}
        placeholder={t('記念日名')}
        value={item.name}
        onChangeText={(text) => handleAnniversaryChange(text, item.date)}
      />
      {Platform.OS !== 'web' ? (
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            gap: 8,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              width: width > sm ? 160 : 'auto',
              flex: width < sm ? 1 : undefined,
            }}
          >
            <View style={{ flex: 1 }}>
              <TouchableOpacity
                style={{
                  flex: 1,
                  backgroundColor: Colors.field,
                  borderRadius: 8,
                  height: 48,
                  paddingHorizontal: 12,
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderWidth: 2,
                  borderColor: 'transparent',
                }}
                onPress={onPressDatePicker}
              >
                <Text>{`${item.date.year}`}</Text>
                <View>
                  <FontAwesomeIcon
                    icon={faCaretUp}
                    size={10}
                    color={Colors.black}
                  />
                  <FontAwesomeIcon
                    icon={faCaretDown}
                    size={10}
                    color={Colors.black}
                  />
                </View>
              </TouchableOpacity>
            </View>
            <Text
              style={{
                marginLeft: 8,
              }}
            >
              {t('年')}
            </Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              width: width > sm ? 160 : 'auto',
              flex: width < sm ? 1 : undefined,
            }}
          >
            <View style={{ flex: 1 }}>
              <TouchableOpacity
                style={{
                  flex: 1,
                  backgroundColor: Colors.field,
                  borderRadius: 8,
                  height: 48,
                  paddingHorizontal: 12,
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderWidth: 2,
                  borderColor: 'transparent',
                }}
                onPress={onPressDatePicker}
              >
                <Text>{`${item.date.month}`}</Text>
                <View>
                  <FontAwesomeIcon
                    icon={faCaretUp}
                    size={10}
                    color={Colors.black}
                  />
                  <FontAwesomeIcon
                    icon={faCaretDown}
                    size={10}
                    color={Colors.black}
                  />
                </View>
              </TouchableOpacity>
            </View>
            <Text
              style={{
                marginLeft: 8,
              }}
            >
              {t('月')}
            </Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              width: width > sm ? 160 : 'auto',
              flex: width < sm ? 1 : undefined,
            }}
          >
            <View style={{ flex: 1 }}>
              <TouchableOpacity
                style={{
                  flex: 1,
                  backgroundColor: Colors.field,
                  borderRadius: 8,
                  height: 48,
                  paddingHorizontal: 12,
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderWidth: 2,
                  borderColor: 'transparent',
                }}
                onPress={onPressDatePicker}
              >
                <Text>{`${item.date.day}`}</Text>
                <View>
                  <FontAwesomeIcon
                    icon={faCaretUp}
                    size={10}
                    color={Colors.black}
                  />
                  <FontAwesomeIcon
                    icon={faCaretDown}
                    size={10}
                    color={Colors.black}
                  />
                </View>
              </TouchableOpacity>
            </View>
            <Text
              style={{
                marginLeft: 8,
              }}
            >
              {t('日')}
            </Text>
          </View>
        </View>
      ) : (
        <SelectDateTimeInput
          mode="date"
          // eslint-disable-next-line ar-i18n/require-translation-ja
          customFormatLabel={(_, date) => date.format('YYYY年MM月DD日')}
          maxDate={dayjs()}
          minDate={dayjs('1900-01-01')}
          defaultCalendarMonth={dayjs('1970-01-01')}
          dateTime={date}
          onChangeDateTime={(date) => {
            onChangeDate(date)
          }}
        />
      )}
      <TouchableOpacity
        style={{ marginTop: 16, flexDirection: 'row', gap: 4 }}
        onPress={onRemoveAnniversary}
      >
        <FontAwesomeIcon icon={faTrashAlt} color={Colors.caution} size={16} />
        <Text style={{ color: Colors.caution }}>{t('この記念日を削除')}</Text>
      </TouchableOpacity>
    </View>
  )
}

export function EditCustomer({
  restaurantId,
  customer,
  onCustomerUpdated,
  onPressCustomerAttributes,
  onPressNoteTemplateEdit,
}: {
  restaurantId: number
  customer: Customer
  onCustomerUpdated?: () => void
  onPressNoteTemplateEdit?: () => void
  onPressCustomerAttributes?: () => void
}) {
  const navigation = useNavigation()
  const navigate = useNavigate()
  const hasUser = customer.user != null
  const token = useToken()
  const { data: restaurant } = useRestaurant(restaurantId)
  const isFreePlan =
    restaurant == null ||
    restaurant.reservation_book_plan_type === 'free' ||
    restaurant.reservation_book_plan_type === 'entry'

  const { profile } = useCustomerProfile(restaurantId, customer.id)
  const { data, mutate: mutateAttributes } = useRestaurantAttributes(
    isFreePlan || !restaurant.reservation_book_available
      ? undefined
      : restaurantId
  )

  const attributes = data?.filter((i) => i.display_in_list)

  const { restaurantCustomerImages, mutate } = useRestaurantCustomerImages(
    restaurantId,
    customer.id
  )

  const { data: customerNoteTemplateData } =
    useRestaurantCustomerNoteTemplate(restaurantId)
  const isExistCustomerNoteTemplate =
    (customerNoteTemplateData?.content ?? '') !== ''

  const customerId = customer.id
  const [reservationLastName, setReservationLastName] = useFormState<string>(
    customer.reservation_last_name ?? ''
  )
  const [reservationFirstName, setReservationFirstName] = useFormState<string>(
    customer.reservation_first_name ?? ''
  )
  const [lastName, setLastName] = useFormState<string>(customer.last_name ?? '')
  const [firstName, setFirstName] = useFormState<string>(
    customer.first_name ?? ''
  )
  const [allergy, setAllergy] = useFormState<string>(customer.allergy ?? '')
  const [email, setEmail] = useFormState<string>(customer.email ?? '')
  const [selectedAttributes, setSelectedAttributes] = useFormState<string[]>(
    profile?.custom_attributes?.split(',') ?? []
  )
  const [gender, setGender] = useFormState<
    NonNullable<typeof profile>['gender']
  >(profile?.gender)
  const [birthYear, setBirthYear] = useFormState<DateStateType>(
    profile?.birthday ? dayjs(profile.birthday).year() : ''
  )
  const [birthMonth, setBirthMonth] = useFormState<DateStateType>(
    profile?.birthday ? dayjs(profile.birthday).month() + 1 : ''
  )
  const [birthDay, setBirthDay] = useFormState<DateStateType>(
    profile?.birthday ? dayjs(profile.birthday).date() : ''
  )
  const birth = useMemo(() => {
    if (birthYear === '' || birthMonth === '' || birthDay === '') return null
    return dayjs(`${birthYear}-${birthMonth}-${birthDay}`)
  }, [birthYear, birthMonth, birthDay])
  const [weddingYear, setWeddingYear] = useFormState<DateStateType>(
    profile?.wedding_anniversary
      ? dayjs(profile.wedding_anniversary).year()
      : ''
  )
  const [weddingMonth, setWeddingMonth] = useFormState<DateStateType>(
    profile?.wedding_anniversary
      ? dayjs(profile.wedding_anniversary).month() + 1
      : ''
  )
  const [weddingDay, setWeddingDay] = useFormState<DateStateType>(
    profile?.wedding_anniversary
      ? dayjs(profile.wedding_anniversary).date()
      : ''
  )
  const wedding = useMemo(() => {
    if (weddingYear === '' || weddingMonth === '' || weddingDay === '')
      return null
    return dayjs(`${weddingYear}-${weddingMonth}-${weddingDay}`)
  }, [weddingYear, weddingMonth, weddingDay])

  const [memo, setMemo] = useFormState<string>(
    customer.restaurant_customer_note?.content ?? ''
  )
  const [anniversary, setAnniversary] = useFormState<
    {
      name: string
      date: {
        year: DateStateType
        month: DateStateType
        day: DateStateType
      }
    }[]
  >(
    profile?.anniversaries[0]
      ? profile?.anniversaries.map((i) => {
          return {
            name: i.name,
            date: {
              year: i.date != null ? dayjs(i.date).year() : '',
              month: i.date != null ? dayjs(i.date).month() + 1 : '',
              day: i.date != null ? dayjs(i.date).date() : '',
            },
          }
        })
      : []
  )
  const [feature, setFeature] = useFormState<string>(profile?.feature ?? '')
  const [likes, setLikes] = useFormState<string>(profile?.likes ?? '')
  const [dislikes, setDislikes] = useFormState<string>(profile?.dislikes ?? '')
  const [smoking, setSmoking] = useFormState<number | undefined>(
    profile?.smoking === 'cigarette'
      ? 1
      : profile?.smoking === 'dont_smoke'
        ? 0
        : undefined
  )
  const [receipt_title, setReceiptTitle] = useFormState<string>(
    profile?.receipt_title ?? ''
  )
  const [company, setCompany] = useFormState<string>(profile?.company ?? '')
  const [section, setSection] = useFormState<string>(profile?.section ?? '')
  const [post, setPost] = useFormState<string>(profile?.post ?? '')
  const [company_postal_code, setCompanyPostalCode] = useFormState<string>(
    profile?.company_postal_code ?? ''
  )
  const [company_address, setCompanyAddress] = useFormState<string>(
    profile?.company_address ?? ''
  )
  const [home_postal_code, setHomePostalCode] = useFormState<string>(
    profile?.home_postal_code ?? ''
  )
  const [home_address, setHomeAddress] = useFormState<string>(
    profile?.home_address ?? ''
  )
  const [x_url, setXUrl] = useFormState<string>(profile?.x_url ?? '')
  const [facebook_url, setFacebookUrl] = useFormState<string>(
    profile?.facebook_url ?? ''
  )
  const [opt_in_dm, setOptInDM] = useFormState<boolean | null>(
    profile?.opt_in_dm ?? false
  )
  const [updater, setUpdater] = useFormState<string>(profile?.updater ?? '')

  const [datePickerState, setDatePickerState] = useState<{
    type: 'birthday' | 'wedding' | 'anniversaries'
    index: number
  }>()

  const { width, sm } = useResponsive()

  const persedNumber = getParsedPhoneNumber(
    // NOTE: phone_numberがnullの場合もあるので、stringで絞り込む
    typeof customer.phone_number === 'string' ? customer.phone_number : ''
  )

  const [dialCode, setDialCode] = useFormState<string>(
    persedNumber.countryCallingCode
      ? `+${persedNumber.countryCallingCode}`
      : getDefaultDialCode()
  )
  const [phoneNumber, setPhoneNumber] = useFormState<string>(
    persedNumber.phone?.toString() ?? ''
  )

  const formGroupMargin = width >= sm ? 40 : 24
  const [isAddingImage, setIsAddingImage] = useState(false)

  const createCustomerImage = async (image: string) => {
    const params = {
      image,
    }

    setIsAddingImage(true)

    try {
      const { error } = await createRestaurantCustomerImage(
        token,
        restaurantId,
        customer.id,
        params
      )

      if (error != null) return
      // WORKAROUND:
      // createRestaurantCustomerImageでmutateしているが、awaitはしていないので
      // 画像追加直後のチラつきを防ぐためここでawaitする
      await mutate()
      displayToastSuccess(t('画像を追加しました'))
    } finally {
      setIsAddingImage(false)
    }
  }

  const handleDeleteImage = async (id: string) => {
    const { error } = await destroyRestaurantCustomerImage(token, id)

    if (error != null) return
    await mutate()
  }

  const onPress = async () => {
    let updateRestaurantCustomerError
    if (!hasUser) {
      const params: UpdateRestaurantCustomerParams = {}
      params.reservation_last_name = reservationLastName
      params.reservation_first_name = reservationFirstName
      params.last_name = lastName
      params.first_name = firstName
      params.email = email !== '' ? email : null
      params.phone_number = formatPhoneNumberByCountryCode(
        phoneNumber,
        dialCode
      )
      params.allergy = allergy
      const { error } = await updateCustomerRestaurantCustomer(
        token,
        customerId,
        params
      )
      updateRestaurantCustomerError = error
    }

    const memoChanged = memo !== customer.restaurant_customer_note?.content
    let updateCustomerRestaurantCustomerNoteError
    if (memoChanged) {
      const { error } = await updateCustomerRestaurantCustomerNote(
        token,
        restaurantId,
        customerId,
        {
          content: memo,
        }
      )
      updateCustomerRestaurantCustomerNoteError = error
    }

    const parseBirthday = (
      year: DateStateType,
      month: DateStateType,
      day: DateStateType
    ) => {
      if (year === '' || month === '' || day === '') return null
      return new Date(Date.UTC(Number(year), Number(month) - 1, Number(day)))
    }

    const parseAnniversaries = (
      anniversaries: {
        name: string
        date: { year: DateStateType; month: DateStateType; day: DateStateType }
      }[]
    ) => {
      const arr = anniversaries.map((anniversary) => {
        return {
          name: anniversary.name,
          date: parseBirthday(
            anniversary.date.year,
            anniversary.date.month,
            anniversary.date.day
          ),
        }
      })
      return arr
    }

    const customerProfileParams = {
      anniversaries_attributes: parseAnniversaries(anniversary),
      birthday: parseBirthday(birthYear, birthMonth, birthDay) ?? undefined,
      company,
      company_address,
      company_postal_code,
      dislikes,
      facebook_url,
      feature,
      gender,
      home_address,
      home_postal_code,
      likes,
      opt_in_dm: opt_in_dm ?? undefined,
      post,
      receipt_title,
      section,
      smoking,
      updater,
      wedding_anniversary:
        parseBirthday(weddingYear, weddingMonth, weddingDay) ?? undefined,
      x_url,
      custom_attributes:
        selectedAttributes.length > 0 ? selectedAttributes.join(',') : null,
    }

    const { error: customerProfileError } =
      await updateCustomerRestaurantCustomerProfile(
        token,
        restaurantId,
        customerId,
        customerProfileParams
      )

    if (
      updateRestaurantCustomerError != null ||
      updateCustomerRestaurantCustomerNoteError != null ||
      customerProfileError != null
    )
      return

    onCustomerUpdated?.()
    if (Platform.OS === 'web') {
      navigate(-1)
    } else {
      navigation.goBack()
    }
  }

  const addAnniversary = () => {
    setAnniversary([
      ...anniversary,
      { name: '', date: { year: '', month: '', day: '' } },
    ])
  }

  const onPressCopyFromNoteTemplate = () => {
    setMemo(customerNoteTemplateData?.content ?? '')
  }

  const handleAnniversaryChange = (
    index: number,
    name: string,
    date: { year: DateStateType; month: DateStateType; day: DateStateType }
  ) => {
    const newAnniversary = [...anniversary]
    newAnniversary[index] = { name, date }
    setAnniversary(newAnniversary)
  }

  return (
    <>
      <KeyboardAwareScrollView
        style={{ flex: 1, backgroundColor: Colors.white }}
        contentContainerStyle={[
          { padding: 40 },
          width < sm && { paddingTop: 26 },
        ]}
      >
        {hasUser && (
          <View style={{ marginBottom: 24 }}>
            <Text style={{ fontSize: 12, color: Colors.black60 }}>
              {t(
                'AutoReserveから登録された名前、電話番号、メールアドレス、アレルギー情報は更新できません'
              )}
            </Text>
          </View>
        )}
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('名前')}
              required
            />
          }
          containerStyle={width < sm && { marginTop: 8 }}
        >
          <View style={{ flexDirection: 'row' }}>
            <TextInput
              placeholder={t('せい')}
              style={[{ flex: 1 }, width < sm && { height: 48 }]}
              value={reservationLastName}
              disabled={hasUser}
              onChangeText={(text) => setReservationLastName(text)}
            />
            <TextInput
              placeholder={t('めい')}
              style={[
                { flex: 1, marginLeft: width >= sm ? 24 : 8 },
                width < sm && { height: 48 },
              ]}
              value={reservationFirstName}
              disabled={hasUser}
              onChangeText={(text) => setReservationFirstName(text)}
            />
          </View>
          <View
            style={{
              marginTop: width >= sm ? 24 : 8,
              flexDirection: 'row',
            }}
          >
            <TextInput
              placeholder={t('姓')}
              style={[{ flex: 1 }, width < sm && { height: 48 }]}
              value={lastName}
              disabled={hasUser}
              onChangeText={(text) => setLastName(text)}
            />
            <TextInput
              placeholder={t('名')}
              style={[
                { flex: 1, marginLeft: width >= sm ? 24 : 8 },
                width < sm && { height: 48 },
              ]}
              value={firstName}
              disabled={hasUser}
              onChangeText={(text) => setFirstName(text)}
            />
          </View>
        </FormGroup>
        <FormGroup
          style={{ marginTop: formGroupMargin }}
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('電話番号')}
            />
          }
          containerStyle={width < sm && { marginTop: 8 }}
        >
          <PhoneNumberInput dialCode={dialCode} onChangeDialCode={setDialCode}>
            <TextInput
              style={[{ flex: 1 }, width < sm && { height: 48 }]}
              placeholder={t('ハイフンなしで入力')}
              value={phoneNumber}
              disabled={hasUser}
              onChangeText={(text) => setPhoneNumber(text)}
              keyboardType="phone-pad"
              textContentType="telephoneNumber"
              clearButtonMode="always"
            />
          </PhoneNumberInput>
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('メールアドレス')}
            />
          }
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          <TextInput
            style={width < sm && { height: 48 }}
            placeholder={t('info@hello.ai')}
            value={email}
            disabled={hasUser}
            onChangeText={(text) => setEmail(text)}
          />
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('アレルギー')}
            />
          }
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          <TextInput
            style={width < sm && { height: 48 }}
            placeholder={t('アレルギー')}
            value={allergy}
            disabled={hasUser}
            onChangeText={(text) => setAllergy(text)}
          />
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('メモ')}
            />
          }
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          <TextInput
            placeholder={t('メモ')}
            multiline
            value={memo}
            onChangeText={(text) => setMemo(text)}
          />
          {width > sm ? (
            <View
              style={{
                flexDirection: width > sm ? 'row' : 'column',
                alignItems: width > sm ? 'center' : 'flex-end',
                justifyContent: 'flex-end',
                marginTop: width > sm ? 16 : 8,
                gap: 16,
              }}
            >
              <Button
                textStyle={{ color: Colors.secondaryBlack }}
                mode="text"
                variant="secondary"
                onPress={() => {
                  if (Platform.OS === 'web') {
                    // web ではモーダル
                    // onPressNoteTemplateEdit でモーダル表示
                  } else {
                    navigation.navigate('EditCustomerNoteTemplateModal')
                  }
                  onPressNoteTemplateEdit?.()
                }}
              >
                {isExistCustomerNoteTemplate
                  ? t('メモテンプレートを編集')
                  : t('メモテンプレートを作成')}
              </Button>
              {isExistCustomerNoteTemplate && (
                <Button
                  mode="outline"
                  onPress={onPressCopyFromNoteTemplate}
                  style={width < sm && { marginBottom: 8 }}
                >
                  {t('メモテンプレートから記入')}
                </Button>
              )}
            </View>
          ) : (
            <View
              style={{
                flexDirection: 'column',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
                marginTop: 8,
                gap: 16,
              }}
            >
              <Button
                style={{
                  backgroundColor: 'none',
                  borderColor: Colors.primary,
                  borderWidth: 1,
                  borderRadius: 4,
                }}
                textStyle={{
                  color: Colors.primary,
                  fontSize: 14,
                  lineHeight: 21,
                }}
                height={32}
              >
                {t('テンプレートから記入')}
              </Button>
              <Text
                style={{
                  color: Colors.secondaryBlack,
                }}
              >
                {t('テンプレートを編集')}
              </Text>
            </View>
          )}
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('写真')}
            />
          }
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          {((restaurantCustomerImages?.length ?? 0) > 0 || isAddingImage) && (
            <ScrollView
              horizontal
              showsHorizontalScrollIndicator={false}
              style={{ flex: 1, paddingTop: 16 }}
            >
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                {isAddingImage && (
                  <View
                    style={{
                      width: 168,
                      height: 112,
                      borderRadius: 8,
                      marginRight: 24,
                      borderColor: Colors.border,
                      borderWidth: 0.5,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Spinner size={24} color={Colors.primary} />
                  </View>
                )}
                {restaurantCustomerImages?.map((customerImage) => (
                  <View key={customerImage.id}>
                    <View
                      style={{
                        position: 'absolute',
                        top: -16,
                        right: 12,
                        zIndex: 10,
                        backgroundColor: Colors.white,
                        borderRadius: 9999,
                      }}
                    >
                      <TouchableOpacity
                        style={{
                          zIndex: 10,
                        }}
                        onPress={() => handleDeleteImage(customerImage.id)}
                      >
                        <FontAwesomeIcon
                          icon={faCircleXmark}
                          size={32}
                          color={Colors.black80}
                        />
                      </TouchableOpacity>
                    </View>
                    <TouchableOpacity>
                      <Image
                        source={{ uri: customerImage.image.sm }}
                        style={{
                          width: 168,
                          height: 112,
                          borderRadius: 8,
                          marginRight: 24,
                          zIndex: 1,
                        }}
                      />
                    </TouchableOpacity>
                    <Text
                      style={{
                        marginTop: 8,
                        fontSize: 14,
                      }}
                    >
                      {/* eslint-disable-next-line ar-i18n/require-translation-ja */}
                      {dayjs(customerImage.created_at).format(
                        t('YYYY年MM月DD日')
                      )}
                    </Text>
                  </View>
                ))}
              </View>
            </ScrollView>
          )}
          <TouchableOpacity
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 16,
            }}
            onPress={async () => {
              const image = await selectImageFromMediaLibrary()

              if (image == null) return
              await createCustomerImage(image)
            }}
          >
            <FontAwesomeIcon
              icon={faPlus}
              size={16}
              color={Colors.primary}
              style={{ marginRight: 8 }}
            />

            <Text
              style={{
                color: Colors.primary,
              }}
            >
              {t('写真を追加する')}
            </Text>
          </TouchableOpacity>
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('お客様属性')}
            />
          }
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          {isFreePlan || !restaurant.reservation_book_available ? (
            <View
              style={{
                flexDirection: 'row',
                padding: 16,
                backgroundColor: Colors.white,
                borderRadius: 8,
                borderColor: Colors.primary,
                borderWidth: 1,
                gap: 12,
                alignItems: 'center',
              }}
            >
              <Image
                style={{ height: 16, width: 16, marginRight: 4 }}
                source={require('../../assets/images/plan-icon.png')}
              />
              <Text style={{ flex: 1, color: Colors.black }}>
                {restaurant?.reservation_book_plan_type === 'entry'
                  ? t(
                      'リクエスト予約機能のみをご利用中の場合、当機能のご利用はできません。ご利用希望の方はお問い合わせください'
                    )
                  : t(
                      'この機能は予約台帳のスタンダードプラン以上でご利用いただけます。ご利用希望の方はお問い合わせください'
                    )}
              </Text>
              <TouchableOpacity
                style={{ flexDirection: 'row', gap: 4, alignItems: 'center' }}
                onPress={() =>
                  Linking.openURL(
                    'https://autoreserve.com/for_restaurants/contact'
                  )
                }
              >
                <Text style={{ color: Colors.primary }}>
                  {t('お問い合わせへ')}
                </Text>
                <FontAwesomeIcon icon={faChevronRight} color={Colors.primary} />
              </TouchableOpacity>
            </View>
          ) : (
            <>
              <CheckboxGroup
                value={selectedAttributes}
                onChange={setSelectedAttributes}
              >
                {attributes?.map((attribute) => {
                  return (
                    <Checkbox
                      key={attribute.id}
                      value={attribute.id}
                      checkboxLabel={<CheckboxLabel value={attribute.name} />}
                    />
                  )
                })}
              </CheckboxGroup>
              <TouchableOpacity
                style={[
                  { flexDirection: 'row', gap: 4, alignItems: 'center' },
                  { marginTop: 16 },
                ]}
                onPress={() => {
                  if (Platform.OS === 'web') {
                    // web ではモーダル
                    // onPressCustomerAttributes でモーダル表示
                  } else {
                    navigation.navigate('CustomerAttribute', {
                      onClose: () => {
                        mutateAttributes()
                        navigation.goBack()
                      },
                    })
                  }
                  onPressCustomerAttributes?.()
                }}
              >
                <FontAwesomeIcon
                  icon={faExternalLinkAlt}
                  color={Colors.primary}
                  size={16}
                />
                <Text style={{ fontSize: 14, color: Colors.primary }}>
                  {t('お客様属性を追加する')}
                </Text>
              </TouchableOpacity>
            </>
          )}
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('性別')}
            />
          }
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          <SelectInput
            selectedValue={gender}
            setValue={(value: 'male' | 'female' | 'other' | null) =>
              setGender(value ?? undefined)
            }
            items={[
              {
                label: t('未登録'),
                value: null,
              },
              {
                label: t('男性'),
                value: 'male',
              },
              {
                label: t('女性'),
                value: 'female',
              },
              {
                label: t('その他'),
                value: 'other',
              },
            ]}
            disabled={false}
            style={{ width: 140 }}
          />
        </FormGroup>

        <FormGroup
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('誕生日')}
            />
          }
          style={{
            marginTop: formGroupMargin,
            flexDirection: width > sm ? 'row' : 'column',
            gap: 4,
          }}
          containerStyle={
            width < sm && {
              marginTop: 8,
              flexDirection: 'row',
            }
          }
        >
          {Platform.OS !== 'web' ? (
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                gap: 8,
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: width > sm ? 160 : 'auto',
                  flex: width < sm ? 1 : undefined,
                }}
              >
                <View style={{ flex: 1 }}>
                  <TouchableOpacity
                    style={{
                      flex: 1,
                      backgroundColor: Colors.field,
                      borderRadius: 8,
                      height: 48,
                      paddingHorizontal: 12,
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      borderWidth: 2,
                      borderColor: 'transparent',
                    }}
                    onPress={() =>
                      setDatePickerState({ type: 'birthday', index: 0 })
                    }
                  >
                    <Text>{`${birthYear}`}</Text>
                    <View>
                      <FontAwesomeIcon
                        icon={faCaretUp}
                        size={10}
                        color={Colors.black}
                      />
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        size={10}
                        color={Colors.black}
                      />
                    </View>
                  </TouchableOpacity>
                </View>
                <Text
                  style={{
                    marginLeft: 8,
                  }}
                >
                  {t('年')}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: width > sm ? 160 : 'auto',
                  flex: width < sm ? 1 : undefined,
                }}
              >
                <View style={{ flex: 1 }}>
                  <TouchableOpacity
                    style={{
                      flex: 1,
                      backgroundColor: Colors.field,
                      borderRadius: 8,
                      height: 48,
                      paddingHorizontal: 12,
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      borderWidth: 2,
                      borderColor: 'transparent',
                    }}
                    onPress={() =>
                      setDatePickerState({ type: 'birthday', index: 0 })
                    }
                  >
                    <Text>{`${birthMonth}`}</Text>
                    <View>
                      <FontAwesomeIcon
                        icon={faCaretUp}
                        size={10}
                        color={Colors.black}
                      />
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        size={10}
                        color={Colors.black}
                      />
                    </View>
                  </TouchableOpacity>
                </View>
                <Text
                  style={{
                    marginLeft: 8,
                  }}
                >
                  {t('月')}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: width > sm ? 160 : 'auto',
                  flex: width < sm ? 1 : undefined,
                }}
              >
                <View style={{ flex: 1 }}>
                  <TouchableOpacity
                    style={{
                      flex: 1,
                      backgroundColor: Colors.field,
                      borderRadius: 8,
                      height: 48,
                      paddingHorizontal: 12,
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      borderWidth: 2,
                      borderColor: 'transparent',
                    }}
                    onPress={() =>
                      setDatePickerState({ type: 'birthday', index: 0 })
                    }
                  >
                    <Text>{`${birthDay}`}</Text>
                    <View>
                      <FontAwesomeIcon
                        icon={faCaretUp}
                        size={10}
                        color={Colors.black}
                      />
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        size={10}
                        color={Colors.black}
                      />
                    </View>
                  </TouchableOpacity>
                </View>
                <Text
                  style={{
                    marginLeft: 8,
                  }}
                >
                  {t('日')}
                </Text>
              </View>
            </View>
          ) : (
            <SelectDateTimeInput
              mode="date"
              // eslint-disable-next-line ar-i18n/require-translation-ja
              customFormatLabel={(_, date) => date.format('YYYY年MM月DD日')}
              maxDate={dayjs()}
              minDate={dayjs('1900-01-01')}
              defaultCalendarMonth={dayjs('1970-01-01')}
              dateTime={birth !== null ? birth : null}
              onChangeDateTime={(date) => {
                setBirthYear(date.year())
                setBirthMonth(date.month() + 1)
                setBirthDay(date.date())
              }}
            />
          )}
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('結婚記念日')}
            />
          }
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          {Platform.OS !== 'web' ? (
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                gap: 8,
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: width > sm ? 160 : 'auto',
                  flex: width < sm ? 1 : undefined,
                }}
              >
                <View style={{ flex: 1 }}>
                  <TouchableOpacity
                    style={{
                      flex: 1,
                      backgroundColor: Colors.field,
                      borderRadius: 8,
                      height: 48,
                      paddingHorizontal: 12,
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      borderWidth: 2,
                      borderColor: 'transparent',
                    }}
                    onPress={() =>
                      setDatePickerState({ type: 'wedding', index: 0 })
                    }
                  >
                    <Text>{`${weddingYear}`}</Text>
                    <View>
                      <FontAwesomeIcon
                        icon={faCaretUp}
                        size={10}
                        color={Colors.black}
                      />
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        size={10}
                        color={Colors.black}
                      />
                    </View>
                  </TouchableOpacity>
                </View>
                <Text
                  style={{
                    marginLeft: 8,
                  }}
                >
                  {t('年')}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: width > sm ? 160 : 'auto',
                  flex: width < sm ? 1 : undefined,
                }}
              >
                <View style={{ flex: 1 }}>
                  <TouchableOpacity
                    style={{
                      flex: 1,
                      backgroundColor: Colors.field,
                      borderRadius: 8,
                      height: 48,
                      paddingHorizontal: 12,
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      borderWidth: 2,
                      borderColor: 'transparent',
                    }}
                    onPress={() =>
                      setDatePickerState({ type: 'wedding', index: 0 })
                    }
                  >
                    <Text>{`${weddingMonth}`}</Text>
                    <View>
                      <FontAwesomeIcon
                        icon={faCaretUp}
                        size={10}
                        color={Colors.black}
                      />
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        size={10}
                        color={Colors.black}
                      />
                    </View>
                  </TouchableOpacity>
                </View>
                <Text
                  style={{
                    marginLeft: 8,
                  }}
                >
                  {t('月')}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: width > sm ? 160 : 'auto',
                  flex: width < sm ? 1 : undefined,
                }}
              >
                <View style={{ flex: 1 }}>
                  <TouchableOpacity
                    style={{
                      flex: 1,
                      backgroundColor: Colors.field,
                      borderRadius: 8,
                      height: 48,
                      paddingHorizontal: 12,
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      borderWidth: 2,
                      borderColor: 'transparent',
                    }}
                    onPress={() =>
                      setDatePickerState({ type: 'wedding', index: 0 })
                    }
                  >
                    <Text>{`${weddingDay}`}</Text>
                    <View>
                      <FontAwesomeIcon
                        icon={faCaretUp}
                        size={10}
                        color={Colors.black}
                      />
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        size={10}
                        color={Colors.black}
                      />
                    </View>
                  </TouchableOpacity>
                </View>
                <Text
                  style={{
                    marginLeft: 8,
                  }}
                >
                  {t('日')}
                </Text>
              </View>
            </View>
          ) : (
            <SelectDateTimeInput
              mode="date"
              // eslint-disable-next-line ar-i18n/require-translation-ja
              customFormatLabel={(_, date) => date.format('YYYY年MM月DD日')}
              maxDate={dayjs()}
              minDate={dayjs('1900-01-01')}
              defaultCalendarMonth={dayjs('1970-01-01')}
              dateTime={wedding !== null ? wedding : null}
              onChangeDateTime={(date) => {
                setWeddingYear(date.year())
                setWeddingMonth(date.month() + 1)
                setWeddingDay(date.date())
              }}
            />
          )}
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('その他記念日')}
            />
          }
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          {anniversary.map((item, index) => (
            <AnniversaryForm
              key={index}
              item={item}
              handleAnniversaryChange={(name, date) =>
                handleAnniversaryChange(index, name, date)
              }
              onRemoveAnniversary={() => {
                const newAnniversary = [...anniversary]
                newAnniversary.splice(index, 1)
                setAnniversary(newAnniversary)
              }}
              onPressDatePicker={() =>
                setDatePickerState({ type: 'anniversaries', index })
              }
              onChangeDate={(date) => {
                const newAnniversaries = [...anniversary]
                newAnniversaries[index].date = {
                  year: date.year(),
                  month: date.month() + 1,
                  day: date.date(),
                }
                setAnniversary(newAnniversaries)
              }}
              date={(() => {
                if (
                  item.date.year === '' ||
                  item.date.month === '' ||
                  item.date.day === ''
                ) {
                  return null
                }
                return dayjs(
                  `${item.date.year}-${item.date.month}-${item.date.day}`
                )
              })()}
            />
          ))}
          <TouchableOpacity
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
            onPress={addAnniversary}
          >
            <FontAwesomeIcon
              icon={faPlus}
              size={16}
              color={Colors.primary}
              style={{ marginRight: 8 }}
            />

            <Text
              style={{
                color: Colors.primary,
              }}
            >
              {t('記念日を追加する')}
            </Text>
          </TouchableOpacity>
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('特徴')}
            />
          }
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          <TextInput
            style={width < sm && { height: 48 }}
            placeholder={t('特徴')}
            value={feature}
            onChangeText={(text) => setFeature(text)}
          />
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('好きなもの')}
            />
          }
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          <TextInput
            style={width < sm && { height: 48 }}
            placeholder={t('好きなもの')}
            value={likes}
            onChangeText={(text) => setLikes(text)}
          />
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('嫌いなもの')}
            />
          }
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          <TextInput
            style={width < sm && { height: 48 }}
            placeholder={t('嫌いなもの')}
            value={dislikes}
            onChangeText={(text) => setDislikes(text)}
          />
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('喫煙情報')}
            />
          }
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          <SelectInput
            selectedValue={smoking}
            setValue={(value) => setSmoking(value)}
            items={[
              {
                label: t('未登録'),
                value: undefined,
              },
              {
                label: t('有'),
                value: 1,
              },
              {
                label: t('無'),
                value: 0,
              },
            ]}
            disabled={false}
            style={{ width: 140 }}
          />
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('領収書名')}
            />
          }
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          <TextInput
            style={width < sm && { height: 48 }}
            placeholder={t('領収書名')}
            value={receipt_title}
            onChangeText={(text) => setReceiptTitle(text)}
          />
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('会社名')}
            />
          }
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          <TextInput
            style={width < sm && { height: 48 }}
            placeholder={t('会社名')}
            value={company}
            onChangeText={(text) => setCompany(text)}
          />
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('部署名')}
            />
          }
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          <TextInput
            style={width < sm && { height: 48 }}
            placeholder={t('部署名')}
            value={section}
            onChangeText={(text) => setSection(text)}
          />
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('役職名')}
            />
          }
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          <TextInput
            style={width < sm && { height: 48 }}
            placeholder={t('役職名')}
            value={post}
            onChangeText={(text) => setPost(text)}
          />
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('勤務先住所')}
            />
          }
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          <TextInput
            style={
              width < sm
                ? {
                    width: 140,
                    height: 48,
                    marginBottom: 16,
                  }
                : {
                    width: 140,
                    marginBottom: 16,
                  }
            }
            placeholder={t('郵便番号')}
            value={company_postal_code}
            onChangeText={(text) => setCompanyPostalCode(text)}
          />
          <TextInput
            style={width < sm && { height: 48 }}
            placeholder={t('住所')}
            value={company_address}
            onChangeText={(text) => setCompanyAddress(text)}
          />
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('自宅住所')}
            />
          }
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          <TextInput
            style={
              width < sm
                ? {
                    height: 48,
                    marginBottom: 16,
                    width: 140,
                  }
                : {
                    marginBottom: 16,
                    width: 140,
                  }
            }
            placeholder={t('郵便番号')}
            value={home_postal_code}
            onChangeText={(text) => setHomePostalCode(text)}
          />
          <TextInput
            style={width < sm && { height: 48 }}
            placeholder={t('住所')}
            value={home_address}
            onChangeText={(text) => setHomeAddress(text)}
          />
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('X(Twitter)')}
            />
          }
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          <TextInput
            style={width < sm && { height: 48 }}
            placeholder={t('X(Twitter)')}
            value={x_url}
            onChangeText={(text) => setXUrl(text)}
          />
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('Facebook')}
            />
          }
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          <TextInput
            style={width < sm && { height: 48 }}
            placeholder={t('Facebook')}
            value={facebook_url}
            onChangeText={(text) => setFacebookUrl(text)}
          />
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('DM可否')}
            />
          }
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          <SelectInput
            selectedValue={opt_in_dm}
            setValue={(value) => setOptInDM(value)}
            items={[
              {
                label: t('未登録'),
                value: null,
              },
              {
                label: t('可'),
                value: true,
              },
              {
                label: t('不可'),
                value: false,
              },
            ]}
            disabled={false}
            style={{ width: 140 }}
          />
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('更新者')}
            />
          }
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          <TextInput
            style={width < sm && { height: 48 }}
            placeholder={t('更新者')}
            value={updater}
            onChangeText={(text) => setUpdater(text)}
          />
        </FormGroup>
      </KeyboardAwareScrollView>
      <View
        style={{
          shadowColor: '#000',
          shadowOffset: {
            width: 0,
            height: -4,
          },
          shadowOpacity: 0.15,
          shadowRadius: 8,
          elevation: 2,
          paddingVertical: 24,
          paddingHorizontal: 16,
          backgroundColor: 'white',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button onPress={onPress} width={260} height={48}>
          {t('保存する')}
        </Button>
      </View>
      {Platform.OS !== 'web' && (
        <DatePicker
          locale={getLocale()}
          title={null}
          confirmText={t('決定')}
          cancelText={t('キャンセル')}
          modal
          mode="date"
          open={datePickerState !== undefined}
          date={(() => {
            switch (datePickerState?.type) {
              case 'birthday':
                if (birthYear === '' || birthMonth === '' || birthDay === '') {
                  return new Date(0)
                }
                return new Date(
                  Number(birthYear),
                  Number(birthMonth) - 1,
                  Number(birthDay)
                )
              case 'wedding':
                if (
                  weddingYear === '' ||
                  weddingMonth === '' ||
                  weddingDay === ''
                ) {
                  return new Date(0)
                }
                return new Date(
                  Number(weddingYear),
                  Number(weddingMonth) - 1,
                  Number(weddingDay)
                )
              case 'anniversaries': {
                const date = anniversary[datePickerState.index].date
                if (date.year === '' || date.month === '' || date.day === '') {
                  return new Date(0)
                }
                return new Date(
                  Number(anniversary[datePickerState.index].date.year),
                  Number(anniversary[datePickerState.index].date.month) - 1,
                  Number(anniversary[datePickerState.index].date.day)
                )
              }
            }
            return new Date(0)
          })()}
          minimumDate={new Date(1900, 1, 1)}
          maximumDate={new Date()}
          onCancel={() => setDatePickerState(undefined)}
          onConfirm={(date) => {
            switch (datePickerState?.type) {
              case 'birthday':
                setBirthYear(date.getFullYear().toString())
                setBirthMonth((date.getMonth() + 1).toString())
                setBirthDay(date.getDate().toString())
                break
              case 'wedding':
                setWeddingYear(date.getFullYear().toString())
                setWeddingMonth((date.getMonth() + 1).toString())
                setWeddingDay(date.getDate().toString())
                break
              case 'anniversaries': {
                const newAnniversaries = [...anniversary]
                newAnniversaries[datePickerState.index].date = {
                  year: date.getFullYear().toString(),
                  month: (date.getMonth() + 1).toString(),
                  day: date.getDate().toString(),
                }
                setAnniversary(newAnniversaries)
                break
              }
            }
            setDatePickerState(undefined)
          }}
        />
      )}
    </>
  )
}
