import { Middleware } from 'swr'
import { useSWRNativeRevalidate } from './useSWRNativeRevalidate'
import { useRefreshInterval } from './useRefreshInterval'

export const swrNativeMiddleware: Middleware = (useSWRNext) => {
  return (key, fetcher, config) => {
    const refreshInterval = useRefreshInterval({
      refreshInterval: config.refreshInterval,
      refreshWhenHidden: config.refreshWhenHidden,
    })

    const swr = useSWRNext(key, fetcher, {
      ...config,
      refreshInterval,
    })

    useSWRNativeRevalidate({
      // useSWRMutationを使うとmutateがundefinedで渡ってきてエラーになるため、空の関数を渡す
      mutate: swr.mutate ?? ((() => {}) as any),
      revalidateOnFocus: config.revalidateOnFocus,
      revalidateOnReconnect: config.revalidateOnReconnect,
      focusThrottleInterval: config.focusThrottleInterval,
    })

    return swr
  }
}
