import React, { useMemo, useState } from 'react'
import { Platform, View, Linking } from 'react-native'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { FreeDirectionalScrollView } from '../../FloorMap/Edit/FreeDirectionalScrollView'
import { SelectInput } from '@hello-ai/ar_shared/src/components/SelectInput'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import { useRestaurantFloor, useRestaurantFloors } from '../../../models/Floor'
import Loading from '../../Shared/Loading'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useNavigate } from '../../../modules/navigation/useNavigate'
import { useNavigation } from '../../../modules/navigation/useNavigation'
import { useComponentSize } from '../../../modules/useComponentSize'
import { AddReservationForm } from './AddReservationForm'
import {
  createRestaurantReservation,
  RestaurantReservationParams,
  useRestaurantReservationsWithFloorId,
} from '../../../models/RestaurantReservation'
import { restaurantReservationBlockService } from '../../../models/RestaurantReservationBlock'
import { ReservationListView } from './ReservationListView'
import { SeatScheduleView } from './SeatScheduleView'
import {
  useRestaurantSeatStatuses,
  SeatStatusItem,
} from '../../../models/ReservationBookSeatStatus'
import { getVisitStatusStyle } from './common'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { faCalendarAlt } from '@fortawesome/pro-solid-svg-icons/faCalendarAlt'
import { displayToastSuccess } from '../../Shared/Toast'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { mutate } from 'swr'
import { swrKey } from 'modules/swr'
import { Gesture, GestureDetector } from 'react-native-gesture-handler'
import { Unavailable } from '../../Restaurant/Unavailable'
import { useRestaurant } from '../../../models/Restaurant'
import config from '../../../modules/config'
import { IrregularNotification } from '../IrregularNotification'

function SeatItem({
  name,
  type,
  nextReservation,
  x,
  y,
  angle,
  isHighlighted,
  seatStatusItem,
  onPress,
}: {
  name: string
  type: 'square' | 'counter' | 'round'
  x: number
  y: number
  nextReservation?: {
    start: string
    end: string
  }
  angle: number
  isHighlighted: boolean
  seatStatusItem?: SeatStatusItem
  onPress: () => void
}) {
  const { backgroundColor, color } = getVisitStatusStyle(seatStatusItem?.status)
  const pressGesture = Gesture.Tap()
    .runOnJS(true)
    .onEnd((e, success) => {
      if (success) {
        onPress()
      }
    })

  return (
    <View
      style={{
        position: 'absolute',
        top: y,
        left: x,
        overflow: 'visible',
      }}
    >
      {(seatStatusItem == null || seatStatusItem.status === 'reserved') &&
        nextReservation != null && (
          <View
            style={{
              position: 'absolute',
              zIndex: 100,
              left: -16,
              top: -20,
              width: 112,
              alignItems: 'center',
            }}
          >
            <View
              style={{
                backgroundColor: Colors.white,
                height: 24,
                borderRadius: 12,
                paddingHorizontal: 8,
                paddingVertical: 4,
                boxSizing: 'border-box',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 4,
                justifyContent: 'space-between',
              }}
            >
              <FontAwesomeIcon
                icon={faCalendarAlt}
                color={Colors.black60}
                size={12}
              />
              <Text
                numberOfLines={1}
                style={{
                  fontSize: 12,
                  color: Colors.black,
                  fontWeight: '600',
                }}
              >
                {dayjs(nextReservation.start).format('HH:mm')}-
                {dayjs(nextReservation.end).format('HH:mm')}
              </Text>
            </View>
            <View
              style={{
                width: 7,
                height: 7,
                transform: [{ rotate: '45deg' }],
                backgroundColor: Colors.white,
                marginTop: -3.5,
              }}
            />
          </View>
        )}
      <GestureDetector gesture={pressGesture}>
        <View
          style={[
            {
              opacity: isHighlighted ? 1 : 0.5,
              justifyContent: 'center',
              alignItems: 'center',
              width: 80,
              height: type === 'counter' ? 40 : 80,
              borderWidth: 2,
              borderColor: Colors.border,
              borderRadius: type === 'round' ? 40 : 4,
              backgroundColor:
                seatStatusItem != null &&
                seatStatusItem.status !== 'reserved' &&
                seatStatusItem.status !== 'no_show'
                  ? backgroundColor
                  : Colors.black6,
              transform: [{ rotate: `${angle}deg` }],
            },
          ]}
        >
          <View
            style={{
              transform: [{ rotate: `${-angle}deg` }],
            }}
          >
            <Text
              style={{
                color:
                  seatStatusItem != null &&
                  seatStatusItem.status !== 'reserved' &&
                  seatStatusItem.status !== 'no_show'
                    ? color
                    : Colors.black,
                fontSize: 12,
                textAlign: 'center',
              }}
              numberOfLines={2}
            >
              {name}
            </Text>
          </View>
        </View>
      </GestureDetector>
    </View>
  )
}

const MAP_SIZE = { width: 1500, height: 1500 } as const

export function MapView({ restaurantId }: { restaurantId: number }) {
  const token = useToken()
  const dateString = dayjs().format('YYYY-MM-DD')
  const navigate = useNavigate()
  const navigation = useNavigation()
  const [size, isReady, onLayout] = useComponentSize()
  const [selectedItemId, setSelectedItemId] = useState<string>()
  const [shouldShowWalkInForm, setShouldShowWalkInForm] = useState(false)

  const { data: restaurant } = useRestaurant(restaurantId)
  const { floors } = useRestaurantFloors(restaurantId)
  const [selectedFloorId, setSelectedFloorId] = useFormState<
    string | undefined
  >(floors != null ? floors[0]?.id : undefined)

  const { floor } = useRestaurantFloor(restaurantId, selectedFloorId)

  const initialScale = useMemo(() => {
    if (floor == null) return undefined
    if (!isReady) return undefined
    const maxX = Math.max(
      ...floor.table_seats.map((seat) => seat.floor_position_x ?? 0)
    )
    const maxY = Math.max(
      ...floor.table_seats.map((seat) => seat.floor_position_y ?? 0)
    )
    const scale = Math.min(
      size.width / (maxX + 110),
      size.height / (maxY + 110)
    )
    return Math.min(scale, 2.5)
  }, [floor, size.width, size.height, isReady])

  const { restaurantReservations, mutate: mutateReservations } =
    useRestaurantReservationsWithFloorId(
      restaurantId,
      selectedFloorId,
      { date: dateString },
      { refreshInterval: 1000 * 10 }
    )

  const { data: seatStatuses, mutate: mutateSeatStatus } =
    useRestaurantSeatStatuses(restaurantId, selectedFloorId, {
      refreshInterval: 10000,
    })

  const { data } = restaurantReservationBlockService.useListPeriod({
    restaurantId,
    startDate: dateString,
    endDate: dateString,
  })

  const selectedItem = useMemo(() => {
    if (selectedItemId == null) {
      return undefined
    }
    return floor?.table_seats.find((i) => i.id === selectedItemId)
  }, [selectedItemId, floor])

  const restaurantReservationBlocks = useMemo(
    () =>
      data?.restaurantReservationBlockPeriods.filter((block) => {
        return block.tableSeats.some((seat) =>
          floor?.table_seats.some((i) => i.id === seat.id)
        )
      }),
    [data, floor]
  )

  if (restaurant?.reservation_book_plan_type === 'entry') {
    return (
      <Unavailable
        description={t(
          'リクエスト予約機能のみをご利用中の場合、当機能のご利用はできません。ご利用を希望の方は、お問い合わせフォームからご連絡ください。'
        )}
        toService={`${config.webBaseUrl}/for_restaurants/reservation_book`}
        toContact={`${config.webBaseUrl}/for_restaurants/contact`}
        onPressService={() => {
          Linking.openURL(
            `${config.webBaseUrl}/for_restaurants/reservation_book`
          )
        }}
        onPressContact={() =>
          Linking.openURL(`${config.webBaseUrl}/for_restaurants/contact`)
        }
      />
    )
  }

  return (
    <View
      style={{
        position: 'relative',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'stretch',
        backgroundColor: Colors.bgBlack,
      }}
    >
      <View
        style={{
          flex: 1,
          paddingHorizontal: 16,
          paddingTop: 16,
          paddingBottom: 36,
          gap: 8,
        }}
      >
        <View style={{ height: 48 }}>
          {floors != null && floors.length > 0 && (
            <View style={{ gap: 16, flexDirection: 'row' }}>
              <View>
                <SelectInput
                  containerStyle={{
                    width: 200,
                    height: 48,
                  }}
                  style={{
                    width: 200,
                    height: 48,
                    backgroundColor: Colors.white,
                  }}
                  selectedValue={selectedFloorId}
                  setValue={setSelectedFloorId}
                  items={floors.map((floor) => ({
                    value: floor.id,
                    label: floor.name,
                  }))}
                />
              </View>
              <View style={{ flexGrow: 1 }}>
                <IrregularNotification
                  restaurantId={restaurantId}
                  date={dayjs()}
                  style={{
                    height: 48,
                    alignItems: 'center',
                  }}
                />
              </View>
            </View>
          )}
        </View>
        {floors?.length === 0 ? (
          <View
            style={{
              flex: 1,
              backgroundColor: Colors.black20,
              borderWidth: 2,
              borderColor: Colors.black16,
              alignItems: 'center',
              justifyContent: 'center',
              gap: 16,
            }}
          >
            <Text
              style={{
                fontWeight: '600',
                fontSize: 22,
                color: Colors.white,
              }}
            >
              {t('フロアが作成されていません')}
            </Text>
            {Platform.OS !== 'web' && (
              <Button
                variant="primary"
                style={{
                  height: 48,
                }}
                onPress={() => {
                  if (Platform.OS === 'web') {
                    navigate(`/restaurants/${restaurantId}/floor/new`)
                  } else {
                    navigation.navigate('TableSeatsFloorForm', {})
                  }
                }}
              >
                {t('フロアを新規作成する')}
              </Button>
            )}
          </View>
        ) : floors == null || floor == null ? (
          <View
            style={{
              flex: 1,
              backgroundColor: Colors.black6,
              borderWidth: 2,
              borderColor: Colors.black16,
              alignItems: 'center',
              justifyContent: 'center',
              gap: 16,
            }}
          >
            <Loading />
          </View>
        ) : (
          <View style={{ position: 'relative', flex: 1 }} onLayout={onLayout}>
            <FreeDirectionalScrollView
              containerStyle={{
                position: 'relative',
                backgroundColor: Colors.black6,
                borderWidth: 2,
                borderColor: Colors.black16,
                flex: 1,
                height: Platform.OS !== 'web' ? size.height : undefined,
                width: Platform.OS !== 'web' ? size.width : undefined,
              }}
              initialScale={initialScale}
              paddingLeft={16}
              paddingRight={16}
              paddingTop={16}
              paddingBottom={16}
              contentSize={MAP_SIZE}
              onPressContentView={() => {
                setShouldShowWalkInForm(false)
                setSelectedItemId(undefined)
              }}
              // onChangeScrollPosition={setScrollPosition}
              // onChangeScale={setScale}
            >
              {floor?.table_seats
                .filter((i) => {
                  return (
                    i.floor_position_x != null && i.floor_position_y != null
                  )
                })
                .map((item) => {
                  // 対象の席の予約済みでまだ終了していない予約を次の予約とする
                  const nextReservation = restaurantReservations.find(
                    (reservation) => {
                      return (
                        reservation.table_seats.some(
                          (seat) => seat.id === item.id
                        ) &&
                        reservation.visit_status === 'reserved' &&
                        dayjs(reservation.end_at) > dayjs()
                      )
                    }
                  )
                  return (
                    <SeatItem
                      key={item.id}
                      name={item.name}
                      type={item.layout_parts ?? 'square'}
                      isHighlighted={
                        selectedItemId == null || item.id === selectedItemId
                      }
                      x={item.floor_position_x!}
                      y={item.floor_position_y!}
                      nextReservation={
                        nextReservation
                          ? {
                              start: nextReservation.start_at,
                              end: nextReservation.end_at,
                            }
                          : undefined
                      }
                      angle={item.floor_angle ?? 0}
                      seatStatusItem={seatStatuses?.find(
                        (i) => i.table_seat_id === item.id
                      )}
                      onPress={() => {
                        if (selectedItemId === item.id) {
                          setShouldShowWalkInForm(false)
                          setSelectedItemId(undefined)
                        } else {
                          setSelectedItemId(item.id)
                          setShouldShowWalkInForm(true)
                        }
                      }}
                    />
                  )
                })}
            </FreeDirectionalScrollView>
          </View>
        )}
      </View>
      <View
        style={{
          width: 286,
          backgroundColor: Colors.white,
          overflow: 'visible',
        }}
      >
        <View
          style={{
            paddingLeft: 20,
            height: 64,
            borderBottomWidth: 1,
            borderBottomColor: Colors.border,
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Text
            style={{ fontWeight: '600', fontSize: 18, color: Colors.black }}
          >
            {selectedItem == null ? t('全ての席') : selectedItem.name}
          </Text>
        </View>
        {selectedItem != null ? (
          <SeatScheduleView
            restaurantId={restaurantId}
            dateString={dateString}
            selectedItem={selectedItem}
            restaurantReservationBlocks={
              restaurantReservationBlocks?.filter((block) =>
                block.tableSeats.some((seat) => seat.id === selectedItem.id)
              ) ?? []
            }
            onUpdateVisitStatus={() => {
              mutateReservations()
              mutateSeatStatus()
              setSelectedItemId(undefined)
              setShouldShowWalkInForm(false)
            }}
          />
        ) : (
          <ReservationListView
            restaurantId={restaurantId}
            reservations={restaurantReservations}
            restaurantReservationBlocks={restaurantReservationBlocks ?? []}
            onUpdateVisitStatus={() => {
              mutateReservations()
              mutateSeatStatus()
            }}
          />
        )}
      </View>
      {selectedItem != null && shouldShowWalkInForm && (
        <View
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: Colors.white,
            borderTopWidth: 1,
            borderTopColor: Colors.border,
          }}
        >
          <AddReservationForm
            defaultPartySize={selectedItem.max_party_size ?? 2}
            onPressClose={() => {
              setShouldShowWalkInForm(false)
            }}
            onPressWalkIn={async (partySize) => {
              if (selectedItemId == null) {
                setShouldShowWalkInForm(false)
                return
              }
              const params: RestaurantReservationParams = {
                adult_party_size: partySize,
                table_seat_ids: [selectedItemId],
                kind: 'walkin',
                visit_status: 'all_visited',
              }

              const { error } = await createRestaurantReservation(
                token,
                restaurantId,
                params
              )
              if (error != null) return
              setShouldShowWalkInForm(false)
              displayToastSuccess(t('ウォークインを追加しました'))
              setSelectedItemId(undefined)
              setShouldShowWalkInForm(false)
              mutateReservations()
              mutateSeatStatus()
              mutate(
                swrKey(
                  token,
                  `/reservation_book/restaurants/${restaurantId}/reservations`,
                  { date: dateString, table_seat_id: selectedItem.id }
                )
              )
            }}
            onPressReservation={(partySize) => {
              setSelectedItemId(undefined)
              setShouldShowWalkInForm(false)
              if (Platform.OS === 'web') {
                navigate(
                  `/restaurants/${
                    restaurantId
                  }/reservations/new?selectedSeatIds=${selectedItem.id}&partySize=${partySize}&from=map`
                )
              } else {
                navigation.navigate('ReservationsForm', {
                  selectedSeatIds: [selectedItem.id],
                  partySize,
                  from: 'map',
                })
              }
            }}
          />
        </View>
      )}
    </View>
  )
}
