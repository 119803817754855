import React, { useMemo, useState } from 'react'
import { Linking, View, Platform } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { ScrollView } from 'react-native-gesture-handler'

import {
  updateCustomerRestaurantCustomerNote,
  updateCustomerRestaurantCustomerProfile,
} from '../../models/Customer'

import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FormGroup, FormLabel } from '@hello-ai/ar_shared/src/components/Form'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'

import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'

import {
  createRestaurantCustomer,
  CreateRestaurantCustomerParams,
} from '../../models/RestaurantCustomer'
import { PhoneNumberInput } from '../Shared/PhoneNumberInput'

import { getPhoneNumber } from '../../modules/phoneNumber'
import {
  useRestaurant,
  useRestaurantCustomerNoteTemplate,
} from '@hello-ai/for_r_app/src/models/Restaurant'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { selectImageFromMediaLibrary } from '@hello-ai/ar_shared/src/components/SelectImage'
import { SelectInput } from '@hello-ai/ar_shared/src/components/SelectInput'
import { Image } from '@hello-ai/ar_shared/src/components/Image'
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons/faCircleXmark'
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons/faTrashAlt'
import DatePicker from 'react-native-date-picker'
import { getLocale } from '@hello-ai/ar_shared/src/modules/locale'
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown'
import { faCaretUp } from '@fortawesome/pro-solid-svg-icons/faCaretUp'
import { createRestaurantCustomerImage } from '../../models/RestaurantCustomerImage'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight'
import {
  Checkbox,
  CheckboxGroup,
  CheckboxLabel,
} from '@hello-ai/ar_shared/src/components/Checkbox'
import { useRestaurantAttributes } from '../../models/CustomerAttributes'
import { faExternalLinkAlt } from '@fortawesome/pro-solid-svg-icons/faExternalLinkAlt'
import { useNavigation } from '../../modules/navigation/useNavigation'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import SelectDateTimeInput from '../Shared/SelectDateTimeInput'
import { useNavigate } from '../../modules/navigation/useNavigate'

type DateStateType = dayjs.Dayjs | number | string

function AnniversaryForm({
  item,
  handleAnniversaryChange,
  onRemoveAnniversary,
  onPressDatePicker,
  /** forWeb only */
  onChangeDate,
  /** forWeb only */
  date,
}: {
  item: {
    name: string
    date: {
      year: DateStateType
      month: DateStateType
      day: DateStateType
    }
  }
  handleAnniversaryChange: (
    name: string,
    date: { year: DateStateType; month: DateStateType; day: DateStateType }
  ) => void
  onRemoveAnniversary: () => void
  onPressDatePicker: () => void
  /** forWeb only */
  onChangeDate: (date: dayjs.Dayjs) => void
  /** forWeb only */
  date: dayjs.Dayjs | null
}) {
  const { width, sm } = useResponsive()
  return (
    <View
      style={{
        marginBottom: 16,
      }}
    >
      <TextInput
        style={[width < sm && { height: 48 }, { marginBottom: 16 }]}
        placeholder={t('記念日名')}
        value={item.name}
        onChangeText={(text) => handleAnniversaryChange(text, item.date)}
      />
      {Platform.OS !== 'web' ? (
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            gap: 8,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              width: width > sm ? 160 : 'auto',
              flex: width < sm ? 1 : undefined,
            }}
          >
            <View style={{ flex: 1 }}>
              <TouchableOpacity
                style={{
                  flex: 1,
                  backgroundColor: Colors.field,
                  borderRadius: 8,
                  height: 48,
                  paddingHorizontal: 12,
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderWidth: 2,
                  borderColor: 'transparent',
                }}
                onPress={onPressDatePicker}
              >
                <Text>{`${item.date.year}`}</Text>
                <View>
                  <FontAwesomeIcon
                    icon={faCaretUp}
                    size={10}
                    color={Colors.black}
                  />
                  <FontAwesomeIcon
                    icon={faCaretDown}
                    size={10}
                    color={Colors.black}
                  />
                </View>
              </TouchableOpacity>
            </View>
            <Text
              style={{
                marginLeft: 8,
              }}
            >
              {t('年')}
            </Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              width: width > sm ? 160 : 'auto',
              flex: width < sm ? 1 : undefined,
            }}
          >
            <View style={{ flex: 1 }}>
              <TouchableOpacity
                style={{
                  flex: 1,
                  backgroundColor: Colors.field,
                  borderRadius: 8,
                  height: 48,
                  paddingHorizontal: 12,
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderWidth: 2,
                  borderColor: 'transparent',
                }}
                onPress={onPressDatePicker}
              >
                <Text>{`${item.date.month}`}</Text>
                <View>
                  <FontAwesomeIcon
                    icon={faCaretUp}
                    size={10}
                    color={Colors.black}
                  />
                  <FontAwesomeIcon
                    icon={faCaretDown}
                    size={10}
                    color={Colors.black}
                  />
                </View>
              </TouchableOpacity>
            </View>
            <Text
              style={{
                marginLeft: 8,
              }}
            >
              {t('月')}
            </Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              width: width > sm ? 160 : 'auto',
              flex: width < sm ? 1 : undefined,
            }}
          >
            <View style={{ flex: 1 }}>
              <TouchableOpacity
                style={{
                  flex: 1,
                  backgroundColor: Colors.field,
                  borderRadius: 8,
                  height: 48,
                  paddingHorizontal: 12,
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderWidth: 2,
                  borderColor: 'transparent',
                }}
                onPress={onPressDatePicker}
              >
                <Text>{`${item.date.day}`}</Text>
                <View>
                  <FontAwesomeIcon
                    icon={faCaretUp}
                    size={10}
                    color={Colors.black}
                  />
                  <FontAwesomeIcon
                    icon={faCaretDown}
                    size={10}
                    color={Colors.black}
                  />
                </View>
              </TouchableOpacity>
            </View>
            <Text
              style={{
                marginLeft: 8,
              }}
            >
              {t('日')}
            </Text>
          </View>
        </View>
      ) : (
        <SelectDateTimeInput
          mode="date"
          // eslint-disable-next-line ar-i18n/require-translation-ja
          customFormatLabel={(_, date) => date.format('YYYY年MM月DD日')}
          maxDate={dayjs()}
          minDate={dayjs('1900-01-01')}
          defaultCalendarMonth={dayjs('1970-01-01')}
          dateTime={date}
          onChangeDateTime={(date) => {
            onChangeDate(date)
          }}
        />
      )}
      <TouchableOpacity
        style={{ marginTop: 16, flexDirection: 'row', gap: 4 }}
        onPress={onRemoveAnniversary}
      >
        <FontAwesomeIcon icon={faTrashAlt} color={Colors.caution} size={16} />
        <Text style={{ color: Colors.caution }}>{t('この記念日を削除')}</Text>
      </TouchableOpacity>
    </View>
  )
}

export function NewCustomer({
  restaurantId,
  defaultProps,
  footerText,
  webRedirectTo,
  onCustomerAdded,
  onPressNoteTemplateEdit,
  onPressCustomerAttributes,
}: {
  restaurantId: number
  defaultProps: {
    firstName?: string
    lastName?: string
    firstNameKana?: string
    lastNameKana?: string
    dialCode: string
    phoneNumber?: string
  }
  footerText?: string
  webRedirectTo?: string
  onCustomerAdded?: (customerId: string) => void
  onPressNoteTemplateEdit?: () => void
  onPressCustomerAttributes?: () => void
}) {
  const token = useToken()
  const navigation = useNavigation()
  const navigate = useNavigate()
  const { data: restaurant } = useRestaurant(restaurantId)
  const isFreePlan =
    restaurant == null ||
    restaurant.reservation_book_plan_type === 'free' ||
    restaurant.reservation_book_plan_type === 'entry'

  const { data, mutate: mutateAttributes } = useRestaurantAttributes(
    isFreePlan || !restaurant.reservation_book_available
      ? undefined
      : restaurantId
  )

  const attributes = data?.filter((attribute) => attribute.display_in_list)

  const [firstNameKana, setFirstNameKana] = useFormState(
    defaultProps.firstNameKana ?? ''
  )
  const [lastNameKana, setLastNameKana] = useFormState(
    defaultProps.lastNameKana ?? ''
  )
  const [firstName, setFirstName] = useFormState(defaultProps.firstName ?? '')
  const [lastName, setLastName] = useFormState(defaultProps.lastName ?? '')
  const [dialCode, setDialCode] = useFormState(defaultProps.dialCode)
  const [phoneNumber, setPhoneNumber] = useFormState(
    defaultProps.phoneNumber ?? ''
  )
  const [email, setEmail] = useFormState('')
  const [selectedAttributes, setSelectedAttributes] = useFormState<string[]>([])
  const [allergy, setAllergy] = useFormState('')
  const [content, setContent] = useFormState('')
  const [restaurantCustomerImages, setRestaurantCustomerImages] = useFormState<
    { base64: string }[]
  >([])
  const [gender, setGender] = useFormState<'male' | 'female' | 'other' | null>(
    null
  )
  const [birthYear, setBirthYear] = useFormState<DateStateType>('')
  const [birthMonth, setBirthMonth] = useFormState<DateStateType>('')
  const [birthDay, setBirthDay] = useFormState<DateStateType>('')
  const birth = useMemo(() => {
    if (birthYear === '' || birthMonth === '' || birthDay === '') return null
    return dayjs(`${birthYear}-${birthMonth}-${birthDay}`)
  }, [birthYear, birthMonth, birthDay])

  const [weddingYear, setWeddingYear] = useFormState<DateStateType>('')
  const [weddingMonth, setWeddingMonth] = useFormState<DateStateType>('')
  const [weddingDay, setWeddingDay] = useFormState<DateStateType>('')
  const wedding = useMemo(() => {
    if (weddingYear === '' || weddingMonth === '' || weddingDay === '')
      return null
    return dayjs(`${weddingYear}-${weddingMonth}-${weddingDay}`)
  }, [weddingYear, weddingMonth, weddingDay])

  const [anniversary, setAnniversary] = useFormState<
    {
      name: string
      date: { year: DateStateType; month: DateStateType; day: DateStateType }
    }[]
  >([])
  const [feature, setFeature] = useFormState('')
  const [likes, setLikes] = useFormState('')
  const [dislikes, setDislikes] = useFormState('')
  const [smoking, setSmoking] = useFormState<number | undefined>(undefined)
  const [company, setCompany] = useFormState('')
  const [section, setSection] = useFormState('')
  const [post, setPost] = useFormState('')
  const [receipt_title, setReceiptTitle] = useFormState('')
  const [company_postal_code, setCompanyPostalCode] = useFormState('')
  const [company_address, setCompanyAddress] = useFormState('')
  const [home_postal_code, setHomePostalCode] = useFormState('')
  const [home_address, setHomeAddress] = useFormState('')
  const [x_url, setXUrl] = useFormState('')
  const [facebook_url, setFacebookUrl] = useFormState('')
  const [opt_in_dm, setOptInDM] = useFormState<boolean | null>(null)

  const [datePickerState, setDatePickerState] = useState<{
    type: 'birthday' | 'wedding' | 'anniversaries'
    index: number
  }>()

  const { data: customerNoteTemplateData } =
    useRestaurantCustomerNoteTemplate(restaurantId)
  const isExistCustomerNoteTemplate =
    (customerNoteTemplateData?.content ?? '') !== ''

  const { width, sm } = useResponsive()

  const formGroupMargin = width >= sm ? 40 : 24

  const addAnniversary = () => {
    setAnniversary([
      ...anniversary,
      { name: '', date: { year: '', month: '', day: '' } },
    ])
  }

  const handleAnniversaryChange = (
    index: number,
    name: string,
    date: { year: DateStateType; month: DateStateType; day: DateStateType }
  ) => {
    const newAnniversary = [...anniversary]
    newAnniversary[index] = { name, date }
    setAnniversary(newAnniversary)
  }

  const onPress = async () => {
    const params: CreateRestaurantCustomerParams = {
      reservation_first_name: firstNameKana,
      reservation_last_name: lastNameKana,
      phone_number: getPhoneNumber(phoneNumber, dialCode),
      allergy,
    }

    if (firstName !== '') {
      params.first_name = firstName
    }

    if (lastName !== '') {
      params.last_name = lastName
    }
    if (email !== '') {
      params.email = email
    }

    const { restaurantCustomer, error } = await createRestaurantCustomer(
      token,
      restaurantId,
      params
    )
    if (error != null) return

    // RestaurantCustomerのidはCustomerのidとしても扱うことができる

    // 新規登録フローのみ実装しているため、顧客作成後customer noteの作成のみ失敗した場合に
    // customer noteの作成のみ再実行する方法がない
    // よって、更新に失敗しても無視しモーダルを閉じる必要がある
    await updateCustomerRestaurantCustomerNote(
      token,
      restaurantId,
      restaurantCustomer!.id,
      {
        content,
      }
    )

    const parseBirthday = (
      year: DateStateType,
      month: DateStateType,
      day: DateStateType
    ) => {
      if (year === '' || month === '' || day === '') return null
      return new Date(Date.UTC(Number(year), Number(month) - 1, Number(day)))
    }

    const parseAnniversaries = (
      anniversaries: {
        name: string
        date: { year: DateStateType; month: DateStateType; day: DateStateType }
      }[]
    ) => {
      const arr = anniversaries.map((anniversary) => {
        return {
          name: anniversary.name,
          date: parseBirthday(
            anniversary.date.year,
            anniversary.date.month,
            anniversary.date.day
          ),
        }
      })
      return arr
    }

    const customerProfileParams = {
      anniversaries_attributes: parseAnniversaries(anniversary),
      birthday: parseBirthday(birthYear, birthMonth, birthDay) ?? undefined,
      company,
      company_address,
      company_postal_code,
      dislikes,
      facebook_url,
      feature,
      gender: gender ?? undefined,
      home_address,
      home_postal_code,
      likes,
      opt_in_dm: opt_in_dm ?? undefined,
      post,
      receipt_title,
      section,
      smoking,
      wedding_anniversary:
        parseBirthday(weddingYear, weddingMonth, weddingDay) ?? undefined,
      x_url,
      custom_attributes:
        selectedAttributes.length > 0 ? selectedAttributes.join(',') : null,
    }

    await updateCustomerRestaurantCustomerProfile(
      token,
      restaurantId,
      restaurantCustomer!.id,
      customerProfileParams
    )

    await Promise.all(
      restaurantCustomerImages.map((image) =>
        createRestaurantCustomerImage(
          token,
          restaurantId,
          restaurantCustomer!.id,
          {
            image: image.base64,
          }
        )
      )
    )

    if (Platform.OS === 'web') {
      if (webRedirectTo != null && webRedirectTo !== '') {
        navigate(webRedirectTo)
      } else {
        navigate(-1)
      }
    } else {
      navigation.goBack()
    }
    onCustomerAdded?.(restaurantCustomer!.id)
  }

  const onPressCopyFromNoteTemplate = () => {
    setContent(customerNoteTemplateData?.content ?? '')
  }

  return (
    <>
      <KeyboardAwareScrollView
        style={{ flex: 1, backgroundColor: Colors.white }}
        contentContainerStyle={[
          { padding: 40 },
          width < sm && { paddingTop: 26 },
        ]}
      >
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={<FormLabel value={t('名前')} required />}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          <View style={{ flexDirection: 'row' }}>
            <TextInput
              placeholder={t('せい')}
              style={[{ flex: 1 }, width < sm && { height: 48 }]}
              value={lastNameKana}
              onChangeText={(text) => setLastNameKana(text)}
            />
            <TextInput
              placeholder={t('めい')}
              style={[
                { flex: 1, marginLeft: width >= sm ? 24 : 8 },
                width < sm && { height: 48 },
              ]}
              value={firstNameKana}
              onChangeText={(text) => setFirstNameKana(text)}
            />
          </View>
          <View
            style={{
              marginTop: width >= sm ? 24 : 8,
              flexDirection: 'row',
            }}
          >
            <TextInput
              placeholder={t('姓')}
              style={[{ flex: 1 }, width < sm && { height: 48 }]}
              value={lastName}
              onChangeText={(text) => setLastName(text)}
            />
            <TextInput
              placeholder={t('名')}
              style={[
                { flex: 1, marginLeft: width >= sm ? 24 : 8 },
                width < sm && { height: 48 },
              ]}
              value={firstName}
              onChangeText={(text) => setFirstName(text)}
            />
          </View>
        </FormGroup>
        <FormGroup
          style={{ marginTop: formGroupMargin }}
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={<FormLabel value={t('電話番号')} />}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          <PhoneNumberInput dialCode={dialCode} onChangeDialCode={setDialCode}>
            <TextInput
              style={[{ flex: 1 }, width < sm && { height: 48 }]}
              placeholder={t('ハイフンなしで入力')}
              value={phoneNumber}
              onChangeText={(text) => setPhoneNumber(text)}
              keyboardType="phone-pad"
              textContentType="telephoneNumber"
              clearButtonMode="always"
            />
          </PhoneNumberInput>
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={<FormLabel value={t('メールアドレス')} />}
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          <TextInput
            style={width < sm && { height: 48 }}
            placeholder="info@hello.ai"
            value={email}
            onChangeText={(text) => setEmail(text)}
          />
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={<FormLabel value={t('アレルギー')} />}
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          <TextInput
            style={width < sm && { height: 48 }}
            placeholder={t('アレルギー・苦手なもの')}
            value={allergy}
            onChangeText={(text) => setAllergy(text)}
          />
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={<FormLabel value={t('メモ')} />}
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          <TextInput
            style={width < sm && { paddingTop: 10, paddingBottom: 10 }}
            placeholder={t('メモ')}
            value={content}
            onChangeText={(text) => setContent(text)}
            multiline
          />
          <View
            style={[
              {
                marginTop: 16,
                flexDirection: width >= sm ? 'row' : 'column-reverse',
              },
              width >= sm
                ? { justifyContent: 'flex-end' }
                : { alignItems: 'flex-end' },
            ]}
          >
            <Button
              textStyle={{ color: Colors.secondaryBlack }}
              mode="text"
              variant="secondary"
              onPress={() => {
                if (Platform.OS === 'web') {
                  // web ではモーダル
                  // onPressNoteTemplateEdit でモーダル表示
                } else {
                  navigation.navigate('EditCustomerNoteTemplateModal')
                }
                onPressNoteTemplateEdit?.()
              }}
            >
              {isExistCustomerNoteTemplate
                ? t('メモテンプレートを編集')
                : t('メモテンプレートを作成')}
            </Button>
            {isExistCustomerNoteTemplate && (
              <Button
                mode="outline"
                onPress={onPressCopyFromNoteTemplate}
                style={width < sm && { marginBottom: 8 }}
              >
                {t('メモテンプレートから記入')}
              </Button>
            )}
          </View>
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('写真')}
            />
          }
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          {restaurantCustomerImages?.length > 0 && (
            <ScrollView
              horizontal
              showsHorizontalScrollIndicator={false}
              style={{ flex: 1, paddingTop: 16 }}
            >
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                {restaurantCustomerImages?.map((customerImage, index) => (
                  <View key={`images-${index}`}>
                    <View
                      style={{
                        position: 'absolute',
                        top: -16,
                        right: 12,
                        zIndex: 10,
                        backgroundColor: Colors.white,
                        borderRadius: 9999,
                      }}
                    >
                      <TouchableOpacity
                        style={{
                          zIndex: 10,
                        }}
                        onPress={() => {
                          const newRestaurantCustomerImages = [
                            ...restaurantCustomerImages,
                          ]
                          newRestaurantCustomerImages.splice(index, 1)
                          setRestaurantCustomerImages(
                            newRestaurantCustomerImages
                          )
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faCircleXmark}
                          size={32}
                          color={Colors.black80}
                        />
                      </TouchableOpacity>
                    </View>
                    <TouchableOpacity>
                      <Image
                        source={{ uri: customerImage.base64 }}
                        style={{
                          width: 168,
                          height: 112,
                          borderRadius: 8,
                          marginRight: 24,
                          zIndex: 1,
                        }}
                      />
                    </TouchableOpacity>
                    <Text
                      style={{
                        marginTop: 8,
                        fontSize: 14,
                      }}
                    >
                      {/* eslint-disable-next-line ar-i18n/require-translation-ja */}
                      {/* {dayjs(customerImage.created_at).format(
                        t('YYYY年MM月DD日')
                      )} */}
                    </Text>
                  </View>
                ))}
              </View>
            </ScrollView>
          )}
          <TouchableOpacity
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 16,
            }}
            onPress={async () => {
              const image = await selectImageFromMediaLibrary()

              if (image == null) return
              setRestaurantCustomerImages([
                ...restaurantCustomerImages,
                { base64: image },
              ])
              return 0
            }}
          >
            <FontAwesomeIcon
              icon={faPlus}
              size={16}
              color={Colors.primary}
              style={{ marginRight: 8 }}
            />

            <Text
              style={{
                color: Colors.primary,
              }}
            >
              {t('写真を追加する')}
            </Text>
          </TouchableOpacity>
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('お客様属性')}
            />
          }
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          {isFreePlan || !restaurant.reservation_book_available ? (
            <View
              style={{
                flexDirection: 'row',
                padding: 16,
                backgroundColor: Colors.white,
                borderRadius: 8,
                borderColor: Colors.primary,
                borderWidth: 1,
                gap: 12,
                alignItems: 'center',
              }}
            >
              <Image
                style={{ height: 16, width: 16, marginRight: 4 }}
                source={require('../../assets/images/plan-icon.png')}
              />
              <Text style={{ flex: 1, color: Colors.black }}>
                {restaurant?.reservation_book_plan_type === 'entry'
                  ? t(
                      'リクエスト予約機能のみをご利用中の場合、当機能のご利用はできません。ご利用希望の方はお問い合わせください'
                    )
                  : t(
                      'この機能は予約台帳のスタンダードプラン以上でご利用いただけます。ご利用希望の方はお問い合わせください'
                    )}
              </Text>
              <TouchableOpacity
                style={{ flexDirection: 'row', gap: 4, alignItems: 'center' }}
                onPress={() =>
                  Linking.openURL(
                    'https://autoreserve.com/for_restaurants/contact'
                  )
                }
              >
                <Text style={{ color: Colors.primary }}>
                  {t('お問い合わせへ')}
                </Text>
                <FontAwesomeIcon icon={faChevronRight} color={Colors.primary} />
              </TouchableOpacity>
            </View>
          ) : (
            <>
              <CheckboxGroup
                value={selectedAttributes}
                onChange={setSelectedAttributes}
              >
                {attributes?.map((attribute) => {
                  return (
                    <Checkbox
                      key={attribute.id}
                      value={attribute.id}
                      checkboxLabel={<CheckboxLabel value={attribute.name} />}
                    />
                  )
                })}
              </CheckboxGroup>
              <TouchableOpacity
                style={[
                  { flexDirection: 'row', gap: 4, alignItems: 'center' },
                  { marginTop: 16 },
                ]}
                onPress={() => {
                  if (Platform.OS === 'web') {
                    // web ではモーダル
                    // onPressCustomerAttributes でモーダル表示
                  } else {
                    navigation.navigate('CustomerAttribute', {
                      onClose: () => {
                        mutateAttributes()
                        navigation.goBack()
                      },
                    })
                  }
                  onPressCustomerAttributes?.()
                }}
              >
                <FontAwesomeIcon
                  icon={faExternalLinkAlt}
                  color={Colors.primary}
                  size={16}
                />
                <Text style={{ fontSize: 14, color: Colors.primary }}>
                  {t('お客様属性を追加する')}
                </Text>
              </TouchableOpacity>
            </>
          )}
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('性別')}
            />
          }
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          <SelectInput
            selectedValue={gender}
            setValue={(value: 'male' | 'female' | 'other' | null) =>
              setGender(value)
            }
            items={[
              {
                label: t('未登録'),
                value: null,
              },
              {
                label: t('男性'),
                value: 'male',
              },
              {
                label: t('女性'),
                value: 'female',
              },
              {
                label: t('その他'),
                value: 'other',
              },
            ]}
            disabled={false}
            style={{ width: 140 }}
          />
        </FormGroup>

        <FormGroup
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('誕生日')}
            />
          }
          style={{
            marginTop: formGroupMargin,
            flexDirection: width > sm ? 'row' : 'column',
            gap: 4,
          }}
          containerStyle={
            width < sm && {
              marginTop: 8,
              flexDirection: 'row',
            }
          }
        >
          {Platform.OS !== 'web' ? (
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                gap: 8,
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: width > sm ? 160 : 'auto',
                  flex: width < sm ? 1 : undefined,
                }}
              >
                <View style={{ flex: 1 }}>
                  <TouchableOpacity
                    style={{
                      flex: 1,
                      backgroundColor: Colors.field,
                      borderRadius: 8,
                      height: 48,
                      paddingHorizontal: 12,
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      borderWidth: 2,
                      borderColor: 'transparent',
                    }}
                    onPress={() =>
                      setDatePickerState({ type: 'birthday', index: 0 })
                    }
                  >
                    <Text>{`${birthYear}`}</Text>
                    <View>
                      <FontAwesomeIcon
                        icon={faCaretUp}
                        size={10}
                        color={Colors.black}
                      />
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        size={10}
                        color={Colors.black}
                      />
                    </View>
                  </TouchableOpacity>
                </View>
                <Text
                  style={{
                    marginLeft: 8,
                  }}
                >
                  {t('年')}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: width > sm ? 160 : 'auto',
                  flex: width < sm ? 1 : undefined,
                }}
              >
                <View style={{ flex: 1 }}>
                  <TouchableOpacity
                    style={{
                      flex: 1,
                      backgroundColor: Colors.field,
                      borderRadius: 8,
                      height: 48,
                      paddingHorizontal: 12,
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      borderWidth: 2,
                      borderColor: 'transparent',
                    }}
                    onPress={() =>
                      setDatePickerState({ type: 'birthday', index: 0 })
                    }
                  >
                    <Text>{`${birthMonth}`}</Text>
                    <View>
                      <FontAwesomeIcon
                        icon={faCaretUp}
                        size={10}
                        color={Colors.black}
                      />
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        size={10}
                        color={Colors.black}
                      />
                    </View>
                  </TouchableOpacity>
                </View>
                <Text
                  style={{
                    marginLeft: 8,
                  }}
                >
                  {t('月')}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: width > sm ? 160 : 'auto',
                  flex: width < sm ? 1 : undefined,
                }}
              >
                <View style={{ flex: 1 }}>
                  <TouchableOpacity
                    style={{
                      flex: 1,
                      backgroundColor: Colors.field,
                      borderRadius: 8,
                      height: 48,
                      paddingHorizontal: 12,
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      borderWidth: 2,
                      borderColor: 'transparent',
                    }}
                    onPress={() =>
                      setDatePickerState({ type: 'birthday', index: 0 })
                    }
                  >
                    <Text>{`${birthDay}`}</Text>
                    <View>
                      <FontAwesomeIcon
                        icon={faCaretUp}
                        size={10}
                        color={Colors.black}
                      />
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        size={10}
                        color={Colors.black}
                      />
                    </View>
                  </TouchableOpacity>
                </View>
                <Text
                  style={{
                    marginLeft: 8,
                  }}
                >
                  {t('日')}
                </Text>
              </View>
            </View>
          ) : (
            <SelectDateTimeInput
              mode="date"
              // eslint-disable-next-line ar-i18n/require-translation-ja
              customFormatLabel={(_, date) => date.format('YYYY年MM月DD日')}
              maxDate={dayjs()}
              minDate={dayjs('1900-01-01')}
              defaultCalendarMonth={dayjs('1970-01-01')}
              dateTime={birth !== null ? birth : null}
              onChangeDateTime={(date) => {
                setBirthYear(date.year())
                setBirthMonth(date.month() + 1)
                setBirthDay(date.date())
              }}
            />
          )}
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('結婚記念日')}
            />
          }
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          {Platform.OS !== 'web' ? (
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                gap: 8,
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: width > sm ? 160 : 'auto',
                  flex: width < sm ? 1 : undefined,
                }}
              >
                <View style={{ flex: 1 }}>
                  <TouchableOpacity
                    style={{
                      flex: 1,
                      backgroundColor: Colors.field,
                      borderRadius: 8,
                      height: 48,
                      paddingHorizontal: 12,
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      borderWidth: 2,
                      borderColor: 'transparent',
                    }}
                    onPress={() =>
                      setDatePickerState({ type: 'wedding', index: 0 })
                    }
                  >
                    <Text>{`${weddingYear}`}</Text>
                    <View>
                      <FontAwesomeIcon
                        icon={faCaretUp}
                        size={10}
                        color={Colors.black}
                      />
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        size={10}
                        color={Colors.black}
                      />
                    </View>
                  </TouchableOpacity>
                </View>
                <Text
                  style={{
                    marginLeft: 8,
                  }}
                >
                  {t('年')}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: width > sm ? 160 : 'auto',
                  flex: width < sm ? 1 : undefined,
                }}
              >
                <View style={{ flex: 1 }}>
                  <TouchableOpacity
                    style={{
                      flex: 1,
                      backgroundColor: Colors.field,
                      borderRadius: 8,
                      height: 48,
                      paddingHorizontal: 12,
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      borderWidth: 2,
                      borderColor: 'transparent',
                    }}
                    onPress={() =>
                      setDatePickerState({ type: 'wedding', index: 0 })
                    }
                  >
                    <Text>{`${weddingMonth}`}</Text>
                    <View>
                      <FontAwesomeIcon
                        icon={faCaretUp}
                        size={10}
                        color={Colors.black}
                      />
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        size={10}
                        color={Colors.black}
                      />
                    </View>
                  </TouchableOpacity>
                </View>
                <Text
                  style={{
                    marginLeft: 8,
                  }}
                >
                  {t('月')}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: width > sm ? 160 : 'auto',
                  flex: width < sm ? 1 : undefined,
                }}
              >
                <View style={{ flex: 1 }}>
                  <TouchableOpacity
                    style={{
                      flex: 1,
                      backgroundColor: Colors.field,
                      borderRadius: 8,
                      height: 48,
                      paddingHorizontal: 12,
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      borderWidth: 2,
                      borderColor: 'transparent',
                    }}
                    onPress={() =>
                      setDatePickerState({ type: 'wedding', index: 0 })
                    }
                  >
                    <Text>{`${weddingDay}`}</Text>
                    <View>
                      <FontAwesomeIcon
                        icon={faCaretUp}
                        size={10}
                        color={Colors.black}
                      />
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        size={10}
                        color={Colors.black}
                      />
                    </View>
                  </TouchableOpacity>
                </View>
                <Text
                  style={{
                    marginLeft: 8,
                  }}
                >
                  {t('日')}
                </Text>
              </View>
            </View>
          ) : (
            <SelectDateTimeInput
              mode="date"
              // eslint-disable-next-line ar-i18n/require-translation-ja
              customFormatLabel={(_, date) => date.format('YYYY年MM月DD日')}
              maxDate={dayjs()}
              minDate={dayjs('1900-01-01')}
              defaultCalendarMonth={dayjs('1970-01-01')}
              dateTime={wedding !== null ? wedding : null}
              onChangeDateTime={(date) => {
                setWeddingYear(date.year())
                setWeddingMonth(date.month() + 1)
                setWeddingDay(date.date())
              }}
            />
          )}
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('その他記念日')}
            />
          }
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          {anniversary.map((item, index) => (
            <AnniversaryForm
              key={index}
              item={item}
              handleAnniversaryChange={(name, date) =>
                handleAnniversaryChange(index, name, date)
              }
              onRemoveAnniversary={() => {
                const newAnniversary = [...anniversary]
                newAnniversary.splice(index, 1)
                setAnniversary(newAnniversary)
              }}
              onPressDatePicker={() =>
                setDatePickerState({ type: 'anniversaries', index })
              }
              onChangeDate={(date) => {
                const newAnniversaries = [...anniversary]
                newAnniversaries[index].date = {
                  year: date.year(),
                  month: date.month() + 1,
                  day: date.date(),
                }
                setAnniversary(newAnniversaries)
              }}
              date={(() => {
                if (
                  item.date.year === '' ||
                  item.date.month === '' ||
                  item.date.day === ''
                ) {
                  return null
                }
                return dayjs(
                  `${item.date.year}-${item.date.month}-${item.date.day}`
                )
              })()}
            />
          ))}
          <TouchableOpacity
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
            onPress={addAnniversary}
          >
            <FontAwesomeIcon
              icon={faPlus}
              size={16}
              color={Colors.primary}
              style={{ marginRight: 8 }}
            />

            <Text
              style={{
                color: Colors.primary,
              }}
            >
              {t('記念日を追加する')}
            </Text>
          </TouchableOpacity>
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('特徴')}
            />
          }
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          <TextInput
            style={width < sm && { height: 48 }}
            placeholder={t('特徴')}
            value={feature}
            onChangeText={(text) => setFeature(text)}
          />
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('好きなもの')}
            />
          }
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          <TextInput
            style={width < sm && { height: 48 }}
            placeholder={t('好きなもの')}
            value={likes}
            onChangeText={(text) => setLikes(text)}
          />
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('嫌いなもの')}
            />
          }
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          <TextInput
            style={width < sm && { height: 48 }}
            placeholder={t('嫌いなもの')}
            value={dislikes}
            onChangeText={(text) => setDislikes(text)}
          />
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('喫煙情報')}
            />
          }
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          <SelectInput
            selectedValue={smoking}
            setValue={(value) => setSmoking(value)}
            items={[
              {
                label: t('未登録'),
                value: undefined,
              },
              {
                label: t('有'),
                value: 1,
              },
              {
                label: t('無'),
                value: 0,
              },
            ]}
            disabled={false}
            style={{ width: 140 }}
          />
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('領収書名')}
            />
          }
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          <TextInput
            style={width < sm && { height: 48 }}
            placeholder={t('領収書名')}
            value={receipt_title}
            onChangeText={(text) => setReceiptTitle(text)}
          />
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('会社名')}
            />
          }
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          <TextInput
            style={width < sm && { height: 48 }}
            placeholder={t('会社名')}
            value={company}
            onChangeText={(text) => setCompany(text)}
          />
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('部署名')}
            />
          }
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          <TextInput
            style={width < sm && { height: 48 }}
            placeholder={t('部署名')}
            value={section}
            onChangeText={(text) => setSection(text)}
          />
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('役職名')}
            />
          }
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          <TextInput
            style={width < sm && { height: 48 }}
            placeholder={t('役職名')}
            value={post}
            onChangeText={(text) => setPost(text)}
          />
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('勤務先住所')}
            />
          }
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          <TextInput
            style={
              width < sm
                ? {
                    width: 140,
                    height: 48,
                    marginBottom: 16,
                  }
                : {
                    width: 140,
                    marginBottom: 16,
                  }
            }
            placeholder={t('郵便番号')}
            value={company_postal_code}
            onChangeText={(text) => setCompanyPostalCode(text)}
          />
          <TextInput
            style={width < sm && { height: 48 }}
            placeholder={t('住所')}
            value={company_address}
            onChangeText={(text) => setCompanyAddress(text)}
          />
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('自宅住所')}
            />
          }
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          <TextInput
            style={
              width < sm
                ? {
                    height: 48,
                    marginBottom: 16,
                    width: 140,
                  }
                : {
                    marginBottom: 16,
                    width: 140,
                  }
            }
            placeholder={t('郵便番号')}
            value={home_postal_code}
            onChangeText={(text) => setHomePostalCode(text)}
          />
          <TextInput
            style={width < sm && { height: 48 }}
            placeholder={t('住所')}
            value={home_address}
            onChangeText={(text) => setHomeAddress(text)}
          />
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('X(Twitter)')}
            />
          }
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          <TextInput
            style={width < sm && { height: 48 }}
            placeholder={t('X(Twitter)')}
            value={x_url}
            onChangeText={(text) => setXUrl(text)}
          />
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('Facebook')}
            />
          }
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          <TextInput
            style={width < sm && { height: 48 }}
            placeholder={t('Facebook')}
            value={facebook_url}
            onChangeText={(text) => setFacebookUrl(text)}
          />
        </FormGroup>
        <FormGroup
          mode={width >= sm ? 'inline' : 'vertical'}
          formLabel={
            <FormLabel
              labelStyle={{
                fontSize: width >= sm ? 16 : 14,
              }}
              value={t('DM可否')}
            />
          }
          style={{ marginTop: formGroupMargin }}
          containerStyle={width < sm && { marginTop: 8 }}
        >
          <SelectInput
            selectedValue={opt_in_dm}
            setValue={(value) => setOptInDM(value)}
            items={[
              {
                label: t('未登録'),
                value: null,
              },
              {
                label: t('可'),
                value: true,
              },
              {
                label: t('不可'),
                value: false,
              },
            ]}
            disabled={false}
            style={{ width: 140 }}
          />
        </FormGroup>
      </KeyboardAwareScrollView>
      <View
        style={{
          shadowColor: '#000',
          shadowOffset: {
            width: 0,
            height: -4,
          },
          shadowOpacity: 0.15,
          shadowRadius: 8,
          elevation: 2,
          paddingVertical: 24,
          paddingHorizontal: 16,
          backgroundColor: 'white',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button onPress={onPress} width={260} height={48}>
          {footerText}
        </Button>
      </View>
      {Platform.OS !== 'web' && (
        <DatePicker
          locale={getLocale()}
          confirmText={t('決定')}
          cancelText={t('キャンセル')}
          modal
          mode="date"
          open={datePickerState !== undefined}
          date={(() => {
            switch (datePickerState?.type) {
              case 'birthday':
                if (birthYear === '' || birthMonth === '' || birthDay === '') {
                  return new Date(0)
                }
                return new Date(
                  Number(birthYear),
                  Number(birthMonth) - 1,
                  Number(birthDay)
                )
              case 'wedding':
                if (
                  weddingYear === '' ||
                  weddingMonth === '' ||
                  weddingDay === ''
                ) {
                  return new Date(0)
                }
                return new Date(
                  Number(weddingYear),
                  Number(weddingMonth) - 1,
                  Number(weddingDay)
                )
              case 'anniversaries': {
                const date = anniversary[datePickerState.index].date
                if (date.year === '' || date.month === '' || date.day === '') {
                  return new Date(0)
                }
                return new Date(
                  Number(anniversary[datePickerState.index].date.year),
                  Number(anniversary[datePickerState.index].date.month) - 1,
                  Number(anniversary[datePickerState.index].date.day)
                )
              }
            }
            return new Date(0)
          })()}
          minimumDate={new Date(1900, 1, 1)}
          maximumDate={new Date()}
          onCancel={() => setDatePickerState(undefined)}
          onConfirm={(date) => {
            switch (datePickerState?.type) {
              case 'birthday':
                setBirthYear(date.getFullYear().toString())
                setBirthMonth((date.getMonth() + 1).toString())
                setBirthDay(date.getDate().toString())
                break
              case 'wedding':
                setWeddingYear(date.getFullYear().toString())
                setWeddingMonth((date.getMonth() + 1).toString())
                setWeddingDay(date.getDate().toString())
                break
              case 'anniversaries': {
                const newAnniversaries = [...anniversary]
                newAnniversaries[datePickerState.index].date = {
                  year: date.getFullYear().toString(),
                  month: (date.getMonth() + 1).toString(),
                  day: date.getDate().toString(),
                }
                setAnniversary(newAnniversaries)
                break
              }
            }
            setDatePickerState(undefined)
          }}
        />
      )}
    </>
  )
}
