import React, { useMemo } from 'react'
import { ScrollView, View, Platform } from 'react-native'

import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'

import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import { faAngleRight } from '@fortawesome/pro-light-svg-icons/faAngleRight'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { Customer } from '@hello-ai/ar_shared/src/types/ForR/Customer'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { getCustomerDisplayName } from '../../../Customers/Customer'
import { RestaurantReservationCourse } from '@hello-ai/ar_shared/src/types/ForR/RestaurantReservationCourse'

import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import {
  ReservationRepContainer,
  ReservationRepWithDate,
} from '../../../Reservation/RepView'
import type { RestaurantRequestReservation } from '@hello-ai/ar_shared/src/types/ForR/RestaurantRequestReservation'
import { useRestaurantRequestReservationsWithoutBeforeChanged } from '../../../../models/RestaurantRequestReservation'
import { Pagination } from '@hello-ai/ar_shared/src/components/Pagination'
import { RefreshControl } from '@hello-ai/ar_shared/src/components/RefreshControl'
import { faPhone } from '@fortawesome/pro-solid-svg-icons/faPhone'
import { faFrown } from '@fortawesome/pro-solid-svg-icons/faFrown'
import { faAddressBook } from '@fortawesome/pro-solid-svg-icons/faAddressBook'
import { faPenToSquare } from '@fortawesome/pro-solid-svg-icons/faPenToSquare'
import { numberWithDelimiter } from '@hello-ai/ar_shared/src/modules/numberWithDelimiter'
import { IrregularNotification } from '../../../Reservations/IrregularNotification'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { useNavigation } from '../../../../modules/navigation/useNavigation'
import { useNavigate } from '../../../../modules/navigation/useNavigate'

export const ContentTypes = ['reserved', 'future_only', 'canceled'] as const

export type ContentType = (typeof ContentTypes)[number]

export function getTitle(type: ContentType) {
  switch (type) {
    case 'reserved':
      return t('成立した予約')
    case 'future_only':
      return t('来店前の予約')
    case 'canceled':
      return t('キャンセル済みの予約')
  }
}

export function Empty({ title }: { title: string }) {
  return (
    <View
      style={{
        paddingVertical: 32,
        alignItems: 'center',
        backgroundColor: Colors.white,
        borderColor: Colors.border,
        borderBottomWidth: 0.5,
        borderRadius: 4,
      }}
    >
      <Text
        style={{
          color: Colors.black60,
          fontWeight: '600',
        }}
      >
        {title}
      </Text>
    </View>
  )
}

interface EllipsisCourse extends Omit<RestaurantReservationCourse, 'id'> {
  id: React.Key
}
function EllipsisCourseTextView({
  index,
  reservationCourse,
}: {
  index: number
  reservationCourse: EllipsisCourse
}) {
  const priceStr = useMemo(() => {
    const price = reservationCourse.restaurant_course?.price ?? 0
    if (price > 0) return `¥${numberWithDelimiter(price)}`
    return ''
  }, [reservationCourse.restaurant_course?.price])

  return (
    <View style={{ marginTop: index === 0 ? 0 : 8 }}>
      <Text numberOfLines={2} style={{ fontWeight: '600', fontSize: 14 }}>
        {reservationCourse.restaurant_course?.title ||
          t('コースが見つかりません')}
      </Text>
      <Text
        style={{
          fontWeight: '600',
          fontSize: 14,
        }}
      >
        {t('{{text}}（{{party_size}}名）', {
          text: priceStr,
          party_size: reservationCourse.party_size,
        })}
      </Text>
    </View>
  )
}

function TableRow({
  requestReservation,
  onPress,
  onPressCustomer,
}: {
  requestReservation: RestaurantRequestReservation
  onPress?: () => void
  onPressCustomer?: (customerId: Customer['id']) => void
}) {
  const { width, sm } = useResponsive()

  if (width < sm) {
    return (
      <TouchableOpacity
        style={{
          flexDirection: 'row',
        }}
        onPress={onPress}
        activeOpacity={onPress == null ? 1 : undefined}
      >
        <View
          style={{
            flex: 1,
            flexDirection: 'column',
            padding: 20,
          }}
        >
          <View style={{ flex: 1, flexDirection: 'row' }}>
            <View style={{ flex: 1 }}>
              <Text style={{ fontSize: 14 }}>
                {dayjs(requestReservation.start_at).format('HH:mm')}~
                {dayjs(requestReservation.end_at).format('HH:mm')}
              </Text>
              {requestReservation.customer != null && (
                <View
                  style={{
                    flex: 2,
                    paddingVertical: 8,
                  }}
                >
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: '600',
                      }}
                    >
                      {requestReservation.name != null
                        ? t('{{name}}様', { name: requestReservation.name })
                        : getCustomerDisplayName(
                            requestReservation.customer,
                            t('様')
                          )}
                    </Text>
                    {requestReservation.party_size > 1 && (
                      <Text
                        style={{
                          fontSize: 14,
                          marginLeft: 16,
                          color: Colors.black60,
                        }}
                      >
                        {t('他{{partySize}}名', {
                          partySize: requestReservation.party_size - 1,
                        })}
                      </Text>
                    )}
                  </View>
                </View>
              )}
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: '300',
                  color: Colors.black,
                }}
              >
                {t('{{partySize}}名 | {{tableSeats}}', {
                  partySize: requestReservation.party_size,
                  tableSeats:
                    requestReservation.restaurant_seat?.name ?? t('なし'),
                })}
              </Text>
            </View>
            {onPress !== undefined && (
              <View
                style={{
                  justifyContent: 'center',
                  paddingVertical: 24,
                }}
              >
                <FontAwesomeIcon
                  size={20}
                  color={Colors.black60}
                  icon={faAngleRight}
                />
              </View>
            )}
          </View>
          <ReservationRepContainer
            style={{
              marginVertical: 8,
              marginLeft: 0,
              marginTop: 20,
            }}
          >
            <ReservationRepWithDate
              label={t('受付日')}
              date={requestReservation.created_at}
            />
            {requestReservation.reservation?.reservation_change_request !=
              null &&
              requestReservation.reservation.reservation_change_request
                .status === 'requesting' && (
                <ReservationRepWithDate
                  label={t('変更リクエスト日')}
                  date={
                    requestReservation.reservation.reservation_change_request
                      .created_at
                  }
                />
              )}
          </ReservationRepContainer>
        </View>
      </TouchableOpacity>
    )
  }

  return (
    <TouchableOpacity
      onPress={onPress}
      activeOpacity={onPress == null ? 1 : undefined}
      style={{
        padding: 20,
        rowGap: 24,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
        }}
      >
        <View
          style={{
            flex: 1,
            flexDirection: 'column',
          }}
        >
          <Text
            style={{
              marginTop: 2, // 予約者情報と上が揃って見えるように視覚的な補正を入れる
              fontSize: 14,
            }}
          >
            {dayjs(requestReservation.start_at).format('HH:mm')}~
            {dayjs(requestReservation.end_at).format('HH:mm')}
          </Text>
        </View>
        <View
          style={{
            flex: 2,
          }}
        >
          {requestReservation.customer != null && (
            <View>
              <Text
                style={{
                  fontWeight: '600',
                }}
              >
                {requestReservation.name != null
                  ? t('{{name}}様', { name: requestReservation.name })
                  : getCustomerDisplayName(
                      requestReservation.customer,
                      t('様')
                    )}
              </Text>
              <View
                style={[
                  {
                    marginTop: 8,
                    flexDirection: 'row',
                    alignItems: 'center',
                  },
                ]}
              >
                <View
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 16,
                    height: 16,
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPhone}
                    size={13.3}
                    color={Colors.black50}
                  />
                </View>
                <Text
                  style={{
                    marginLeft: 8,
                    fontSize: 14,
                  }}
                >
                  {requestReservation.customer.phone_number_format ??
                    t('未登録')}
                </Text>
              </View>
              <View
                style={[
                  {
                    marginTop: 4,
                    flexDirection: 'row',
                    alignItems: 'center',
                  },
                ]}
              >
                <View
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 16,
                    height: 16,
                  }}
                >
                  <FontAwesomeIcon
                    icon={faFrown}
                    size={16}
                    color={Colors.black50}
                  />
                </View>
                <Text
                  style={{
                    marginLeft: 8,
                    fontSize: 14,
                  }}
                >
                  {(requestReservation.customer.allergy ?? '') || t('未記入')}
                </Text>
              </View>
              {onPressCustomer != null && (
                <TouchableOpacity
                  disabled={
                    requestReservation.customer?.id == null ||
                    requestReservation.customer.id === ''
                  }
                  onPress={() => {
                    if (requestReservation.customer?.id != null) {
                      onPressCustomer(requestReservation.customer.id)
                    }
                  }}
                  style={[
                    {
                      marginTop: 16,
                      flexDirection: 'row',
                      alignItems: 'center',
                    },
                    requestReservation.customer.id === '' && {
                      opacity: 0.6,
                    },
                  ]}
                >
                  <FontAwesomeIcon
                    icon={faAddressBook}
                    size={16}
                    color={Colors.primary}
                  />
                  <Text
                    style={{
                      marginLeft: 8,
                      fontSize: 16,
                      color: Colors.primary,
                    }}
                  >
                    {t('顧客情報を見る')}
                  </Text>
                </TouchableOpacity>
              )}
            </View>
          )}
          {requestReservation.party_size > 1 && (
            <Text
              style={{
                marginTop: 8,
                fontSize: 14,
                color: Colors.black60,
              }}
            >
              {t('他{{partySize}}名', {
                partySize: requestReservation.party_size - 1,
              })}
            </Text>
          )}
          {requestReservation.memo != null &&
            requestReservation.memo !== '' && (
              <View
                style={[
                  {
                    marginTop: 8,
                    flexDirection: 'row',
                    alignItems: 'center',
                  },
                ]}
              >
                <View
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 16,
                    height: 16,
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPenToSquare}
                    size={16}
                    color={Colors.black50}
                  />
                </View>
              </View>
            )}
        </View>
        <View
          style={{
            flex: 1,
            paddingVertical: 24,
          }}
        >
          <View
            style={{
              paddingRight: 24,
            }}
          >
            <Text
              style={{
                fontSize: 14,
                fontWeight: '600',
              }}
            >
              {requestReservation.restaurant_seat?.name ?? t('なし')}
            </Text>
            <Text
              style={{
                marginTop: 8,
                fontSize: 14,
                fontWeight: '600',
              }}
            >
              {requestReservation.party_size}
              {t('人')}
            </Text>
          </View>
        </View>
        <View
          style={{
            flex: 2,
            paddingVertical: 24,
          }}
        >
          <View>
            {(requestReservation.reservation_request_courses?.length ?? 0) >
              0 &&
              requestReservation.reservation_request_courses.map(
                (reservationCourse, index) => {
                  return (
                    <EllipsisCourseTextView
                      key={reservationCourse.id}
                      reservationCourse={reservationCourse}
                      index={index}
                    />
                  )
                }
              )}
            <Text
              style={{
                marginTop:
                  (requestReservation.reservation_request_courses?.length ??
                    0) > 0
                    ? 16
                    : 0,
                fontSize: 14,
              }}
              numberOfLines={3}
            >
              {requestReservation.memo}
            </Text>
          </View>
        </View>
        {onPress !== undefined && (
          <View
            style={{
              justifyContent: 'center',
              paddingVertical: 24,
            }}
          >
            <FontAwesomeIcon
              size={20}
              color={Colors.black}
              icon={faAngleRight}
            />
          </View>
        )}
      </View>
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <View
          style={{
            flex: 1 / 4,
            alignItems: 'flex-start',
          }}
        />
        <ReservationRepContainer>
          <ReservationRepWithDate
            label={t('受付日')}
            date={requestReservation.created_at}
          />
          {requestReservation.reservation?.reservation_change_request != null &&
            requestReservation.reservation.reservation_change_request.status ===
              'requesting' && (
              <ReservationRepWithDate
                label={t('変更リクエスト日')}
                date={
                  requestReservation.reservation.reservation_change_request
                    .created_at
                }
              />
            )}
        </ReservationRepContainer>
      </View>
    </TouchableOpacity>
  )
}

export function Header({ children }: { children: React.ReactNode }) {
  return (
    <View
      style={{
        flexDirection: 'row',
        borderColor: Colors.border,
        borderBottomWidth: 0.5,
      }}
    >
      {children}
    </View>
  )
}

export function HeaderCell({
  flex,
  children,
  isFirst = false,
}: {
  flex: number
  children: string
  isFirst?: boolean
}) {
  return (
    <View
      style={{
        flexDirection: 'row',
        paddingVertical: 16,
        alignItems: 'center',
        flex,
        paddingLeft: isFirst ? 20 : 0,
      }}
    >
      <Text
        style={{
          fontWeight: '600',
          fontSize: 14,
        }}
      >
        {children}
      </Text>
    </View>
  )
}

export function AllTable({
  requestReservations,
  onPressRequestRestaurantReservation,
  onPressCustomer,
}: {
  requestReservations?: RestaurantRequestReservation[]
  onPressRequestRestaurantReservation?: (
    requestReservation: RestaurantRequestReservation
  ) => void
  onPressCustomer?: (customerId: Customer['id']) => void
}) {
  const { width, sm } = useResponsive()
  const sectionedRequestReservations = useMemo(() => {
    if (requestReservations == null) return {}
    // reserved_at の日付ごとに分ける
    return requestReservations.reduce<{
      [key: string]: RestaurantRequestReservation[]
    }>((acc, requestReservation) => {
      let date: string
      if (requestReservation.reservation?.reservation_change_request != null) {
        date = dayjs(
          requestReservation.reservation.reservation_change_request
            .to_reserved_at
        ).format('YYYY-MM-DD')
      } else {
        date = dayjs(requestReservation.start_at).format('YYYY-MM-DD')
      }
      if (acc[date] == null) {
        acc[date] = []
      }
      acc[date].push(requestReservation)
      return acc
    }, {})
  }, [requestReservations])

  return (
    <View>
      {width >= sm && (
        <View
          style={{
            flexDirection: 'row',
            borderColor: Colors.black16,
            borderBottomWidth: 1,
          }}
        >
          <HeaderCell isFirst flex={1}>
            {t('予約時間')}
          </HeaderCell>
          <HeaderCell flex={2}>{t('予約者情報')}</HeaderCell>
          <HeaderCell flex={1}>{t('席・人数')}</HeaderCell>
          <HeaderCell flex={2}>{t('備考')}</HeaderCell>
          {onPressRequestRestaurantReservation != null && (
            <View style={{ width: 40 }} />
          )}
        </View>
      )}
      {Object.keys(sectionedRequestReservations)
        .sort()
        .map((date) => (
          <View key={`request-reservations-${date}`}>
            <View
              style={{
                paddingHorizontal: 20,
                paddingVertical: 12,
                borderBottomWidth: 1,
                borderColor: Colors.black16,
              }}
            >
              <Text
                style={{
                  color: Colors.black,
                  fontSize: 18,
                  fontWeight: '600',
                }}
              >
                {`${dayjs(date).format('LL')}(${dayjs(date).format('ddd')})`}
              </Text>
            </View>
            {sectionedRequestReservations[date].map((requestReservation) => (
              <View
                key={requestReservation.id}
                style={[
                  {
                    backgroundColor: Colors.white,
                    borderColor: Colors.black16,
                    borderBottomWidth: 1,
                    borderRadius: 4,
                  },
                ]}
              >
                <TableRow
                  requestReservation={requestReservation}
                  onPress={
                    onPressRequestRestaurantReservation == null
                      ? undefined
                      : () =>
                          onPressRequestRestaurantReservation(
                            requestReservation
                          )
                  }
                  onPressCustomer={onPressCustomer}
                />
              </View>
            ))}
          </View>
        ))}
    </View>
  )
}

export function Table({
  requestReservations,
  onPressRequestRestaurantReservation,
  onPressCustomer,
}: {
  requestReservations?: RestaurantRequestReservation[]
  onPressRequestRestaurantReservation?: (
    requestReservation: RestaurantRequestReservation
  ) => void
  onPressCustomer?: (customerId: Customer['id']) => void
}) {
  const { width, sm } = useResponsive()
  return (
    <View>
      {width >= sm && (
        <Header>
          <HeaderCell isFirst flex={1}>
            {t('予約時間')}
          </HeaderCell>
          <HeaderCell flex={2}>{t('予約者情報')}</HeaderCell>
          <HeaderCell flex={1}>{t('席・人数')}</HeaderCell>
          <HeaderCell flex={2}>{t('備考')}</HeaderCell>
          {onPressRequestRestaurantReservation != null && (
            <View style={{ marginLeft: 24, width: 22, paddingRight: 24 }} />
          )}
        </Header>
      )}
      {requestReservations?.map((requestReservation) => (
        <View
          key={requestReservation.id}
          style={[
            {
              backgroundColor: Colors.white,
              borderColor: Colors.border,
              borderBottomWidth: 0.5,
              borderRadius: 4,
            },
          ]}
        >
          <TableRow
            requestReservation={requestReservation}
            onPress={
              onPressRequestRestaurantReservation == null
                ? undefined
                : () => onPressRequestRestaurantReservation(requestReservation)
            }
            onPressCustomer={onPressCustomer}
          />
        </View>
      ))}
    </View>
  )
}

export default function RequestReservationListView({
  restaurantId,
  date,
  onPressRequestRestaurantReservation,
  onPressCustomer,
}: {
  restaurantId: number
  date: dayjs.Dayjs
  onPressRequestRestaurantReservation?: (
    requestReservation: RestaurantRequestReservation
  ) => void
  onPressCustomer?: (customerId: Customer['id']) => void
}) {
  const { width, sm } = useResponsive()
  const navigation = useNavigation()
  const navigate = useNavigate()
  const { requestReservations, pagination, isLoading, mutate } =
    useRestaurantRequestReservationsWithoutBeforeChanged(restaurantId, {
      date,
      per: 20,
    })

  return (
    <ScrollView
      style={{ flex: 1 }}
      contentContainerStyle={[
        width >= sm && {
          paddingTop: 40,
        },
        {
          paddingHorizontal: width < sm ? 16 : 24,
          paddingBottom: 20,
        },
        width < sm && {
          paddingBottom: 80,
        },
      ]}
      refreshControl={
        <RefreshControl refreshing={isLoading} onRefresh={mutate} />
      }
    >
      <IrregularNotification
        restaurantId={restaurantId}
        date={date}
        style={width < sm ? { marginTop: 24 } : undefined}
      />
      <View
        style={[
          {
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          },
          width < sm && {
            marginTop: 24,
          },
        ]}
      >
        <Button
          style={{
            height: 48,
            borderRadius: 24,
            backgroundColor: Colors.primaryBg,
            borderColor: Colors.primaryBg,
          }}
          onPress={() => {
            if (Platform.OS === 'web') {
              navigate(`/restaurants/${restaurantId}/reservations/requests`)
            } else {
              navigation.navigate('RequestReservationList')
            }
          }}
        >
          <Text
            style={{ color: Colors.primary, fontSize: 16, fontWeight: '600' }}
          >
            {t('承認待ちリクエスト予約一覧')}
          </Text>
        </Button>
      </View>

      <View
        style={[
          {
            marginTop: 24,
            backgroundColor: Colors.white,
            borderRadius: 4,
            shadowColor: '#000',
            shadowOffset: {
              width: 0,
              height: 1,
            },
            shadowOpacity: 0.1,
            shadowRadius: 4,
            elevation: 2,
          },
        ]}
      >
        <Table
          requestReservations={requestReservations}
          onPressRequestRestaurantReservation={
            onPressRequestRestaurantReservation
          }
          onPressCustomer={onPressCustomer}
        />
        {requestReservations != null && requestReservations.length === 0 && (
          <Empty title={t('予約がありません')} />
        )}
        {requestReservations === undefined && (
          <View
            style={{
              paddingVertical: 24,
            }}
          >
            <Loading />
          </View>
        )}

        <View
          style={[
            {
              paddingVertical: 24,
              marginBottom: 16,
            },
            width >= sm
              ? {
                  borderTopWidth: 0.5,
                  borderColor: Colors.border,
                }
              : {
                  backgroundColor: Colors.white,
                },
          ]}
        >
          {pagination != null && (
            <Pagination
              currentPage={pagination.currentPage}
              totalPage={pagination.totalPages}
              setPage={pagination.setPage}
            />
          )}
        </View>
      </View>
    </ScrollView>
  )
}
