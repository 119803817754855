import React from 'react'
import { View } from 'react-native'
import { Text } from '../../../Text'
import { Colors } from '../../../../constants/Colors'
import { OutboundUpdateHistory } from '../../../../types/ForR/OutboundSetting'
import { t } from '../../../../modules/i18n'
import dayjs from '../../../../modules/dayjs'

export default function UpdateHistoryTitle({
  latestHistory,
}: {
  latestHistory?: OutboundUpdateHistory
}) {
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'flex-end',
        marginBottom: 16,
      }}
    >
      <Text
        style={{
          fontSize: 22,
          fontWeight: '600',
          lineHeight: 33,
          color: Colors.black,
          marginRight: 24,
        }}
      >
        {t('更新履歴')}
      </Text>
      <Text
        style={{
          fontSize: 12,
          fontWeight: '300',
          lineHeight: 18,
        }}
      >
        {t('最終更新日：{{date}}', {
          date:
            latestHistory == null
              ? '---/--/-- --:--'
              : dayjs(latestHistory.created_at)
                  .tz(dayjs.tz.guess())
                  .format('YYYY/MM/DD HH:mm'),
        })}
      </Text>
    </View>
  )
}
