import React, { FC, useState } from 'react'
import { View, ScrollView } from 'react-native'
import { t } from '../../../../modules/i18n'

import { Colors } from '../../../../constants/Colors'
import { Checkbox, CheckboxLabel } from '../../../Checkbox'
import { Step, Stepper } from '../../../Stepper'
import { Text } from '../../../Text'
import {
  GOURMET_SITE_PROVIDER_SOURCE,
  GourmetSiteProvider,
} from '../../../../types/ForR/GourmetSiteSetting'

type Props = {
  provider: GourmetSiteProvider
  steps: Step[]
  currentStepNumber: number
  sourceLabel: string
  onPressNext: () => void
  onPressGoBack: () => void
}

const getStepsByProvider = (provider: GourmetSiteProvider) => {
  switch (provider) {
    case 'ikkyu':
      return [
        t(
          'Step1 {{provider_name}}の管理画面にてAutoReserve提供の取り込み専用メールアドレスを登録しました。',
          {
            provider_name:
              GOURMET_SITE_PROVIDER_SOURCE[provider].authenticateLabel,
          }
        ),
      ]
    default:
      return [
        t(
          'Step1 {{provider_name}}の管理画面にてAutoReserve提供の取り込み専用メールアドレスを登録しました。',
          {
            provider_name:
              GOURMET_SITE_PROVIDER_SOURCE[provider].authenticateLabel,
          }
        ),
        t(
          'Step2 {{provider_name}}の管理画面にてメールアドレスの認証を完了しました。',
          {
            provider_name:
              GOURMET_SITE_PROVIDER_SOURCE[provider].authenticateLabel,
          }
        ),
      ]
  }
}

export function ConfirmEmailStepForm({
  provider,
  steps,
  currentStepNumber,
  sourceLabel,
  onPressNext,
  onPressGoBack,
}: Props) {
  const [agreeStates, setAgreeStates] = useState(
    getStepsByProvider(provider).map(() => false)
  )

  return (
    <Stepper
      steps={steps}
      currentStepNumber={currentStepNumber}
      errors={[]}
      isDisabledNextPress={agreeStates.some((state) => !state)}
      onPressNext={onPressNext}
      onPressGoBack={onPressGoBack}
    >
      <ScrollView
        style={{ flex: 1, backgroundColor: '#fff' }}
        contentContainerStyle={{
          flex: 1,
          paddingHorizontal: '5%',
          paddingVertical: 40,
        }}
      >
        <Text
          style={{
            fontSize: 22,
            fontWeight: '600',
            marginBottom: 40,
          }}
        >
          {t('最後に設定の確認をして連携完了です')}
        </Text>
        <View
          style={{
            padding: 40,
            borderWidth: 1,
            borderColor: Colors.border,
            borderStyle: 'dashed',
          }}
        >
          <View>
            <Text style={{ fontSize: 18, fontWeight: '600' }}>
              {t(
                '全ての作業が終わりましたらチェックを入れて確定ボタンを押してください。'
              )}
            </Text>
            {getStepsByProvider(provider).map((step, index) => {
              const checked = agreeStates[index]
              return (
                <Checkbox
                  key={index}
                  onChange={() => {
                    setAgreeStates((prev) => {
                      const newState = [...prev]
                      newState[index] = !newState[index]
                      return newState
                    })
                  }}
                  checkboxLabel={
                    <CheckboxLabel value={step} style={{ fontSize: 18 }} />
                  }
                  checked={checked}
                  style={{ marginTop: 44 }}
                />
              )
            })}
          </View>
        </View>
      </ScrollView>
    </Stepper>
  )
}
