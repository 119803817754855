import React from 'react'
import { View, Text, StyleProp, ViewStyle } from 'react-native'
import { faYenSign } from '@fortawesome/pro-light-svg-icons/faYenSign'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { numberWithDelimiter } from '@hello-ai/ar_shared/src/modules/numberWithDelimiter'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import {
  TableBalancingCashDrawer,
  TableCloseTypeBalancingCashDrawer,
  CashInDrawerSnapshotKey,
  convertCashInDrawerSnapshotKeyToName,
  convertCashInDrawerSnapshotKeyToNumber,
} from '@hello-ai/ar_shared/src/models/ForR/TableBalancingCashDrawer'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'

function SummaryRowName({
  name,
  fontSize,
  fontBold = false,
  color,
}: {
  name: string
  fontSize: number
  fontBold?: boolean
  color?: string
}) {
  return (
    <Text
      style={[
        {
          fontSize,
        },
        fontBold && {
          fontWeight: '600',
        },
        { textAlign: 'center' },
        color != null && {
          color,
        },
      ]}
    >
      {name}
    </Text>
  )
}

function SummaryRowAmount({
  amount,
  fontSize,
  fontBold = false,
  color,
  currencyIcon,
}: {
  amount: number | null
  fontSize: number
  fontBold?: boolean
  color?: string
  currencyIcon?: IconDefinition
}) {
  if (amount == null) {
    return null
  }

  return (
    <Text
      style={[
        {
          fontSize,
        },
        amount < 0 && {
          color: Colors.caution,
        },
        fontBold && {
          fontWeight: '600',
        },
        color != null && {
          color,
        },
      ]}
    >
      <FontAwesomeIcon
        icon={currencyIcon || faYenSign}
        size={fontSize - 2}
        color={color}
      />
      {numberWithDelimiter(amount)}
    </Text>
  )
}

function SummaryRow({
  children,
  style,
}: {
  children?: React.ReactNode
  style?: StyleProp<ViewStyle>
}) {
  return (
    <View
      style={[
        {
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        },
        style,
      ]}
    >
      {children}
    </View>
  )
}

function SummaryRowTitle({
  name,
  amount,
  highlight = false,
  currencyIcon,
}: {
  name: string
  amount: number
  highlight?: boolean
  currencyIcon?: IconDefinition
}) {
  return (
    <View
      style={[
        {
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: 'white',
        },
        highlight && {
          backgroundColor: Colors.accentBg,
          height: 100,
        },
      ]}
    >
      <Text
        style={{
          fontWeight: '600',
          fontSize: 18,
          color: Colors.black,
        }}
      >
        {name}
      </Text>

      <Text
        style={[
          {
            fontWeight: '600',
            fontSize: 18,
          },
          amount < 0 && {
            color: Colors.caution,
          },
        ]}
      >
        <FontAwesomeIcon icon={currencyIcon || faYenSign} size={16} />
        {numberWithDelimiter(amount)}
      </Text>
    </View>
  )
}

function DetailRow({
  row,
  index,
  currencyIcon,
}: {
  row: {
    name: string
    amount: number
    totalAmount: number
  }
  index: number
  currencyIcon?: IconDefinition
}) {
  const { width, sm } = useResponsive()

  return (
    <View
      key={row.name}
      style={[
        {
          paddingTop: 24,
          paddingBottom: 24,
        },
        index > 0 && {
          borderTopWidth: 0.5,
          borderTopColor: Colors.border,
        },
        { flexDirection: 'row' },
      ]}
    >
      <View style={[{ flex: 3 }, width < sm && { flex: 1 }]}>
        <Text
          style={[
            {
              fontSize: 18,
              color: Colors.black,
            },
            width < sm && { fontSize: 14 },
          ]}
        >
          {row.name}
        </Text>
      </View>
      <View style={{ flex: 1, alignItems: 'flex-end' }}>
        <Text
          style={[
            {
              fontSize: 18,
              color: Colors.black,
            },
            width < sm && { fontSize: 14 },
          ]}
        >
          {t('{{text}}枚', { text: numberWithDelimiter(row.amount) })}
        </Text>
      </View>
      <View style={{ flex: 1, alignItems: 'flex-end', marginRight: 10 }}>
        <Text
          style={[
            {
              fontSize: 18,
              color: Colors.black,
            },
            width < sm && { fontSize: 14 },
          ]}
        >
          <FontAwesomeIcon
            icon={currencyIcon || faYenSign}
            size={width < sm ? 12 : 16}
          />
          {numberWithDelimiter(row.totalAmount)}
        </Text>
      </View>
    </View>
  )
}

export function DetailRowView({
  balancingCashDrawer,
  showTotalOverAndShortAmount = false,
  currencyIcon,
}: {
  balancingCashDrawer: TableBalancingCashDrawer
  showTotalOverAndShortAmount?: boolean
  currencyIcon?: IconDefinition
}) {
  const { width, sm } = useResponsive()

  const isCashDrawerClosed = (
    balancingCashDrawer: TableBalancingCashDrawer
  ): balancingCashDrawer is TableCloseTypeBalancingCashDrawer =>
    balancingCashDrawer.balancing_type === 'close' ||
    balancingCashDrawer.balancing_type === 'open_and_close'

  const detailRows = Object.keys(
    balancingCashDrawer.table_cash_in_drawer_snapshot
  )
    .filter((key): key is CashInDrawerSnapshotKey => key !== 'id')
    .reduce(
      (accumulator: CashInDrawerSnapshotKey[][], key) => {
        const note = key.match(/note/) ? key : null
        const coin = key.match(/coin/) ? key : null
        const filter = (
          e: CashInDrawerSnapshotKey | null
        ): e is CashInDrawerSnapshotKey => e !== null
        const sort = (
          a: CashInDrawerSnapshotKey,
          b: CashInDrawerSnapshotKey
        ) => {
          const aAmount = Number(a.match(/[0-9]+/))
          const bAmount = Number(b.match(/[0-9]+/))
          return bAmount - aAmount
        }

        return [
          [...accumulator[0], note].filter(filter).sort(sort),
          [...accumulator[1], coin].filter(filter).sort(sort),
        ]
      },
      [[], []]
    )
    .flat()
    .map((key) => {
      const amount = balancingCashDrawer.table_cash_in_drawer_snapshot[key]
      const name = convertCashInDrawerSnapshotKeyToName(key)
      return {
        name,
        amount,
        totalAmount: convertCashInDrawerSnapshotKeyToNumber(key) * amount,
      }
    })
  const cashOverAndShortAmount =
    isCashDrawerClosed(balancingCashDrawer) || showTotalOverAndShortAmount
      ? balancingCashDrawer.total_cash_over_and_short_amount
      : balancingCashDrawer.cash_over_and_short_amount
  const expectedAmountInCashDrawer =
    balancingCashDrawer.cash_amount_in_drawer_snapshot - cashOverAndShortAmount

  const overAndShortAmountColor =
    balancingCashDrawer.total_cash_over_and_short_amount < 0
      ? Colors.caution
      : Colors.black

  return (
    <View
      style={[
        {
          paddingTop: 34,
          paddingRight: 32,
          paddingLeft: 32,
          paddingBottom: 30,
        },
        width < sm && {
          paddingTop: 20,
          paddingRight: 14,
          paddingLeft: 14,
          paddingBottom: 14,
        },
      ]}
    >
      <SummaryRowTitle
        name={t('レジ内現金')}
        amount={balancingCashDrawer.cash_amount_in_drawer_snapshot}
        currencyIcon={currencyIcon}
      />
      <View
        style={[
          { marginTop: 19, marginLeft: 16 },
          width < sm && { marginLeft: 0 },
        ]}
      >
        <View
          style={[
            {
              paddingVertical: 24,
              borderTopWidth: 0.5,
              borderTopColor: Colors.border,
            },
            width < sm && { paddingVertical: 18 },
          ]}
        >
          <SummaryRow>
            <SummaryRowName
              name={t('想定レジ内現金')}
              fontSize={18}
              color={Colors.black}
            />
            <SummaryRowAmount
              amount={expectedAmountInCashDrawer}
              fontSize={18}
              color={Colors.black}
              currencyIcon={currencyIcon}
            />
          </SummaryRow>
        </View>
        <View
          style={[
            {
              paddingVertical: 24,
              borderTopWidth: 0.5,
              borderTopColor: Colors.border,
            },
          ]}
        >
          <SummaryRow>
            <SummaryRowName
              name={t('過不足')}
              fontSize={18}
              color={overAndShortAmountColor}
            />
            <SummaryRowAmount
              amount={cashOverAndShortAmount}
              fontSize={18}
              color={overAndShortAmountColor}
              currencyIcon={currencyIcon}
            />
          </SummaryRow>
        </View>
        <View
          style={{
            marginTop: 20,
          }}
        >
          <View
            style={[
              {
                borderRadius: 8,
                paddingHorizontal: 24,
                backgroundColor: Colors.bgBlack,
              },
              width < sm && { paddingHorizontal: 12 },
            ]}
          >
            {detailRows.map((snapshotRow, index) => (
              <DetailRow
                key={snapshotRow.name}
                row={snapshotRow}
                index={index}
                currencyIcon={currencyIcon}
              />
            ))}
          </View>
        </View>
      </View>
      {isCashDrawerClosed(balancingCashDrawer) && (
        <View
          style={{
            marginTop: 32,
            marginBottom: -32,
            marginHorizontal: -32,
            paddingHorizontal: 32,
            borderTopWidth: 0.5,
            borderTopColor: Colors.border,
          }}
        >
          <View
            style={{
              paddingVertical: 24,
              marginHorizontal: -32,
              paddingHorizontal: 32,
              borderBottomWidth: 0.5,
              borderBottomColor: Colors.border,
            }}
          >
            <SummaryRowTitle
              name={t('釣り銭準備金（翌日分）')}
              amount={
                balancingCashDrawer.table_balancing_cash_drawer_closing
                  .next_day_opening_cash_amount
              }
              currencyIcon={currencyIcon}
            />
          </View>
          <View
            style={{
              paddingVertical: 24,
              marginHorizontal: -32,
              paddingHorizontal: 32,
              borderBottomWidth: 0.5,
              borderBottomColor: Colors.border,
            }}
          >
            <SummaryRowTitle
              name={t('銀行預入れ金')}
              amount={
                balancingCashDrawer.table_balancing_cash_drawer_closing
                  .cash_drop_amount
              }
              currencyIcon={currencyIcon}
            />
          </View>
        </View>
      )}
    </View>
  )
}
