import { ReanimatedScrollEvent } from 'react-native-reanimated/lib/typescript/hook/commonTypes'
import {
  CELL_HEIGHT,
  CELL_PADDING,
  HOUR_PC_WIDTH,
  HOUR_SP_WIDTH,
  SEAT_PC_HEIGHT,
  SEAT_SP_HEIGHT,
} from './const'
import {
  RestaurantReservation as RestaurantReservationModel,
  isSiteControllerReservation,
} from '../../../models/RestaurantReservation'
import { ReservationTag } from '@hello-ai/ar_shared/src/types/ForR/ReservationTag'
import { getTagIcon } from '../../../models/ReservationTag'
import type { ViewStyle } from 'react-native'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { getCustomerDisplayName } from '../../Customers/Customer'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import {
  TableSeat as TableSeatModel,
  useTableSeats,
} from '../../../models/TableSeat'
import { ChartMode } from './types'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { ListPeriodResponse_RestaurantReservationBlockPeriod } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/restaurant_reservation_block/restaurant_reservation_block_service'
import { Timestamp } from '@hello-ai/proto/src/gen/google/protobuf/timestamp'
import type { Items } from './types'

export function ifScrollToBottom(
  event?: ReanimatedScrollEvent | null
): boolean {
  if (event == null) return false
  const { layoutMeasurement, contentOffset, contentSize } = event
  const paddingToBottom = 20
  return (
    layoutMeasurement.height + contentOffset.y >=
    contentSize.height - paddingToBottom
  )
}

export function ifScrollToTop(event?: ReanimatedScrollEvent | null): boolean {
  if (event == null) return false
  return event.contentOffset.y <= 0
}

/**
 * 省略記号（...）を表示すべきかどうかを判断する関数
 * 滞在時間が1~1.5時間、かつアイコンが4つ以上の場合のみtrue
 *
 * @param hours - 予約の滞在時間（時間単位）
 * @param iconCount - 表示するアイコンの数
 * @returns 省略記号を表示すべき場合はtrue、そうでない場合はfalse
 */
export function shouldDisplayEllipsis(
  hours: number,
  iconCount: number
): boolean {
  if (hours < 1 || hours >= 2) return false
  return iconCount > 3
}

/**
 * アイコン表示領域の最大幅を計算する関数
 * - 2時間以上の場合は全て表示
 * - 1~1.5時間の場合
 *   - アイコン数が3より大きければ2つ分表示（省略記号の表示スペースを空けるため）
 *   - アイコン数が3以下ならば3つ分表示
 * - 0.5時間の場合は1つ分表示
 *
 * @param hours - 予約の滞在時間（時間単位）
 * @param iconCount
 * @returns アイコン表示領域の最大幅
 */
export function calculateIconMaxWidth(
  hours: number,
  iconCount: number
): string | number {
  const iconWidth = 16
  const gap = 2
  // 2時間以上の場合は全て表示
  if (hours >= 2) {
    return 'auto'
  }
  // 1~1.5時間の場合
  if (hours >= 1) {
    // アイコン数が3より大きければ2つ分表示（省略記号の表示スペースを空けるため）
    if (iconCount > 3) {
      return iconWidth * 2 + gap
      // アイコン数が3以下ならば3つ分表示
    } else {
      return iconWidth * 3 + gap * 2
    }
  }
  // 0.5時間の場合は1つ分表示
  return iconWidth
}

/**
 * アイコンの数を計算する関数
 * cssレイアウトに使用
 *
 * @param restaurantReservation - 予約情報
 * @param isReservedByAR - ARによる予約かどうか
 * @returns アイコンの数
 */
export function calculateIconCount(
  restaurantReservation: Omit<
    RestaurantReservationModel,
    | 'id'
    | 'status'
    | 'cancel_fee'
    | 'table_seats'
    | 'restaurant_crew_member'
    | 'reservation'
  >,
  isReservedByAR: boolean
): number {
  let iconCount = 0
  if (restaurantReservation.smart_payment !== null) iconCount++
  if (restaurantReservation.memo) iconCount++
  if (isSiteControllerReservation(restaurantReservation)) iconCount++
  if (isReservedByAR) iconCount++
  if (
    restaurantReservation.reservation_courses !== undefined &&
    restaurantReservation.reservation_courses.length > 0
  )
    iconCount++
  if (restaurantReservation.reservation_tags !== undefined) {
    iconCount += restaurantReservation.reservation_tags.filter(
      (tag: ReservationTag) => getTagIcon(tag.name) !== undefined
    ).length
  }
  return iconCount
}

export function getCellWidth(hours: number, isSp: boolean) {
  return (isSp ? HOUR_SP_WIDTH : HOUR_PC_WIDTH) * hours - CELL_PADDING * 2
}

export function getCellHeight(isSp: boolean) {
  return (isSp ? SEAT_SP_HEIGHT : SEAT_PC_HEIGHT) - CELL_PADDING * 2
}

export const getCellX = (x: number, isSp: boolean) => {
  return (isSp ? HOUR_SP_WIDTH : HOUR_PC_WIDTH) * x + CELL_PADDING
}

export const getCellY = (y: number, isSp: boolean) => {
  return (
    CELL_HEIGHT + (isSp ? SEAT_SP_HEIGHT : SEAT_PC_HEIGHT) * y + CELL_PADDING
  )
}

export function getCellStyle({
  x,
  y,
  isSp,
}: {
  x: number
  y: number
  isSp: boolean
}): ViewStyle {
  return {
    transform: [
      { translateX: getCellX(x, isSp) },
      {
        translateY: getCellY(y, isSp),
      },
    ],
  }
}

export function setIntervalByRequestAnimationFrame(
  callback: () => void,
  interval: number
): () => void {
  let accumulator = 0
  let lastTime = performance.now()
  let idForRaf: number | null = null
  let canceled = false

  const loop = (currentTime: number) => {
    if (canceled) return
    idForRaf = requestAnimationFrame(loop)
    accumulator += currentTime - lastTime
    lastTime = currentTime

    if (accumulator >= interval) {
      callback()
      accumulator = 0
    }
  }

  idForRaf = requestAnimationFrame(loop)

  return () => {
    if (idForRaf !== null) {
      cancelAnimationFrame(idForRaf)
    }
    canceled = true
  }
}

export function getReservationText(
  restaurantReservation: Pick<RestaurantReservationModel, 'customers' | 'kind'>
) {
  const customers = restaurantReservation.customers
  return customers.length === 0
    ? restaurantReservation.kind === 'walkin'
      ? t('ウォークイン')
      : t('未設定')
    : customers
        .map((customer) =>
          t('{{name}}様', { name: getCustomerDisplayName(customer) })
        )
        .join('、')
}

export function getUnselectableTableSeatIds({
  mode,
  date,
  startTime,
  endTime,
  restaurantReservations,
  restaurantReservation: targetReservation,
}: {
  mode: ChartMode
  date: dayjs.Dayjs
  startTime: number
  endTime: number
  restaurantReservations: RestaurantReservationModel[]
  restaurantReservation?: RestaurantReservationModel
}) {
  const unselectableTableSeatIds: Array<TableSeatModel['id']> = []

  if (mode === 'selectBlock') {
    return unselectableTableSeatIds
  }
  const startAt = date.startOf('day').add(startTime, 'seconds')
  const endAt = date.startOf('day').add(endTime, 'seconds')

  if (mode !== 'seatChange') {
    for (const restaurantReservation of restaurantReservations) {
      for (const tableSeat of restaurantReservation.table_seats) {
        if (
          !unselectableTableSeatIds.includes(tableSeat.id) &&
          overlapsWith(
            {
              startAt,
              endAt,
            },
            {
              startAt: dayjs(restaurantReservation.start_at),
              endAt: dayjs(restaurantReservation.end_at),
            }
          )
        ) {
          if (mode === 'selectSeats') {
            // NOTE: 予約変更前と日付が違う場合は選択不可とする
            if (
              restaurantReservation != null &&
              dayjs(targetReservation?.start_at).format('YYYY-MM-DD') !==
                date.format('YYYY-MM-DD')
            )
              unselectableTableSeatIds.push(tableSeat.id)
            // NOTE: 予約変更時でも開始時刻が同じ予約とは席交換可能にする
            if (startAt.isSame(dayjs(restaurantReservation.start_at))) continue
          } else {
            // NOTE 予約変更以外のモード
            unselectableTableSeatIds.push(tableSeat.id)
          }
        }
      }
    }
  }

  if (mode === 'seatChange' && targetReservation?.id != null) {
    const reserved = restaurantReservations.find(
      (i) => i.id === targetReservation?.id
    )
    const reservedTableSeatIds = reserved?.table_seats.map((i) => i.id)
    // 同じ席IDが含まれている次の予約
    const nextReservations = restaurantReservations.filter(
      (candidateReservation) => {
        if (targetReservation?.id === candidateReservation.id) return false

        const tableSeatIds = candidateReservation.table_seats.map((i) => i.id)
        if (!reservedTableSeatIds?.some((a) => tableSeatIds.includes(a)))
          return false
        return endAt.isSameOrAfter(dayjs(candidateReservation.start_at))
      }
    )

    // 交換前の席に次予約が入っている場合
    const unSelectableReservations = restaurantReservations.filter(
      (candidateUnSelectableReservation) => {
        if (targetReservation?.id === candidateUnSelectableReservation.id)
          return false
        const isDuplicationDuration = nextReservations.some(
          (nextReservation) => {
            if (nextReservation.id === candidateUnSelectableReservation.id)
              return false
            // 次予約の開始時刻以降の予約席は判定しない
            if (
              dayjs(candidateUnSelectableReservation.start_at).isSameOrAfter(
                dayjs(nextReservation.start_at)
              )
            )
              return false
            // 交換できる席は終了時刻が次予約の開始時刻前のみである
            return dayjs(nextReservation.start_at).isBefore(
              dayjs(candidateUnSelectableReservation.end_at)
            )
          }
        )
        return isDuplicationDuration
      }
    )
    unSelectableReservations.forEach((unSelectableReservation) => {
      unSelectableReservation.table_seats.forEach((tableSeat) => {
        if (!unselectableTableSeatIds.includes(tableSeat.id)) {
          unselectableTableSeatIds.push(tableSeat.id)
        }
      })
    })
    // 席の変更・交換は自席を含めて同じ開始時刻の予約のみである
    const nextReservationIds = nextReservations.map((i) => i.id)
    restaurantReservations
      .filter((reservation) => {
        if (reservation.id === targetReservation?.id) return false
        if (nextReservationIds.includes(reservation.id)) return false

        // 時間が被ってても移動OKという整理になったため、一律でtrueにする
        // return startAt.isSame(reservation.start_at)
        return false
      })
      .forEach((notSameStartReservation) => {
        notSameStartReservation.table_seats.forEach((tableSeat) => {
          if (!unselectableTableSeatIds.includes(tableSeat.id)) {
            unselectableTableSeatIds.push(tableSeat.id)
          }
        })
      })
  }

  return unselectableTableSeatIds
}

export function overlapsWith(
  left: {
    startAt: dayjs.Dayjs
    endAt: dayjs.Dayjs
  },
  right: {
    startAt: dayjs.Dayjs
    endAt: dayjs.Dayjs
  }
) {
  // 区間A, Bが重なっている場合、
  // (Aの開始地点) <= Bの終了地点) && (Aの終了地点 >= Bの開始地点)
  // という条件を満たしている
  return right.endAt.isAfter(left.startAt) && left.endAt.isAfter(right.startAt)
}

type SelectSeatState =
  | 'over-capacity'
  | 'selectable'
  | 'selected'
  | 'under-capacity'
  | 'unselectable'

export function getSelectSeatState({
  selected,
  unselectable,
  partySize,
  minPartySize,
  maxPartySize,
}: {
  selected: boolean
  unselectable: boolean
  partySize: number
  minPartySize?: number
  maxPartySize?: number
}): SelectSeatState {
  if (selected) return 'selected'
  if (unselectable) return 'unselectable'

  if (minPartySize != null && maxPartySize != null) {
    if (minPartySize <= partySize && partySize <= maxPartySize)
      return 'selectable'
  }
  if (maxPartySize != null && maxPartySize < partySize) return 'over-capacity'
  if (minPartySize != null && minPartySize > partySize) return 'under-capacity'

  return 'selectable'
}

export function getSelectSeatStyle(state: SelectSeatState) {
  switch (state) {
    case 'over-capacity':
      return {
        borderColor: Colors.accent,
        backgroundColor: Colors.bgBlack,
      }
    case 'selectable':
      return {
        borderColor: Colors.accent,
        backgroundColor: Colors.accent40,
      }
    case 'selected':
      return {
        borderColor: Colors.accent,
        backgroundColor: Colors.accent,
      }
    case 'under-capacity':
      return {
        borderColor: Colors.secondaryBlack,
        backgroundColor: Colors.black50,
      }
    case 'unselectable':
      return {
        borderColor: 'transparent',
        backgroundColor: Colors.black20,
      }
  }
}

export function getOtherReservations({
  restaurantReservations,
}: {
  restaurantReservations: RestaurantReservationModel[] | undefined
}) {
  const reservations: RestaurantReservationModel[] = []

  if (restaurantReservations == null) {
    return reservations
  }

  for (const restaurantReservation of restaurantReservations) {
    if (restaurantReservation.table_seats.length === 0) {
      reservations.push(restaurantReservation)
    }
  }

  return reservations
}

export function findOverlappingItemsByTableSeatId({
  tableSeatId,
  timeRange,
  reservations,
  reservationBlockPeriods,
}: {
  tableSeatId: TableSeatModel['id']
  timeRange: {
    startAt: dayjs.Dayjs
    endAt: dayjs.Dayjs
  }
  reservations: RestaurantReservationModel[]
  reservationBlockPeriods: ListPeriodResponse_RestaurantReservationBlockPeriod[]
}) {
  const overlappingItems: Items = {
    reservations: [],
    reservationBlockPeriods: [],
  }

  for (const reservation of reservations) {
    if (
      reservation.table_seats.some(
        (tableSeat) => tableSeat.id === tableSeatId
      ) &&
      overlapsWith(
        {
          startAt: timeRange.startAt,
          endAt: timeRange.endAt,
        },
        {
          startAt: dayjs(reservation.start_at),
          endAt: dayjs(reservation.end_at),
        }
      )
    ) {
      overlappingItems.reservations.push(reservation)
    }
  }

  for (const reservationBlockPeriod of reservationBlockPeriods) {
    if (
      reservationBlockPeriod.tableSeats.some(
        (tableSeat) => tableSeat.id === tableSeatId
      ) &&
      overlapsWith(
        {
          startAt: timeRange.startAt,
          endAt: timeRange.endAt,
        },
        {
          startAt: dayjs(Timestamp.toDate(reservationBlockPeriod.startAt!)),
          endAt: dayjs(Timestamp.toDate(reservationBlockPeriod.endAt!)),
        }
      )
    ) {
      overlappingItems.reservationBlockPeriods.push(reservationBlockPeriod)
    }
  }
  return overlappingItems
}

// in hours
const seatDurationInterval = 0.5
export function getStartTimeFromPosition(relativeX: number, isSp: boolean) {
  'worklet'
  const x =
    Math.round(
      relativeX / (isSp ? HOUR_SP_WIDTH : HOUR_PC_WIDTH) / seatDurationInterval
    ) * seatDurationInterval
  return x * 3600
}

export function getSeatIdFromPosition(
  relativeY: number,
  isSp: boolean,
  tableSeats: ReturnType<typeof useTableSeats>['tableSeats'][number][],
  unassignedSeatReservationLength: number
) {
  'worklet'
  const titleOffset = 1 // タイトル分の調整
  const offset = titleOffset + unassignedSeatReservationLength // 席が未指定の予約（ウォークイン、リクエスト）分の調整
  const columnIndex = Math.round(
    relativeY / (isSp ? SEAT_SP_HEIGHT : SEAT_PC_HEIGHT)
  )
  const index = columnIndex - offset
  return tableSeats[index]?.id
}

export function getPartySize(
  tableSeat: ReturnType<typeof useTableSeats>['tableSeats'][number]
) {
  return `${tableSeat.min_party_size ?? ''}~${tableSeat.max_party_size ?? ''}`
}
