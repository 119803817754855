import React, { useEffect, useMemo, useRef } from 'react'
import { Platform, View } from 'react-native'
import { Chart, createChartScrollResponder } from '../Reservations/Chart'
import { useRestaurantBusinessTimesByDate } from '../../models/RestaurantBusinessTime'
import { TableSeat, useTableSeats } from '../../models/TableSeat'
import {
  initDefaultReservationFormState,
  initReservationFormState,
} from './Form'
import {
  exchangeRestaurantReservationTableSeats,
  updateRestaurantReservation,
  useRestaurantReservation,
  useRestaurantReservations,
} from '../../models/RestaurantReservation'
import {
  getDefaultDuration,
  validate,
} from './ReservationForm/SelectSeats/Form'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import { getFormatTime } from '@hello-ai/ar_shared/src/modules/time'
import { Stepper } from '@hello-ai/ar_shared/src/components/Stepper'
import {
  Alert,
  AlertMethods,
  AlertProvider,
} from '@hello-ai/ar_shared/src/components/Alert'

import { displayToastError, displayToastSuccess } from '../Shared/Toast'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useNavigation } from '../../modules/navigation/useNavigation'
import { useNavigate } from '../../modules/navigation/useNavigate'

export function ReservationChangeSeat({
  restaurantId,
  restaurantReservationId,
}: {
  restaurantId: number
  restaurantReservationId: string
}) {
  const navigation = useNavigation()
  const navigate = useNavigate()
  const token = useToken()
  const { width, sm } = useResponsive()
  const alertRef = useRef<AlertMethods>(null)

  const { restaurantReservation } = useRestaurantReservation(
    restaurantId,
    restaurantReservationId
  )

  const [state] = useFormState(
    restaurantReservation
      ? {
          ...initReservationFormState(restaurantReservation),
          selectedSeatIds: [],
        }
      : initDefaultReservationFormState(dayjs())
  )
  const { tableSeats } = useTableSeats(restaurantId, {})
  const { dateString, startTime, adultPartySize, childPartySize } = state
  const date = dayjs(dateString)
  const partySize = adultPartySize + childPartySize
  const [selectedSeatIds, setSelectedSeatIds] = useFormState<
    Array<TableSeat['id']>
  >(state.selectedSeatIds)

  const { restaurantBusinessTimes } = useRestaurantBusinessTimesByDate(
    restaurantId,
    {
      date: state.dateString,
    }
  )

  const [endTime, setEndTime] = useFormState(
    state.endTime != null
      ? state.endTime
      : startTime + getDefaultDuration({ startTime, restaurantBusinessTimes })
  )

  const ref = useRef<React.ElementRef<typeof Chart>>(null)
  const { setChartRef, reset, chartProps, requestScroll } = useMemo(
    () => createChartScrollResponder(),
    []
  )
  useEffect(() => {
    setChartRef(ref.current)
    return () => {
      reset()
    }
  }, [reset, setChartRef])

  useEffect(() => {
    requestScroll({ time: startTime })
  }, [requestScroll, startTime])

  const { disabled, errors } = useMemo(
    () =>
      validate({
        partySize,
        selectedSeatIds,
        startTime,
        endTime,
        tableSeats,
        restaurantBusinessTimes,
      }),
    [
      startTime,
      endTime,
      partySize,
      restaurantBusinessTimes,
      selectedSeatIds,
      tableSeats,
    ]
  )

  const { restaurantReservations } = useRestaurantReservations(restaurantId, {
    date: date.format('YYYY-MM-DD'),
  })

  const onPressSubmit = async () => {
    const alert = Platform.select({
      web: alertRef.current?.alert,
      default: Alert.alert,
    })

    const isExchangeMode = restaurantReservations
      .filter((i) => i.id !== restaurantReservationId)
      .some((i) =>
        i.table_seats
          .flatMap((t) => t.id)
          .some((tid) => selectedSeatIds.includes(tid))
      )

    if (isExchangeMode) {
      alert(t('席の入れ替え'), t('席を入れ替えますか？'), [
        {
          text: t('いいえ'),
          onPress: () => console.log('Cancel Pressed'),
          style: 'cancel',
        },
        {
          text: t('はい'),
          onPress: async () => {
            /**
             * NOTE
             * 予約１：テーブル１、２、３
             * 予約２：テーブルA、B、C
             * のとき、予約１をテーブル１、２，Aとしたい場合は
             * table_seat_ids = １、２、A
             * other_table_seats = ３、B、C
             * とする。
             * このとき、３を交換元、Aを交換先と呼ぶ
             */
            // 交換元の開放予定シートId 例）３
            const releaseSeatIds = restaurantReservation?.table_seats.flatMap(
              (original) =>
                selectedSeatIds.includes(original.id) ? [] : [original.id]
            )
            const selectedEndAt = dayjs(
              `${state.dateString} ${getFormatTime(endTime)}`
            )
            const startAt = dayjs(restaurantReservation?.start_at)
            const params = {
              end_at: selectedEndAt.format('YYYY-MM-DD HH:mm:ss'),
              table_seat_ids: selectedSeatIds,
              other_table_seats: restaurantReservations.flatMap(
                (reservation) => {
                  if (reservation.id === restaurantReservationId) return []
                  // 交換先シート 例）A
                  const exchangeSeatIds = reservation.table_seats.flatMap(
                    (tableSeat) =>
                      selectedSeatIds.includes(tableSeat.id)
                        ? [tableSeat.id]
                        : []
                  )

                  if (exchangeSeatIds.length === 0) return []

                  // 時間の重複チェック
                  // 予約時間が完全に前後している場合（終了時間と開始時間が同じ場合）は重複としない
                  const isOverlapping = !(
                    selectedEndAt.isSame(dayjs(reservation.start_at)) ||
                    startAt.isSame(dayjs(reservation.end_at)) ||
                    selectedEndAt.isBefore(dayjs(reservation.start_at)) ||
                    startAt.isAfter(dayjs(reservation.end_at))
                  )
                  if (!isOverlapping) return [] // 時間が重複していない場合はスキップ

                  // 交換先予約で交換しないシートIds 例) B、C
                  const otherReservationNewSeatIds =
                    reservation.table_seats.flatMap((tableSeat) => {
                      return !exchangeSeatIds.includes(tableSeat.id)
                        ? [tableSeat.id]
                        : []
                    })
                  // 交換作業 例) 3 <-> A
                  exchangeSeatIds.forEach(() => {
                    const releaseSeatId = releaseSeatIds?.shift()
                    if (releaseSeatId != null) {
                      otherReservationNewSeatIds.push(releaseSeatId)
                    }
                  })

                  return [
                    {
                      reservation_id: reservation.id,
                      table_seat_ids: otherReservationNewSeatIds,
                    },
                  ]
                }
              ),
            }
            const { error } = await exchangeRestaurantReservationTableSeats(
              token,
              restaurantId,
              restaurantReservationId,
              params
            )
            if (error != null) return
            displayToastSuccess(
              t('席を交換しました'),
              undefined,
              width < sm ? { marginBottom: 48 } : undefined
            )
            if (Platform.OS === 'web') {
              navigate(
                `/restaurants/${restaurantId}/reservations?m=chart&date=${dateString}`
              )
            } else {
              navigation.goBack()
            }
          },
        },
      ])
      return
    }

    const { error } = await updateRestaurantReservation(
      token,
      restaurantId,
      restaurantReservationId,
      {
        table_seat_ids: selectedSeatIds,
      }
    )
    if (error != null) return
    displayToastSuccess(
      t('予約を更新しました'),
      undefined,
      width < sm ? { marginBottom: 48 } : undefined
    )
    if (Platform.OS === 'web') {
      navigate(
        `/restaurants/${restaurantId}/reservations?m=chart&date=${dateString}`
      )
    } else {
      navigation.goBack()
    }
  }

  useEffect(() => {
    errors.length > 0 &&
      errors.forEach((error) => {
        displayToastError(error)
      })
  }, [errors])

  return (
    <Stepper
      errors={[]}
      onPressNext={onPressSubmit}
      isDisabledNextPress={disabled}
    >
      <Chart
        restaurantId={restaurantId}
        ref={ref}
        date={date}
        mode="seatChange"
        selectParams={{
          restaurantReservation,
          startTime,
          endTime,
          setEndTime,
          partySize,
          selectedSeatIds,
          onChangeSelectedSeatIds: setSelectedSeatIds,
        }}
        scrollViewProps={{
          style: {
            backgroundColor: width < sm ? Colors.bgLightBlack : Colors.white,
            flex: 1,
          },
        }}
        {...chartProps}
      />
      <View
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
        pointerEvents="box-none"
      >
        <AlertProvider ref={alertRef} />
      </View>
    </Stepper>
  )
}
