import { supportedLocales } from '@hello-ai/ar_shared/src/modules/locale'
import { getLocale } from '@hello-ai/for_r_app/src/modules/locale'
export { getLocale } from '@hello-ai/for_r_app/src/modules/locale'

export {
  getCountry,
  isZh,
  supportedLocales,
} from '@hello-ai/ar_shared/src/modules/locale'

export function getLocaleFromPathname(pathname: string) {
  return supportedLocales.find((locale) => {
    return (
      pathname.toLowerCase() === `/${locale.toLowerCase()}` ||
      pathname.toLowerCase().startsWith(`/${locale.toLowerCase()}/`)
    )
  })
}

export function getPathnameWithLocale(pathname: string) {
  const locale = getLocale()

  // Don't append locale to these:
  // autoreserve://
  // https://
  // tel:
  if (/^[a-zA-Z]+:/.test(pathname)) {
    return pathname
  }

  if (getLocaleFromPathname(pathname) !== undefined) {
    return pathname
  }

  return `/${locale.toLowerCase()}${pathname}`
}
