import { Customer } from './Customer'
import { ReservationChangeRequest } from './RestaurantRequestReservation'
import { RestaurantCrewMember } from './RestaurantCrewMember'
import { RestaurantReservationCourse } from './RestaurantReservationCourse'
import {
  SiteControllerParsedCourse,
  SiteControllerReservationRestaurantMemo,
} from './SiteController'
import { TableSeat } from './TableSeat'
import { RestaurantCourse } from './RestaurantCourse'
import { ReservationTag } from './ReservationTag'

export type RestaurantReservationStatus = 'reserved' | 'canceled'

export type RestaurantReservationSource =
  | 'phone'
  | 'tabelog'
  | 'opentable'
  | 'toreta'
  | 'tablecheck'
  | 'ebica'
  | 'hotpepper'
  | 'autoreserve'

export const ALL_RESTAURANT_RESERVATION_VISIT_STATUS = [
  'reserved',
  'some_visited',
  'all_visited',
  'departed',
  'paid',
  'no_show',
] as const
export type RestaurantReservationVisitStatus =
  | (typeof ALL_RESTAURANT_RESERVATION_VISIT_STATUS)[number]
  | 'eaten'

export interface SmartPayment {
  amount: number
  id: string
  reason: string | null
  status:
    | 'not_authorized' // 与信前
    | 'authorized' // 与信済
    | 'paid' // 支払済
    | 'canceled' // キャンセル済(スマート決済以外を利用)
    | 'failed' // 決済エラー
    | 'require_three_d_secure' // 3Dセキュア認証が必要(決済エラー扱い)
  issue_payment_receipt_enabled: boolean
}

export interface RestaurantReservationBase {
  id: string
  status: RestaurantReservationStatus
  visit_status?: RestaurantReservationVisitStatus
  start_at: string
  end_at: string
  created_at: string
  changed_at?: string
  canceled_at?: string
  adult_party_size: number
  child_party_size: number
  party_size: number
  table_seats: TableSeat[]
  customers: Customer[]
  reservation_courses: RestaurantReservationCourse[]
  allergy: string
  memo: string
  reservation_tags?: ReservationTag[]
  cancel_fee: number
  kind: 'normal' | 'walkin'
  reservation_child_details?: {
    id: string
    age: number
  }[]
  source?: RestaurantReservationSource

  // TODO: refactor
  uuid?: string

  /**
   * この予約に紐づく変更リクエストがある場合、その情報を含む
   */
  reservation_change_request?: ReservationChangeRequest
  restaurant_course?: RestaurantCourse
  restaurant_crew_member: RestaurantCrewMember | null
  smart_payment: SmartPayment | null
  has_previous_table_order?: boolean
  reservation?: {
    id: number
    uuid: string
    use_smart_payment: boolean
  }
  table_order?: {
    id: string
  } | null
}

interface RestaurantReservationReservationBook
  extends RestaurantReservationBase {
  source_by: 'reservation_book'
}
export interface RestaurantReservationWithSiteController
  extends RestaurantReservationBase {
  source_by: 'site_controller'
  reservation_site_controller_parsed_course: {
    course_party_size: number
    site_controller_parsed_course: SiteControllerParsedCourse
  }
  site_controller_reservation_restaurant_memos: SiteControllerReservationRestaurantMemo[]
}
interface UnknownReservation extends RestaurantReservationBase {
  source_by?: string
}

export type RestaurantReservation =
  | RestaurantReservationReservationBook
  | RestaurantReservationWithSiteController
  | UnknownReservation

export function isSiteControllerReservation(
  reservation: Pick<RestaurantReservation, 'source_by'>
): reservation is RestaurantReservationWithSiteController {
  return reservation.source_by === 'site_controller'
}

export interface RestaurantReservationParams {
  status?: RestaurantReservationStatus
  start_at?: string
  end_at?: string
  adult_party_size?: number
  child_party_size?: number
  table_seat_ids?: string[]
  customer_ids?: string[]
  reservation_courses?: Array<{
    id?: RestaurantReservationCourse['id']
    restaurant_course_id: RestaurantReservationCourse['restaurant_course']['id']
    party_size: RestaurantReservationCourse['party_size']
    _destroy?: boolean | '1'
  }>
  allergy?: string
  memo?: string
  reservation_tag_ids?: string[]
  table_order_id?: string
  other_table_seats?: {
    reservation_id: string
    table_seat_ids: string[]
  }[]
  restaurant_crew_member_id?: string
  visit_status?: RestaurantReservationVisitStatus
  kind?: 'normal' | 'walkin'
}
